import { useTranslation } from "react-i18next";
import { Navigate, useRouteError } from "react-router";
import { ErrorState } from "src/components/ErrorState";
import { AnchorButton } from "src/components/ui/AnchorButton";
import { PAGE_URLS } from "src/configs/pages-urls";
import { useErrorMessage } from "src/hooks/use-error-message";
import { PageSection } from "src/layouts/PageLayout";
import type { ApiError } from "src/types/ApiError";

export function AppErrorState() {
	const routeError = useRouteError();
	const errorMessage = useErrorMessage(routeError as Error);
	const { t } = useTranslation();
	if (
		routeError instanceof Error &&
		routeError.message.includes("dynamically imported module") &&
		window
	) {
		// we reload the APP when we get an error related to dynamically imported module
		window.location.reload();
		return null;
	}

	const { status = 0 } = routeError as ApiError;

	if (status === 404) {
		return (
			<Navigate
				to={PAGE_URLS.notFound().index}
				replace
				state={{ error: routeError }}
			/>
		);
	}

	if (status === 403) {
		return (
			<Navigate
				to={PAGE_URLS.notAuthorized().index}
				replace
				state={{ error: routeError }}
			/>
		);
	}

	return (
		<PageSection className="p-0">
			<ErrorState
				description={errorMessage}
				variant="fullScreen"
				error={routeError instanceof Error ? routeError : undefined}
			>
				<AnchorButton to="/" variant="primary">
					{t("accountError.goBack")}
				</AnchorButton>
			</ErrorState>
		</PageSection>
	);
}
