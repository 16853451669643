import { UNLIMITED_PAGE_SIZE } from "src/configs/constants.ts";
import type { RouterConfig } from "src/router/types";
import {
	configCouriersListOptions,
	promiseCourierServiceLevelsListOptions,
	promiseCourierServiceLevelsRetrieveOptions,
	promiseDeliveryMethodsListOptions,
	promiseDeliveryMethodsRetrieveOptions,
	promiseDeliveryPredictionServicesPublishedRetrieveOptions,
	promiseWarehousesListOptions,
	promiseWarehousesRetrieveOptions,
} from "src/types/_generated/@tanstack/react-query.gen";

export const promiseCarrierLevelLoader = (
	{ queryClient }: RouterConfig,
	accountId?: string,
) => {
	return queryClient.ensureQueryData(
		promiseCourierServiceLevelsListOptions({
			query: {
				account: [Number(accountId)],
			},
		}),
	);
};

export const promiseWarehousesLoader = (
	{ queryClient }: RouterConfig,
	accountId?: string,
) => {
	return queryClient.ensureQueryData(
		promiseWarehousesListOptions({
			query: { account: [Number(accountId)] },
		}),
	);
};

export const promiseDeliveryMethodsLoader = (
	{ queryClient }: RouterConfig,
	accountId?: string,
) => {
	return queryClient.ensureQueryData(
		promiseDeliveryMethodsListOptions({
			query: { account: [Number(accountId)] },
		}),
	);
};

export const promiseDeliveryPredictionServicesLoader = (
	{ queryClient }: RouterConfig,
	accountId?: string,
) => {
	return Promise.all([
		queryClient.ensureQueryData(
			promiseDeliveryPredictionServicesPublishedRetrieveOptions({
				query: { account: Number(accountId) },
			}),
		),
		queryClient.ensureQueryData(
			promiseDeliveryPredictionServicesPublishedRetrieveOptions({
				query: { account: Number(accountId) },
			}),
		),
		queryClient.ensureQueryData(
			promiseDeliveryMethodsListOptions({
				query: { account: [Number(accountId)] },
			}),
		),
		queryClient.ensureQueryData(
			configCouriersListOptions({
				query: { limit: UNLIMITED_PAGE_SIZE },
			}),
		),
	]);
};

export const promiseCarrierLevelEditLoader = (
	{ queryClient }: RouterConfig,
	promiseId?: string,
) => {
	return queryClient.ensureQueryData(
		promiseCourierServiceLevelsRetrieveOptions({
			path: { id: Number(promiseId) },
		}),
	);
};

export const promiseWarehousesEditLoader = (
	{ queryClient }: RouterConfig,
	promiseId?: string,
) => {
	return queryClient.ensureQueryData(
		promiseWarehousesRetrieveOptions({
			path: { id: Number(promiseId) },
		}),
	);
};

export const promiseDeliveryMethodsEditLoader = (
	{ queryClient }: RouterConfig,
	promiseId?: string,
) => {
	return queryClient.ensureQueryData(
		promiseDeliveryMethodsRetrieveOptions({
			path: { id: Number(promiseId) },
		}),
	);
};
