import { type VariantProps, cva } from "class-variance-authority";
import { omit } from "lodash";
import {
	type ComponentProps,
	Fragment,
	type PropsWithChildren,
	type ReactNode,
} from "react";
import type { Location } from "react-router";
import { GoBackButton } from "src/components/GoBackButton";
import type { goBackButtonVariants } from "src/components/GoBackButton/GoBackButton";
import { Flex } from "src/components/ui/Flex";
import { H1 } from "src/components/ui/Typography";
import { PageSection } from "src/layouts/PageLayout/PageSection";
import { cn } from "src/utils/tailwind";

type Props = {
	title: ReactNode;
	withPageSectionWrapper?: boolean;
	containerClassName?: string;
};

type PropsWithoutBackButton = {
	hideBackButton: true;
};

type PropsWithBackButton = {
	defaultBackPath?: string;
	backPath?: string;
	backState?: Location["state"];
	hideBackButton?: false;
};

const pageHeaderVariants = cva("py-3 px-5 bg-pl-blue w-full", {
	variants: {
		type: {
			base: "bg-page-header-background text-page-header-foreground",
			dark: "bg-slate-500 text-page-header-foreground",
			transparent: "bg-transparent text-page-header-foregroundDark",
			cancelled:
				"bg-page-header-backgroundDanger text-page-header-foreground",
		},
	},
	defaultVariants: {
		type: "base",
	},
});

type PageHeaderProps = Props &
	(PropsWithoutBackButton | PropsWithBackButton) &
	Omit<ComponentProps<"div">, "title"> &
	VariantProps<typeof pageHeaderVariants> & {
		iconSize?: VariantProps<typeof goBackButtonVariants>["size"];
	};

function PageSectionWrapperWithoutPaddingX(props: PropsWithChildren) {
	return <PageSection className="p-0" {...props} />;
}
function PageSectionWrapper(props: PropsWithChildren) {
	return <PageSection className="py-0" {...props} />;
}

export function PageHeader(props: PageHeaderProps) {
	const {
		title,
		children,
		hideBackButton,
		className,
		withPageSectionWrapper,
		containerClassName,
		iconSize,
		type,
		...rest
	} = props;

	const renderBackButton = () => {
		if (!hideBackButton) {
			const { defaultBackPath, backPath } = props;
			return (
				<GoBackButton
					defaultBackPath={defaultBackPath}
					backPath={backPath}
					iconSize={iconSize}
					colorVariant={type === "transparent" ? "dark" : "light"}
				/>
			);
		}

		return null;
	};

	const getWrapper = () => {
		if (!withPageSectionWrapper) return Fragment;

		return hideBackButton
			? PageSectionWrapper
			: PageSectionWrapperWithoutPaddingX;
	};

	const Wrapper = getWrapper();

	return (
		<div
			className={cn(pageHeaderVariants({ type }), className)}
			{...omit(rest, "defaultBackPath", "backPath")}
		>
			<Wrapper>
				<Flex
					dir="row"
					align="center"
					gap={4}
					className={containerClassName}
				>
					<Flex align="center" gap={4}>
						{renderBackButton()}
						<H1 size="lg" fontWeight="medium">
							{title}
						</H1>
					</Flex>
					{children}
				</Flex>
			</Wrapper>
		</div>
	);
}

function ActionBar({
	children,
	className,
	...rest
}: ComponentProps<typeof Flex>) {
	return (
		<Flex gap={2} className={cn("p-4", className)} wrap {...rest}>
			{children}
		</Flex>
	);
}

PageHeader.ActionBar = ActionBar;
