/* eslint-disable react-hooks/exhaustive-deps */
import { BellAlertIcon } from "@heroicons/react/20/solid";
import { Portal } from "@radix-ui/react-portal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

type PublishedMenuNotificationProps = {
	selectorId: string;
	hasNotification?: boolean;
};

export function PublishedMenuNotification({
	hasNotification,
	selectorId,
}: PublishedMenuNotificationProps) {
	const { t } = useTranslation();
	const [container, setContainer] = useState<HTMLElement | null>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const observer = new MutationObserver(() => {
			const el = document.querySelector<HTMLElement>(`#${selectorId}`);

			if (el !== container) {
				setContainer(el);
			}
		});
		observer.observe(document, { subtree: true, childList: true });

		return () => observer.disconnect();
	}, [selectorId]);

	if (!hasNotification) return null;
	if (!container) return null;

	return (
		<Portal container={container}>
			<>
				<BellAlertIcon
					id="notification-alert"
					className="alert-bell h-5 w-5 text-pl-blue outline-none"
					data-testid="notification-alert"
				/>

				<Tooltip
					className="no-text-wrap max-w-full"
					anchorSelect="#notification-alert"
				>
					{t("sidebar.published-notification")}
				</Tooltip>
			</>
		</Portal>
	);
}
