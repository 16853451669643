import { lazy } from "react";
import { type LoaderFunctionArgs, Navigate } from "react-router";
import { adminAuthorization } from "src/configs/authorization/admin";
import { PAGES } from "src/configs/pages-urls";
import { authorizationLoader } from "src/router/common-loaders";
import { lazyLoad } from "src/router/lazyload";
import type { RouterConfig } from "src/router/types";

const EmbeddedResourcePage = lazy(() =>
	import("src/module--admin/admin.lazy").then(
		(mod): { default: () => JSX.Element } => ({
			default: mod.EmbeddedResourcePage,
		}),
	),
);

export function serviceRoutes(config: RouterConfig) {
	return [
		{
			index: true,
			element: <Navigate to={PAGES.service.sub.users} replace />,
		},
		{
			path: PAGES.service.sub.gdpr,
			async lazy() {
				const { GdprPage } = await import(
					"src/module--admin/admin.lazy"
				);
				return { Component: GdprPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					adminAuthorization.admin.gdpr,
				),
		},
		{
			path: "*",
			element: lazyLoad(<EmbeddedResourcePage />),
		},
	];
}
