import { XMarkIcon } from "@heroicons/react/24/outline";
import type { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import type { ClearIndicatorProps } from "react-select";
import type { SelectOption } from "src/types/ui.types";

export function SelectClearIndicator(
	props: ClearIndicatorProps,
	{
		deselectAllOptions,
		defaultValue,
	}: {
		deselectAllOptions: () => void;
		defaultValue?: SelectOption["value"];
		required?: boolean;
	},
) {
	const { t } = useTranslation();
	const {
		getValue,
		innerProps: { ref, ...restInnerProps },
	} = props;
	const { value } = getValue()[0] as SelectOption;
	const isHidden =
		(value !== 0 && !value) ||
		(getValue()[0] as SelectOption).value === defaultValue;
	const handleEnter = (evt: KeyboardEvent) => {
		if (evt.key === "Enter") {
			evt.preventDefault();
			deselectAllOptions();
		}
	};
	return (
		!isHidden && (
			<div
				ref={ref}
				className="flex align-center"
				data-testid="select-reset-btn"
				{...restInnerProps}
			>
				<button
					type="button"
					onKeyDown={handleEnter}
					onClick={deselectAllOptions}
				>
					<span className="sr-only">{t("reset")}</span>
					<XMarkIcon className="h-5 w-5 mr-2" aria-hidden />
				</button>
			</div>
		)
	);
}
