import { type LoaderFunctionArgs, Navigate } from "react-router";
import { promiseAuthorization } from "src/configs/authorization/promise";
import { PAGES, PAGE_URLS } from "src/configs/pages-urls";
import {
	promiseCarrierLevelEditLoader,
	promiseCarrierLevelLoader,
	promiseDeliveryMethodsEditLoader,
	promiseDeliveryMethodsLoader,
	promiseDeliveryPredictionServicesLoader,
	promiseWarehousesEditLoader,
	promiseWarehousesLoader,
} from "src/module--promise/promise.loaders";
import { authorizationLoader } from "src/router/common-loaders";
import type { RouterConfig } from "src/router/types";

export function promisesRoutes(config: RouterConfig) {
	return [
		{
			index: true,
			element: <Navigate to={PAGES.promise.sub.carrierLevel} replace />,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{
						...config,
						accountIdParam,
						conditionalRedirect: true,
					},
					promiseAuthorization.promises.index,
					[
						{
							redirectUrl: PAGE_URLS.promise().carrierLevel,
							abilityList:
								promiseAuthorization.promises.carrierLevel,
						},
						{
							redirectUrl: PAGE_URLS.promise().warehouses,
							abilityList:
								promiseAuthorization.promises.warehouses,
						},
						{
							redirectUrl: PAGE_URLS.promise().deliveryMethods,
							abilityList:
								promiseAuthorization.promises.deliveryMethods,
						},
						{
							redirectUrl:
								PAGE_URLS.promise().deliveryPredictionService,
							abilityList:
								promiseAuthorization.promises
									.deliveryPredictionService,
						},
						{
							redirectUrl: PAGE_URLS.promise().promiseAnalytics,
							abilityList:
								promiseAuthorization.promises.analytics,
						},
					],
				),
		},
		{
			path: PAGES.promise.sub.carrierLevel,
			async lazy() {
				const { PromiseCarrierLevelListPage } = await import(
					"src/module--promise/promise.lazy"
				);
				return { Component: PromiseCarrierLevelListPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					promiseAuthorization.promises.carrierLevel,
				)) && promiseCarrierLevelLoader(config, accountIdParam),
			children: [
				{
					path: "new",
					async lazy() {
						const { PromiseCarrierLevelNewPage } = await import(
							"src/module--promise/promise.lazy"
						);
						return {
							Component: PromiseCarrierLevelNewPage,
						};
					},
				},
				{
					path: ":promiseId",
					async lazy() {
						const { PromiseCarrierLevelEditPage } = await import(
							"src/module--promise/promise.lazy"
						);
						return {
							Component: PromiseCarrierLevelEditPage,
						};
					},
					loader: async ({
						params: { promiseId },
					}: LoaderFunctionArgs) =>
						promiseCarrierLevelEditLoader({ ...config }, promiseId),
				},
			],
		},
		{
			path: PAGES.promise.sub.warehouses,
			async lazy() {
				const { PromiseWarehousesListPage } = await import(
					"src/module--promise/promise.lazy"
				);
				return { Component: PromiseWarehousesListPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					promiseAuthorization.promises.warehouses,
				)) && promiseWarehousesLoader(config, accountIdParam),
			children: [
				{
					path: "new",
					async lazy() {
						const { PromiseWarehousesNewPage } = await import(
							"src/module--promise/promise.lazy"
						);
						return {
							Component: PromiseWarehousesNewPage,
						};
					},
				},
				{
					path: ":promiseId",
					async lazy() {
						const { PromiseWarehousesEditPage } = await import(
							"src/module--promise/promise.lazy"
						);
						return {
							Component: PromiseWarehousesEditPage,
						};
					},
					loader: async ({
						params: { promiseId },
					}: LoaderFunctionArgs) =>
						promiseWarehousesEditLoader({ ...config }, promiseId),
				},
			],
		},
		{
			path: PAGES.promise.sub.deliveryMethods,
			async lazy() {
				const { PromiseDeliveryMethodsListPage } = await import(
					"src/module--promise/promise.lazy"
				);
				return { Component: PromiseDeliveryMethodsListPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					promiseAuthorization.promises.deliveryMethods,
				)) && promiseDeliveryMethodsLoader(config, accountIdParam),
			children: [
				{
					path: "new",
					async lazy() {
						const { PromiseDeliveryMethodsNewPage } = await import(
							"src/module--promise/promise.lazy"
						);
						return {
							Component: PromiseDeliveryMethodsNewPage,
						};
					},
				},
				{
					path: ":promiseId",
					async lazy() {
						const { PromiseDeliveryMethodsEditPage } = await import(
							"src/module--promise/promise.lazy"
						);
						return {
							Component: PromiseDeliveryMethodsEditPage,
						};
					},
					loader: async ({
						params: { promiseId },
					}: LoaderFunctionArgs) =>
						promiseDeliveryMethodsEditLoader(
							{ ...config },
							promiseId,
						),
				},
			],
		},
		{
			path: PAGES.promise.sub.deliveryPredictionService,
			async lazy() {
				const { PromiseServicePredictionPage } = await import(
					"src/module--promise/promise.lazy"
				);
				return { Component: PromiseServicePredictionPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					promiseAuthorization.promises.deliveryPredictionService,
				)) &&
				promiseDeliveryPredictionServicesLoader(config, accountIdParam),
		},
		{
			path: PAGES.promise.sub.promiseAnalytics,
			async lazy() {
				const { PromiseReport } = await import("src/components/Report");
				return { Component: PromiseReport };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					promiseAuthorization.promises.analytics,
				),
		},
	];
}
