import { type QueryClient, queryOptions } from "@tanstack/react-query";
import type { HTTPError } from "ky";
import { redirect } from "react-router";
import { getAuthHeaders } from "src/hooks/use-auth-headers";
import { notificationQueries } from "src/module--communications/communications.query-factory";
import { CommunicationTypes } from "src/module--communications/types/communications.type";
import type { ApiError } from "src/types/ApiError";
import type { UserInfo } from "src/types/oidc-user-info";
import { getLanguageCode } from "src/utils/languages";

export const emailCommunicationsDetailsLoader = async (
	qc: QueryClient,
	idToken: string,
	oidcUser: UserInfo,
	request: Request,
	accountIdParam?: string,
) => {
	try {
		const params = new URL(request.url)?.searchParams;
		const headers = getAuthHeaders(
			oidcUser,
			Number(accountIdParam),
			idToken,
		);
		const notificationParams = {
			...Object.fromEntries(params),
			lang: getLanguageCode().split("-")[0],
			user: accountIdParam,
		};

		const data = await qc.ensureQueryData(
			queryOptions(
				notificationQueries.detail(notificationParams, headers),
			),
		);
		return {
			data,
			communicationType: CommunicationTypes.Email,
		};
	} catch (err) {
		const error = err as ApiError;
		if (
			(err as HTTPError)?.response?.status === 404 ||
			error?.status === 404
		)
			return redirect("/404");
		throw error;
	}
};

export const notificationsLoader = async (
	qc: QueryClient,
	idToken: string,
	oidcUser: UserInfo,
	request: Request,
	type: CommunicationTypes,
	accountIdParam?: string,
) => {
	try {
		const params = new URL(request.url)?.searchParams;
		const headers = getAuthHeaders(
			oidcUser,
			Number(accountIdParam),
			idToken,
		);
		const notificationParams = {
			...Object.fromEntries(params),
			lang: getLanguageCode().split("-")[0],
			user: accountIdParam,
		};

		const data = await qc.ensureQueryData(
			queryOptions(
				notificationQueries.detail(notificationParams, headers),
			),
		);
		return {
			data,
			communicationType: type,
		};
	} catch (err) {
		const error = err as ApiError;

		if (
			(err as HTTPError)?.response?.status === 404 ||
			error?.status === 404
		)
			return redirect("/404");

		throw error;
	}
};
