import dayjs from "dayjs";
import type { DateType } from "react-tailwindcss-datepicker";
import type { dateFormatType } from "src/types/date";

export const getDateTimeTranslation = (
	date: string | Date,
	includeTime = false,
): string => {
	if (includeTime) {
		const formatter = `L, ${dayjs().locale() === "en" ? "hh:mm A" : "HH:mm"}`;
		return dayjs(date).format(formatter);
	}

	return dayjs(date).format("L");
};

export const getISOStringSlicedDate = (date: Date) =>
	dayjs(date).format("YYYY-MM-DD");

export const getCurrentWeekPeriodFromDate = (date: Date) => {
	const endOfWeek = dayjs(date).endOf("week").startOf("day");
	const startOfWeek = dayjs(date).startOf("week").startOf("day");

	return {
		end: endOfWeek.toDate(),
		start: startOfWeek.toDate(),
	};
};

export const getWeekPeriodAgoFromDate = (date: Date, period = 1) => {
	const targetDate = dayjs(date).subtract(period, "week");
	const day = targetDate.day(); // 0 (Sunday) to 6 (Saturday)

	// Adjust to Monday (if Sunday, go back 6 days; else, shift back to Monday)
	const start = targetDate
		.subtract(day === 0 ? 6 : day - 1, "day")
		.startOf("day");
	const end = start.add(6, "day").startOf("day"); // Sunday end of the day

	return {
		start: start.toDate(),
		end: end.toDate(),
	};
};

export const getMonthPeriodAgoFromDate = (date: Date, period = 1) => {
	// Subtract the period in months and get the start of the month
	const startOfMonth = dayjs(date)
		.subtract(period, "month")
		.startOf("month")
		.startOf("day")
		.add(1, "day");

	// Get the end of the previous month (the last day of the month)
	const endOfMonth = startOfMonth
		.endOf("month")
		.subtract(1, "day")
		.startOf("day")
		.add(1, "day");

	return {
		start: startOfMonth.toDate(), // Convert back to JavaScript Date object
		end: endOfMonth.toDate(), // Convert back to JavaScript Date object
	};
};

export const getDaysBeforeDate = (date: Date, days = 1) => {
	const startDate = dayjs(date).subtract(days, "day");
	return {
		start: getISOStringSlicedDate(startDate.toDate()),
		end: getISOStringSlicedDate(date),
	};
};

export const isDateIncludesToday = ({ from, to }: { from: Date; to: Date }) => {
	const todayDate = dayjs().startOf("day");
	const fromDate = dayjs(from).startOf("day");
	const toDate = dayjs(to).startOf("day");

	return fromDate.isBefore(todayDate) && toDate.isAfter(todayDate);
};

export const setDateTimeToEndOfTheDay = (dateString: string) => {
	return dayjs(dateString).endOf("day").toDate();
};

export const setDateTimeToStartOfTheDayDateString = (dateString: string) => {
	return dayjs(dateString).startOf("day").toISOString();
};

export const setDateTimeToStartOfTheDay = (dateString: string) =>
	dayjs(dateString).startOf("day").toDate();

export const getFormattedDate = (date: string, format: dateFormatType) => {
	return format === "zulu"
		? dayjs(date).toISOString()
		: dayjs(date).format("YYYY-MM-DDTHH:mm:00");
};

export const getLocalDateTime = (date: string | Date, lang?: string) => {
	return dayjs(date).toDate().toLocaleString(lang, {
		year: "numeric",
		month: "2-digit",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
	});
};

export const internationalDateFormatter = (date: string | DateType) =>
	dayjs(date).format("YYYY-MM-DD");

export const getYesterday = () => {
	return dayjs().subtract(1, "day").format("YYYY-MM-DD");
};
