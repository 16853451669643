import type { AccountAccountsListData } from "src/types/_generated";

export const CM_NOTIFICATION_LOCAL_STORAGE_KEY = "pL-campaign-notification";
export const BANNER_LOCAL_STORAGE_KEY = "pL-notification-banner";

export const DEFAULT_HIGHLIGHT_COLOR = "rgb(255, 222, 112)";

export const PAGE_SIZE = 36;
export const DEFAULT_TRACKINGS_DAYS_LIMIT = 90;
export const DEFAULT_COMMUNICATE_DAYS_LIMIT = 7;
export const SLIDER_CLOSING_DELAY = 700;

export const ANIMATION_VARIANTS = {
	initial: { opacity: 0, x: -200, duration: 0.5 },
	animate: { opacity: 1, x: 0, duration: 0.5 },
	exit: { opacity: 0, x: 200, duration: 0.5 },
};

export const ANIMATION_TRANSITION = {
	duration: 0.5,
};

export const INITIAL_ACCOUNT_SEARCH_PARAMS = {
	limit: 50,
	search: "",
} satisfies AccountAccountsListData["query"];

export const STALE_QUERY_CLIENT_TIME = 1000 * 60;

export const APP_URL_PREFIX = "a";

export const APP_SIGNED_IN_FLAG = "signedIn";

export const FILTERS_CURRENT_VIEW_PARAM = "currentView";

export const GLOBAL_ACCOUNT_ID = 1;
export const SUPPORT_EMAIL = "support@parcellab.com";
export const UNLIMITED_PAGE_SIZE = 1000;
