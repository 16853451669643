import { type VariantProps, cva } from "class-variance-authority";
import { type ComponentProps, forwardRef } from "react";
import { cn } from "src/utils/tailwind";

const flexLayoutVariants = cva(["flex flex-col"], {
	variants: {
		variant: {
			centeredPage:
				"m-auto w-screen max-w-xl rounded-md shadow-xl sm:px-6 lg:max-w-[70vw]",
		},
		padding: {
			0: "",
			1: "[&>.layout-content]:p-1",
			2: "[&>.layout-content]:p-2",
			3: "[&>.layout-content]:p-3",
			4: "[&>.layout-content]:p-4",
		},
		height: {
			full: "h-full",
			screen: "h-screen",
		},
	},
	defaultVariants: {
		height: "full",
	},
});
interface Props
	extends ComponentProps<"div">,
		VariantProps<typeof flexLayoutVariants> {}

function FlexLayout({
	variant,
	className,
	padding = 0,
	height,
	...others
}: Props) {
	return (
		<div
			className={cn(
				flexLayoutVariants({ variant, padding, height }),
				className,
			)}
			{...others}
		/>
	);
}

type LayoutFooterProps = {
	shadowed?: boolean;
} & ComponentProps<"div">;

const LayoutFooter = forwardRef<HTMLDivElement, LayoutFooterProps>(
	({ className, shadowed = true, ...others }, ref) => (
		<div
			ref={ref}
			className={cn(
				"layout-content flex-shrink-0",
				{ "shadow-md": shadowed },
				className,
			)}
			{...others}
		/>
	),
);

FlexLayout.Footer = LayoutFooter;

function LayoutBody({
	className,
	scrollable = true,
	...others
}: ComponentProps<"div"> & { scrollable?: boolean }) {
	return (
		<div
			className={cn(
				"layout-content flex flex-[1] flex-col",
				{ "overflow-auto": scrollable },
				className,
			)}
			{...others}
		/>
	);
}

FlexLayout.Body = LayoutBody;

type LayoutHeaderProps = {
	bordered?: boolean;
	shadowed?: boolean;
} & ComponentProps<"div">;

function LayoutHeader({
	className,
	bordered = true,
	shadowed = true,
	...others
}: LayoutHeaderProps) {
	return (
		<div
			className={cn(
				"layout-content flex flex-[0]",
				{ "border-b": bordered },
				{ "shadow-md": shadowed },
				className,
			)}
			{...others}
		/>
	);
}

FlexLayout.Header = LayoutHeader;

export { FlexLayout };
