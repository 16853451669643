import { QueryErrorResetBoundary } from "@tanstack/react-query";
/* eslint-disable react/no-unstable-nested-components */
import { type PropsWithChildren, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorState } from "src/components/ErrorState";
import { LoadingScreen } from "src/components/LoadingScreen";
import type { FeedbackVariantsProps } from "src/components/utils/feedback.variants";

export type PageStateBoundaryProps = PropsWithChildren<{
	variant?: FeedbackVariantsProps["variant"];
}>;

export function PageStateBoundary({
	children,
	variant,
}: PageStateBoundaryProps) {
	return (
		<QueryErrorResetBoundary>
			{({ reset }) => (
				<ErrorBoundary
					fallbackRender={({ error, resetErrorBoundary }) => (
						<ErrorState
							error={error as Error}
							resetErrorBoundary={resetErrorBoundary}
							variant={variant}
						/>
					)}
					onReset={reset}
				>
					<Suspense fallback={<LoadingScreen />}>{children}</Suspense>
				</ErrorBoundary>
			)}
		</QueryErrorResetBoundary>
	);
}
