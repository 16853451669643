import { FolderPlusIcon } from "@heroicons/react/24/outline";
import type { ComponentProps, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { AnchorButton } from "src/components/ui/AnchorButton";
import {
	type FeedbackVariantsProps,
	feedbackVariants,
} from "src/components/utils/feedback.variants";
import { cn } from "src/utils/tailwind";

export type EmptyStateProps = {
	Icon?: typeof FolderPlusIcon;
	iconClassName?: string;
} & {
	title?: ReactNode;
	description?: ReactNode;
	to?: string;
} & Omit<ComponentProps<"div">, "title"> &
	FeedbackVariantsProps;

export function EmptyState({
	title,
	Icon,
	description,
	variant = "fit",
	to,
	className,
	iconClassName,
	...others
}: EmptyStateProps) {
	const IconComponent = Icon || FolderPlusIcon;
	const { t } = useTranslation();

	function content() {
		return (
			<div
				data-testid="empty-state"
				className={cn(
					feedbackVariants({ variant }),
					className,
					"bg-background",
					to && "hover:border-slate-400",
				)}
				{...others}
			>
				<IconComponent
					className={cn("h-20 w-20 text-indigo-400", iconClassName)}
				/>

				<span className="block text-xl font-medium text-slate-900 mt-4">
					{title || t("empty.title")}
				</span>
				{description && (
					<span className="block max-w-lg text-center text-base font-medium text-slate-700 p-4">
						{description}
					</span>
				)}
			</div>
		);
	}
	return to ? (
		<AnchorButton
			rounded="xl"
			className="w-full"
			type="button"
			variant="noStyle"
			to={to}
		>
			{content()}
		</AnchorButton>
	) : (
		<>{content()}</>
	);
}
