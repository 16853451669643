import { type LoaderFunctionArgs, Navigate } from "react-router";
import { trackingsAuthorization } from "src/configs/authorization/trackings";
import { PAGES } from "src/configs/pages-urls";
import { trackingDetailsLoader } from "src/module--trackings/trackings.loaders";
import { authorizationLoader } from "src/router/common-loaders";
import type { RouterConfig } from "src/router/types";

export function trackingsRoutes(config: RouterConfig) {
	return [
		{
			index: true,
			element: <Navigate to={PAGES.trackings.sub.trackings} replace />,
		},
		{
			path: PAGES.trackings.sub.trackings,
			async lazy() {
				const { TrackingsPage } = await import(
					"src/module--trackings/trackings.lazy"
				);
				return { Component: TrackingsPage };
			},
		},
		{
			path: `${PAGES.trackings.sub.details}`,
			async lazy() {
				const { TrackingPage } = await import(
					"src/module--trackings/trackings.lazy"
				);
				return {
					Component: TrackingPage,
					loader: async ({
						params: { accountId },
						request,
					}: LoaderFunctionArgs) =>
						trackingDetailsLoader(
							{ ...config, request },
							accountId,
						),
				};
			},
			children: [
				{
					path: PAGES.trackings.sub.return,
					async lazy() {
						const { TrackingsReturnPage } = await import(
							"src/module--trackings/trackings.lazy"
						);
						return { Component: TrackingsReturnPage };
					},
					loader: async ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							trackingsAuthorization.trackings.returnsInitiation,
						),
				},
			],
		},
		{
			path: PAGES.trackings.sub.returnsOverview,
			async lazy() {
				const { TrackingsReturnsOverviewPage } = await import(
					"src/module--trackings/trackings.lazy"
				);
				return { Component: TrackingsReturnsOverviewPage };
			},
		},
		{
			path: PAGES.trackings.sub.delivery,
			async lazy() {
				const { TrackingsDeliveryReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsDeliveryReport };
			},
		},
		{
			path: PAGES.trackings.sub.deliveryExceptions,
			async lazy() {
				const { TrackingsDeliveryExceptionsReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsDeliveryExceptionsReport };
			},
		},
		{
			path: PAGES.trackings.sub.leadTimes,
			async lazy() {
				const { TrackingsLeadtimesReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsLeadtimesReport };
			},
		},
		{
			path: PAGES.trackings.sub.claimsPromotion,
			async lazy() {
				const { TrackingsClaimsPromotionPage } = await import(
					"src/module--trackings/trackings.lazy"
				);
				return { Component: TrackingsClaimsPromotionPage };
			},
		},
		{
			path: PAGES.trackings.sub.claims,
			async lazy() {
				const { TrackingsClaimsReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsClaimsReport };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					trackingsAuthorization.trackings.claims,
				),
		},
		{
			path: PAGES.trackings.sub.liveNetwork,
			async lazy() {
				const { TrackingsLivenetworkReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsLivenetworkReport };
			},
		},
		{
			path: PAGES.trackings.sub.communicationsInsights,
			async lazy() {
				const { TrackingsCommsInsightsReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsCommsInsightsReport };
			},
		},
		{
			path: PAGES.trackings.sub.exceptions,
			async lazy() {
				const { TrackingsExceptionsReport } = await import(
					"src/components/Report"
				);
				return { Component: TrackingsExceptionsReport };
			},
		},
		{
			path: PAGES.trackings.sub.benchmarkingPromotion,
			async lazy() {
				const { BenchmarkingPromotionPage } = await import(
					"src/module--trackings/trackings.lazy"
				);
				return { Component: BenchmarkingPromotionPage };
			},
		},
		{
			path: PAGES.trackings.sub.benchmarking,
			async lazy() {
				const { BenchmarkingCarrierReport } = await import(
					"src/components/Report"
				);
				return { Component: BenchmarkingCarrierReport };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					trackingsAuthorization.trackings.carrier,
					[
						{
							redirectUrl:
								PAGES.trackings.sub.benchmarkingPromotion,
							abilityList:
								trackingsAuthorization.trackings
									.benchmarkingPromotion,
						},
					],
				),
		},
	];
}
