import type { HTTPError } from "ky";
import { first } from "lodash";
import type { JsonValue } from "type-fest";

type ParseHTTPErrorType = Record<string, JsonValue> | Record<string, string>;

export async function fetchErrorResponse(error: HTTPError) {
	// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
	let result;
	try {
		result = await error.response.json();
	} catch (err: unknown) {
		result = err;
	}

	return result as ParseHTTPErrorType;
}

export async function parseHTTPError(error: HTTPError): Promise<string | null> {
	try {
		const errorResponse = (await fetchErrorResponse(error)) as Record<
			string,
			string
		>;
		const errorMessage = Object.entries(errorResponse).map(
			([key, value]) => `${key}: ${value}`,
		);

		return errorMessage.join("\n");
	} catch {
		return null;
	}
}

export function getInitialHTTPErrorMessage(
	error: string | undefined | null,
): string | undefined {
	if (!error) {
		return undefined;
	}

	return first(error.split("\n\n"));
}
