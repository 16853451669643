import type { Dispatch, SetStateAction } from "react";
import { useLocalStorage } from "react-use";
import { useOidcUser } from "src/services/oidc-exporter";
import { getDefaultUserAccountId } from "src/utils/user";

export const useLocalStorageAccountId = (): [
	number | undefined,
	Dispatch<SetStateAction<number | undefined>>,
] => {
	const { oidcUser } = useOidcUser();

	const accountId = oidcUser ? getDefaultUserAccountId(oidcUser) : undefined;

	const [localAccountId, setLocalAccountId] = useLocalStorage<number>(
		"pl-account-id",
		accountId,
		{
			raw: true,
		},
	);

	return [
		localAccountId ? Number(localAccountId) : localAccountId,
		setLocalAccountId,
	];
};
