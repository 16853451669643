import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";
import {
	AccountFeatureFlags,
	UserFeatureFlags,
} from "src/types/portal-features.type";

type CommunicationsAuthorization = {
	communications: GenericAuthorizationMap<
		| "index"
		| "emails"
		| "sms"
		| "webhooks"
		| "insights"
		| "emotionAI"
		| "emotionAIPromotion"
		| "benchmarking"
		| "benchmarkingPromotion"
	>;
};

export const communicationsAuthorization: CommunicationsAuthorization = {
	communications: {
		index: {
			can: {
				allOf: [[Action.view, Model.TRACK_EMAIL]],
				oneOf: [
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPreviewFeatures,
					],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasPortalBeta,
					],
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPortalBetaEnabled,
					],
				],
			},
		},
		emails: {
			can: { oneOf: [[Action.view, Model.TRACK_EMAIL]] },
		},
		sms: {
			can: { oneOf: [[Action.view, Model.TRACK_SMS]] },
		},
		webhooks: {
			can: { oneOf: [[Action.view, Model.TRACK_WEBHOOK]] },
		},
		insights: {
			can: {
				oneOf: [[Action.view, Model.PORTAL_REPORT]],
			},
		},
		emotionAI: {
			can: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasEmotionAi,
					],
				],
			},
		},
		emotionAIPromotion: {
			cannot: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasEmotionAi,
					],
				],
			},
		},
		benchmarking: {
			can: {
				allOf: [
					[Action.view, Model.PORTAL_REPORT],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasBenchmarking,
					],
				],
			},
		},
		benchmarkingPromotion: {
			can: {
				allOf: [[Action.view, Model.PORTAL_REPORT]],
			},
			cannot: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasBenchmarking,
					],
				],
			},
		},
	},
};
