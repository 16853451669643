import type React from "react";
import { cn } from "src/utils/tailwind.ts";

interface PiiWrapperProps {
	enable?: boolean;
	className?: string;
	children: React.ReactNode;
}

export function PiiWrapper({
	children,
	className,
	enable = true,
}: PiiWrapperProps) {
	return (
		<span
			className={
				enable ? cn(`pii data-hj-suppress ${className}`) : className
			}
		>
			{children}
		</span>
	);
}
