import { useQuery } from "@tanstack/react-query";
import { useCurrentAccountId } from "src/hooks/use-account-id";
import { portalReportEmbedTokenTokenCreateOptions } from "src/types/_generated/@tanstack/react-query.gen";

export const useReportData = () => {
	const account_id = useCurrentAccountId();
	return useQuery(
		portalReportEmbedTokenTokenCreateOptions({ path: { account_id } }),
	);
};
