import type { VariantProps } from "class-variance-authority";
import { type ComponentProps, type ReactNode, useContext } from "react";
import { ErrorTextFormElement } from "src/components/form/ErrorText/ErrorTextFormElement";
import { FormElementDescription } from "src/components/form/FormElementDescription";
import { InputLabel, type labelVariants } from "src/components/form/InputLabel";
import { Switch } from "src/components/form/Switch/Switch";
import { baseFormElementWrapperVariants } from "src/components/form/form.styles";
import type { FormikFieldProps } from "src/components/form/form.types";
import { FormContext } from "src/contexts/form-context";
import { cn } from "src/utils/tailwind";

export type SwitchFormElementProps = FormikFieldProps<boolean> &
	ComponentProps<typeof Switch> & {
		label?: ReactNode;
		description?: ReactNode;
		descriptionModalContent?: ReactNode;
		className?: string;
		wrapperClassName?: string;
		defaultChecked?: boolean;
		id?: string;
		readOnly?: boolean;
		disabled?: boolean;
		required?: boolean;
		tooltip?: string;
		hideOptionalText?: boolean;
		labelStyle?: VariantProps<typeof labelVariants>["filedStyle"];
	} & VariantProps<typeof baseFormElementWrapperVariants>;

export function SwitchFormElement({
	label,
	field,
	description,
	descriptionModalContent,
	meta,
	wrapperClassName,
	tooltip,
	id,
	required,
	hideOptionalText,
	labelStyle,
	...rest
}: SwitchFormElementProps) {
	const { layout: formLayout } = useContext(FormContext);

	const appliedLayout = rest.layout || formLayout;

	return (
		<div
			className={cn(
				baseFormElementWrapperVariants({
					layout: appliedLayout,
				}),
				wrapperClassName,
			)}
			id={id}
		>
			{!!label && (
				<InputLabel
					className="mb-0"
					htmlFor={field.name}
					meta={meta}
					required={required}
					hideOptionalText={hideOptionalText}
					filedStyle={labelStyle}
				>
					{label}
				</InputLabel>
			)}

			<Switch field={field} meta={meta} {...rest} />
			{!!description && (
				<FormElementDescription
					descriptionModalContent={descriptionModalContent}
					tooltip={tooltip}
					className={
						appliedLayout === "horizontalWide" ||
						appliedLayout === "vertical"
							? "md:col-start-1 ml-0"
							: ""
					}
				>
					{description}
				</FormElementDescription>
			)}
			<ErrorTextFormElement name={field.name} />
		</div>
	);
}
