import { type LoaderFunctionArgs, redirect } from "react-router";
import { appStoreAuthorization } from "src/configs/authorization/app-store";
import { PAGES } from "src/configs/pages-urls";
import { authorizationLoader } from "src/router/common-loaders";
import type { RouterConfig } from "src/router/types";

const appStoreChildrenRoutes = (config: RouterConfig) => [
	{
		path: `${PAGES.appStore.sub.app}/:id`,
		async lazy() {
			const { App } = await import(
				"src/module--app-store/app-store.lazy"
			);
			return { Component: App };
		},
		loader: ({
			params: { accountId: accountIdParam },
		}: LoaderFunctionArgs) =>
			authorizationLoader(
				{ ...config, accountIdParam },
				appStoreAuthorization.appStore.app,
			),
	},
	{
		path: `${PAGES.appStore.sub.recipe}/:id`,
		async lazy() {
			const { Recipe } = await import(
				"src/module--app-store/app-store.lazy"
			);
			return { Component: Recipe };
		},
		loader: ({
			params: { accountId: accountIdParam },
		}: LoaderFunctionArgs) =>
			authorizationLoader(
				{ ...config, accountIdParam },
				appStoreAuthorization.appStore.recipe,
			),
	},
];

export function appStoreRoutes(config: RouterConfig) {
	return [
		{
			path: "",
			loader: () => redirect(PAGES.appStore.sub.all),
		},
		{
			path: `${PAGES.appStore.sub.all}`,
			async lazy() {
				const { Apps } = await import(
					"src/module--app-store/app-store.lazy"
				);
				return { Component: Apps };
			},
			children: appStoreChildrenRoutes(config),
		},
		{
			path: `${PAGES.appStore.sub.category}/:tagFilter`,
			async lazy() {
				const { Apps } = await import(
					"src/module--app-store/app-store.lazy"
				);
				return { Component: Apps };
			},
			children: appStoreChildrenRoutes(config),
		},
	];
}
