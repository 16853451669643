import { redirect } from "react-router";
import type { LoaderConfig } from "src/router/types";
import type { ApiError } from "src/types/ApiError";
import { returnsCourierApisListOptions } from "src/types/_generated/@tanstack/react-query.gen";

export const returnsCourierApisLoader = (
	config: LoaderConfig,
	accountId: string,
) => {
	try {
		return config.queryClient.ensureQueryData(
			returnsCourierApisListOptions({
				query: { account: [Number(accountId)] },
			}),
		);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};
