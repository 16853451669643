import i18next from "i18next";
import ISO6391, { type LanguageCode } from "iso-639-1";
import { defaultLanguage } from "src/locales/language-config";

export const allCodes = () => ISO6391.getAllCodes();
export const allNames = () => ISO6391.getAllNames();
export const getName = (code: string) => ISO6391.getName(code);
export const getNativeName = (code: string) => ISO6391.getNativeName(code);

export const getLanguageOptions = (addMul = false) => {
	const options = allCodes().map((code) => ({
		label: `${code} - ${getName(code)}`,
		value: code,
	}));
	if (addMul) {
		const mulCode = "mul" as LanguageCode;
		options.push({ label: `${mulCode} - Multi Language`, value: mulCode });
	}
	return options;
};

export const getLanguageCode = (language?: string | undefined) =>
	(language ?? i18next.language ?? defaultLanguage).split("-")[0];
