import { useCallback, useState } from "react";

export type DiscloseArgs = {
	isOpen: boolean;
	toggle: () => void;
	close: () => void;
	open: () => void;
	onOpenChange: (isOpen: boolean) => void;
};

export function useDisclose(defaultOpen?: boolean): DiscloseArgs {
	const [isOpen, setIsOpen] = useState(defaultOpen ?? false);

	const open = useCallback(() => {
		setIsOpen(true);
	}, []);

	const toggle = useCallback(() => {
		setIsOpen((isOpenState) => !isOpenState);
	}, []);

	const close = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onOpenChange = useCallback((isOpen: boolean) => {
		setIsOpen(isOpen);
	}, []);

	return {
		isOpen,
		toggle,
		close,
		open,
		onOpenChange,
	};
}
