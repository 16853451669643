import { useQueryClient } from "@tanstack/react-query";
import { pick } from "lodash";
import { Fragment, type PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextInputFormElement } from "src/components/form";
import {
	Form,
	type FormProps,
	type FormSubmitType,
} from "src/components/form/Form";
import { SelectFormElement } from "src/components/form/Select/SelectFormElement";
import { FormSubmitButton } from "src/components/form/SubmitButton";
import { FormikFieldSwitch } from "src/components/form/Switch/FormikFieldSwitch";
import type { PropsWithActionWrapper } from "src/components/form/form.types";
import { PAGE_URLS } from "src/configs/pages-urls";
import { Action, Model } from "src/contexts/authorization-context";
import { useUserSettingsMutation } from "src/hooks/use-user-settings";
import {
	ProfileRoleEnum,
	type UserSettings,
	type UserSettingsRequest,
} from "src/types/_generated";
import { accountUsersMeRetrieveQueryKey } from "src/types/_generated/@tanstack/react-query.gen";

type FormType = UserSettingsRequest;

type Props = Partial<FormProps<FormType, UserSettings>> &
	PropsWithActionWrapper<{
		userSettings: UserSettings;
		onSuccess?: () => void;
		showOptIn?: boolean;
		submitDisabled?: boolean;
	}>;

export function UserForm({
	id = "user-form",
	userSettings,
	onSuccess,
	initialValues,
	actionWrapper: ActionWrapper = Fragment,
	showOptIn = true,
	submitDisabled,
	children,
	...rest
}: PropsWithChildren<Props>) {
	const { t } = useTranslation(undefined, { keyPrefix: "user-form" });
	const { t: tBase } = useTranslation();
	const { mutateAsync } = useUserSettingsMutation();
	const queryClient = useQueryClient();

	const handleSubmit: FormSubmitType<FormType, UserSettings> = (values) =>
		mutateAsync(
			{
				body: {
					...values,
					profileCompleted: true,
				},
			},
			{
				onSuccess() {
					void queryClient.invalidateQueries({
						queryKey: accountUsersMeRetrieveQueryKey(),
					});
					onSuccess?.();
				},
			},
		);

	return (
		<Form<FormType, UserSettings>
			onSubmit={handleSubmit}
			initialValues={{
				...pick(
					userSettings,
					"firstName",
					"lastName",
					"phoneNumber",
					"profileRole",
					"jobTitle",
					"hasMarketingOptIn",
				),
				...initialValues,
			}}
			className="mx-0"
			enableReinitialize
			id={id}
			authorizationArguments={{
				can: {
					allOf: [[Action.change, Model.ACCOUNT_USER]],
				},
			}}
			{...rest}
		>
			<TextInputFormElement
				name="firstName"
				label={t("firstName")}
				required
			/>
			<TextInputFormElement
				name="lastName"
				label={t("lastName")}
				required
			/>
			<TextInputFormElement
				name="phoneNumber"
				label={t("phoneNumber")}
				pattern="^([+\d\s()-]{1,20})?$"
				type="tel"
			/>
			<TextInputFormElement
				name="jobTitle"
				label={t("jobTitle")}
				required
			/>
			<SelectFormElement
				name="profileRole"
				label={t("profileRole")}
				required
				options={Object.values(ProfileRoleEnum).map((i) => ({
					value: i,
					label: t(`profileRoleOption.${i}`),
				}))}
			/>
			{showOptIn && (
				<FormikFieldSwitch
					name="hasMarketingOptIn"
					required
					label={t("hasMarketingOptIn.title")}
					description={
						<Trans
							i18nKey="user-form.hasMarketingOptIn.description"
							components={{
								a: (
									<a
										href={PAGE_URLS.privacyPolicy.path}
										target="_blank"
										title={tBase("PrivacyPolicy")}
										rel="noreferrer"
										className="text-pl-blue hover:underline"
									>
										{tBase("PrivacyPolicy")}
									</a>
								),
							}}
						/>
					}
				/>
			)}
			{children}
			<ActionWrapper>
				<FormSubmitButton disabled={submitDisabled} form={id}>
					{t("submitText")}
				</FormSubmitButton>
			</ActionWrapper>
		</Form>
	);
}
