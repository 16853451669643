import type { VariantProps } from "class-variance-authority";
import {
	Formik,
	type FormikConfig,
	type FormikErrors,
	Form as FormikForm,
	type FormikFormProps,
	type FormikHelpers,
	type FormikProps,
	type FormikValues,
	isPromise,
} from "formik";
import type { ComponentProps, ReactNode } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FormValuesChangeListener } from "src/components/form/FormValuesChangeListener";
import { ScrollToError } from "src/components/form/ScrollToError";
import {
	type baseFormElementWrapperVariants,
	baseFormVariants,
} from "src/components/form/form.styles";
import {
	type FormError,
	errorIsPartOfForm,
	parseFormError,
} from "src/components/form/utils";
import type { AbilityArguments } from "src/contexts/authorization-context";
import { FormContextProvider } from "src/contexts/form-context";
import { useHasAuthorization } from "src/hooks/use-has-authorization";
import { cn } from "src/utils/tailwind";

export type FormHelpers<T> = {
	onSuccess: () => void;
	onError: (error: Error) => void;
	onSettled: () => void;
} & FormikHelpers<T>;

export type FormSubmitType<T, R = T> = (
	values: T,
	helpers: FormHelpers<T>,
	// biome-ignore lint/suspicious/noConfusingVoidType: void should be usable
) => void | undefined | Promise<R>;

type ChildrenProp<T> =
	| ReactNode
	| undefined
	| ((props: FormikProps<T>) => ReactNode);

export type FormProps<T, R = T> = Pick<
	FormikConfig<T>,
	| "initialValues"
	| "validate"
	| "validateOnBlur"
	| "validateOnChange"
	| "enableReinitialize"
> &
	Omit<FormikFormProps, "children"> &
	VariantProps<typeof baseFormElementWrapperVariants> &
	VariantProps<typeof baseFormVariants> & {
		onSubmit: FormSubmitType<T, R>;
		children?: ChildrenProp<T>;
		childrenOuterForm?: ChildrenProp<T>;
		authorizationArguments?: AbilityArguments;
		silentSuccess?: boolean;
		onValuesChange?: (values: T) => void;
	};

function DisabledFieldset({ children, disabled }: ComponentProps<"fieldset">) {
	if (!disabled) return children;

	return (
		<fieldset style={{ all: "inherit" }} disabled>
			{children}
		</fieldset>
	);
}

export function Form<T extends FormikValues = never, R = T>({
	className,
	layout,
	formLayout,
	columns,
	initialValues,
	onSubmit,
	id,
	children,
	validate,
	validateOnBlur,
	validateOnChange,
	enableReinitialize,
	childrenOuterForm,
	authorizationArguments,
	silentSuccess,
	onValuesChange,
	...rest
}: FormProps<T, R>) {
	const { t } = useTranslation();
	const authorized = useHasAuthorization(
		authorizationArguments,
		!authorizationArguments,
	);

	function handleSettled(helpers: FormikHelpers<T>) {
		helpers.setSubmitting(false);
	}

	function handleSuccess() {
		if (silentSuccess) return;

		toast.success(
			t(`${id ?? "form"}.messages.success`, {
				defaultValue: t("form.messages.success"),
			}),
		);
	}

	async function handleError(
		err: FormError,
		values: T,
		helpers: FormikHelpers<T>,
	) {
		const { error, message, isHTTPMessage } = await parseFormError(err);

		if (!isHTTPMessage && errorIsPartOfForm(values, error)) {
			helpers.setErrors(error as FormikErrors<T>);
			return;
		}

		if (message) {
			toast.error(message);
			return;
		}

		toast.error(
			t(`${id ?? "form"}.messages.error`, {
				defaultValue: t("form.messages.error"),
			}),
		);
	}

	const handleSubmit = (values: T, helpers: FormikHelpers<T>) => {
		const result = onSubmit(values, {
			onSuccess: () => handleSuccess(),
			onError: (error: FormError) => handleError(error, values, helpers),
			onSettled: () => handleSettled(helpers),
			...helpers,
		});

		if (isPromise(result)) {
			result
				.then(handleSuccess)
				.catch((error: FormError) =>
					handleError(error, values, helpers),
				);
		}

		return result;
	};

	return (
		<FormContextProvider
			authorizationArguments={authorizationArguments}
			authorized={authorized}
			layout={layout || "default"}
			columns={columns}
		>
			<Formik<T>
				initialValues={initialValues}
				onSubmit={(v, h) => handleSubmit(v, h)}
				validate={validate}
				validateOnBlur={validateOnBlur}
				validateOnChange={validateOnChange}
				enableReinitialize={enableReinitialize}
			>
				{(props) => (
					<>
						<FormikForm
							className={cn(
								baseFormVariants({
									formLayout: formLayout || "default",
								}),
								className,
							)}
							id={id}
							{...rest}
						>
							<DisabledFieldset disabled={!authorized}>
								{typeof children === "function"
									? children(props)
									: children}
								<ScrollToError />
							</DisabledFieldset>
						</FormikForm>
						{typeof childrenOuterForm === "function"
							? childrenOuterForm(props)
							: childrenOuterForm}

						{onValuesChange && (
							<FormValuesChangeListener
								onValuesChange={onValuesChange}
							/>
						)}
					</>
				)}
			</Formik>
		</FormContextProvider>
	);
}
