import type { VariantProps } from "class-variance-authority";
import { type PropsWithChildren, createContext, useMemo } from "react";
import type {
	baseFormElementWrapperVariants,
	baseFormVariants,
} from "src/components/form/form.styles";
import type { AbilityArguments } from "src/contexts/authorization-context";

type FormContextType = VariantProps<typeof baseFormElementWrapperVariants> &
	VariantProps<typeof baseFormVariants> & {
		authorized: boolean;
		authorizationArguments?: AbilityArguments;
	};

export const FormContext = createContext<FormContextType>({
	layout: "default",
	authorized: true,
});

export function FormContextProvider(
	props: FormContextType & PropsWithChildren,
) {
	const {
		children,
		layout = "default",
		authorized,
		authorizationArguments,
	} = props;

	const value = useMemo(
		() => ({
			layout,
			authorized,
			authorizationArguments,
		}),
		[layout, authorized, authorizationArguments],
	);

	return (
		<FormContext.Provider value={value}>{children}</FormContext.Provider>
	);
}
