import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";
import {
	AccountFeatureFlags,
	UserFeatureFlags,
} from "src/types/portal-features.type";

type TrackingsAuthorization = {
	trackings: GenericAuthorizationMap<
		| "index"
		| "insights"
		| "returnsOverview"
		| "claimsPromotion"
		| "claims"
		| "benchmarkingPromotion"
		| "carrier"
		| "controlTower"
		| "returnsInitiation"
	>;
};

export const trackingsAuthorization: TrackingsAuthorization = {
	trackings: {
		index: {
			can: {
				allOf: [[Action.view, Model.TRACK_TRACKING]],
				oneOf: [
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPreviewFeatures,
					],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasPortalBeta,
					],
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPortalBetaEnabled,
					],
				],
			},
		},
		insights: {
			can: {
				oneOf: [[Action.view, Model.PORTAL_REPORT]],
			},
		},
		returnsOverview: {
			can: {
				oneOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasReturnsOverview,
					],
				],
			},
		},
		claimsPromotion: {
			cannot: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasClaimsReport,
					],
				],
			},
		},
		claims: {
			can: {
				allOf: [
					[Action.view, Model.PORTAL_REPORT],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasClaimsReport,
					],
				],
			},
		},
		benchmarkingPromotion: {
			cannot: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasBenchmarking,
					],
				],
			},
		},
		carrier: {
			can: {
				allOf: [
					[Action.view, Model.PORTAL_REPORT],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasBenchmarking,
					],
				],
			},
		},
		controlTower: {
			can: {
				oneOf: [[Action.view, Model.PORTAL_REPORT]],
			},
		},
		returnsInitiation: {
			can: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasTrackingReturnInitiation,
					],
				],
			},
		},
	},
};
