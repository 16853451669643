export const commonTextVariants = {
	color: {
		primary: "text-primary",
		secondary: "text-secondary",
		white: "text-white",
		foregroundDark: "text-foreground-dark",
		foregroundMedium: "text-foreground-medium",
		foreground: "text-foreground",
	},
	size: {
		xs: "text-xs",
		sm: "text-sm",
		base: "text-base",
		lg: "text-lg",
		xl: "text-xl",
		"2xl": "text-2xl",
		"3xl": "text-3xl",
		"4xl": "text-4xl",
		"5xl": "text-5xl",
	},
	muted: {
		true: "text-muted-foreground",
	},
	capitalized: {
		true: "capitalize",
	},
	uppercase: {
		true: "uppercase",
	},
	fontWeight: {
		normal: "font-normal",
		medium: "font-medium",
		bold: "font-bold",
		semibold: "font-semibold",
		extrabold: "font-extrabold",
	},
	ellipsis: {
		true: "text-ellipsis overflow-hidden whitespace-nowrap",
	},
	leading: {
		none: "leading-none",
		tight: "leading-tight",
		normal: "leading-normal",
		loose: "leading-loose",
	},
};
