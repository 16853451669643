import { ArrowsPointingOutIcon } from "@heroicons/react/20/solid";
import * as Sentry from "@sentry/react";
import type { Embed, Report, service } from "powerbi-client";
import { type EventHandler, PowerBIEmbed } from "powerbi-client-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorState } from "src/components/ErrorState";
import { LoadingScreen } from "src/components/LoadingScreen";
import { Button } from "src/components/ui/Button";
import type { FeedbackVariantsProps } from "src/components/utils/feedback.variants";
import { useReportData } from "src/hooks/use-report-data";
import type { DashboardType } from "src/types/dashboard.type";
import { getLanguageFilters, getReportConfig } from "src/utils/report-config";
import { cn } from "src/utils/tailwind";
import { Flex } from "../ui/Flex";

export function PowerBi({
	className,
	title,
	wrapperClassName,
	feedbackVariant = "fullScreen",
	isFullViewButton = true,
	dashboardInfo,
}: {
	className?: string;
	title?: string;
	wrapperClassName?: string;
	feedbackVariant?: FeedbackVariantsProps["variant"];
	isFullViewButton?: boolean;
	dashboardInfo: DashboardType;
}) {
	const [report, setReport] = useState<Report>();
	const [iframeError, setIframeError] = useState<boolean>(false);
	const { isLoading, error, data: reportData } = useReportData();

	const {
		t,
		i18n: { language },
	} = useTranslation();

	if (isLoading) {
		return (
			<LoadingScreen
				variant={feedbackVariant}
				data-testid="powerbi-loader"
			/>
		);
	}
	if (error || !reportData?.token) {
		return (
			<ErrorState
				variant={feedbackVariant}
				data-testid="powerbi-error"
				error={error}
			/>
		);
	}
	const data = { ...reportData, dashboardInfo };
	const handleIframeError = (event: service.ICustomEvent<Event>) => {
		if (event) {
			console.error("Error embedding the dashboard:", event.detail);
			setIframeError(true);
		}
	};

	const handleIframeLoaded = (
		event: service.ICustomEvent<Event> & {
			target: { powerBiEmbed: Report };
		},
	) => {
		if (event) setIframeError(false);
		event.target?.powerBiEmbed
			.setFilters(getLanguageFilters(language))
			.catch((err: Error) => {
				Sentry.captureException(err.message, { level: "info" });
			});
	};

	const handleEmbedReportEvents: Map<string, EventHandler> = new Map([
		["error", handleIframeError as EventHandler],
		["loaded", handleIframeLoaded as EventHandler],
	]);

	const handleGetEmbeddedComponent = (embedObject: Embed) => {
		setReport(embedObject as Report);
	};
	const handleFullScreenButtonClick = () => report?.fullscreen();

	return (
		<div
			data-testid="powerbi-report"
			className={cn(
				"flex flex-row flex-wrap justify-end",
				wrapperClassName,
			)}
		>
			{report && !iframeError && isFullViewButton && (
				<Flex className="w-full align-bottom justify-between px-6 pt-4 pb-0 border-b border-slate-200">
					{title && (
						<h2 className="text-lg font-bold mt-1">{title}</h2>
					)}
					<Button
						dataTestId="powerbi-fullscreen-button"
						className="mb-4"
						onClick={handleFullScreenButtonClick}
						type="button"
						size={"sm"}
						variant={"secondary"}
					>
						<ArrowsPointingOutIcon className="w-4 h-4 mr-2" />
						{t("benchmarking-section.fullScreen")}
					</Button>
				</Flex>
			)}
			<PowerBIEmbed
				key={`report-${dashboardInfo.report_id}-${language}`}
				embedConfig={getReportConfig(data, language)}
				eventHandlers={handleEmbedReportEvents}
				cssClassName={cn(
					"h-[calc(100vh_-_50px_-16px_-1px)] overflow-y-hidden w-full",
					className,
				)}
				getEmbeddedComponent={handleGetEmbeddedComponent}
			/>
		</div>
	);
}
