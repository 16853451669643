import {
	type LoaderFunctionArgs,
	type RouteObject,
	redirect,
} from "react-router";

export const getRedirectRoutes = (
	mappings: Record<string, string>,
): RouteObject[] => {
	return Object.entries(mappings).map(([oldPath, newPath]) => ({
		path: oldPath,
		loader: async ({ params }: LoaderFunctionArgs) => {
			const path = window.location.href;
			const pathNew = newPath.replace(":id", params.id || "");
			const pathOld = oldPath.replace(":id", params.id || "");
			return redirect(path.replace(pathOld, pathNew));
		},
	}));
};

export const generateRedirectsMapping = (
	paths: Record<string, string>,
): Record<string, string> => {
	return Object.keys(paths).reduce(
		(previous, key) => {
			previous[`${key}/:id`] = `${paths[key]}/:id`;
			previous[`${key}/new`] = `${paths[key]}/new`;
			return previous;
		},
		{ ...paths },
	);
};
