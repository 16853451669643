import type { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { cva } from "class-variance-authority";
import type { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { Flex } from "src/components/ui/Flex";
import { cn } from "src/utils/tailwind";

export type FeedbackProps = PropsWithChildren<{
	title?: ReactNode;
	subTitle?: ReactNode;
	description?: ReactNode;
	icon?: typeof ExclamationTriangleIcon;
	footer?: ReactNode;
	type?: "success" | "warning" | "danger";
	footerProps?: ComponentProps<typeof Flex>;
	padded?: boolean;
}>;

const iconWrapperVariants = cva("rounded-full h-10 w-10", {
	variants: {
		iconBgColor: {
			success: "bg-green-100",
			warning: "bg-orange-100",
			danger: "bg-red-100",
		},
	},
});

const iconVariants = cva("h-6 w-6", {
	variants: {
		iconColor: {
			success: "text-green-600",
			warning: "text-orange-400",
			danger: "text-red-400",
		},
	},
});

export function Feedback({
	title,
	subTitle,
	description,
	footer,
	icon: Icon,
	children,
	type = "success",
	padded = true,
	footerProps: { className: footerClassName, ...restFooterProps } = {},
}: FeedbackProps) {
	return (
		<Flex dir="col">
			<Flex align="start" className={cn({ "p-6": padded })} gap={3}>
				{Icon && (
					<Flex asChild centered noShrink>
						<div
							className={iconWrapperVariants({
								iconBgColor: type,
							})}
						>
							<Icon
								className={iconVariants({ iconColor: type })}
								aria-hidden="true"
							/>
						</div>
					</Flex>
				)}

				<Flex dir="col" gap={2}>
					<h2 className="text-lg font-medium text-gray-900 mt-1">
						{title}
					</h2>

					{subTitle && (
						<p className="text-card-foreground">{subTitle}</p>
					)}

					{description && (
						<div className="text-muted-foreground">
							{description}
						</div>
					)}

					{children}
				</Flex>
			</Flex>
			{footer && (
				<Flex
					justify="end"
					className={cn(
						"px-6 py-4 bg-slate-100 overflow-hidden",
						footerClassName,
					)}
					{...restFooterProps}
				>
					<Flex gap={2}>{footer}</Flex>
				</Flex>
			)}
		</Flex>
	);
}
