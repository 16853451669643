import type { LanguageDetectorModule } from "i18next";
import {
	type LanguageCode,
	defaultLanguage,
	languageMapping,
	supportedLanguages,
} from "src/locales/language-config";

interface CustomNavigator extends Navigator {
	userLanguage?: string;
}

const mapLanguageCode = (languageCode: string): string =>
	languageMapping[languageCode] || defaultLanguage;

export const customLanguageDetector: LanguageDetectorModule = {
	type: "languageDetector",

	init: (): void => {
		/* You can use services, detectorOptions, and i18nextOptions if needed */
	},

	detect: (): string | undefined => {
		const localStorageLanguage = localStorage.getItem(
			"i18nextLng",
		) as LanguageCode | null;

		if (
			localStorageLanguage &&
			supportedLanguages.has(localStorageLanguage)
		) {
			return localStorageLanguage;
		}

		if (typeof window !== "undefined") {
			const browserLanguage = ((window.navigator as CustomNavigator)
				.userLanguage || window.navigator.language) as LanguageCode;

			if (browserLanguage) {
				const languageCode = browserLanguage.split("-")[0];

				return supportedLanguages.has(browserLanguage)
					? browserLanguage
					: mapLanguageCode(languageCode);
			}
		}

		return undefined; // Return undefined if no language is detected, i18next will use the fallback language
	},

	cacheUserLanguage: (language: string): void => {
		localStorage.setItem("i18nextLng", language);
	},
};
