import { useTranslation } from "react-i18next";
import { type NoticeProps, components } from "react-select";
import type { SelectOption } from "src/types/ui.types";

export function SelectLoadingMessage(props: NoticeProps<SelectOption>) {
	const { t } = useTranslation();
	return (
		<components.LoadingMessage {...props}>
			<span className="p-2 text-sm">{t("loading")}</span>
		</components.LoadingMessage>
	);
}
