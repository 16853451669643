import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";

type AdminAuthorization = {
	settings: GenericAuthorizationMap<"notifications">;
};

export const settingsAuthorization: AdminAuthorization = {
	settings: {
		notifications: {
			can: {
				oneOf: [[Action.view, Model.NOTIFICATIONS_NOTIFICATION]],
			},
		},
	},
};
