import type { VariantProps } from "class-variance-authority";
import { useField } from "formik";
import { isNil } from "lodash";
import { type ReactNode, forwardRef, useContext, useEffect } from "react";
import type { MultiValue, SingleValue } from "react-select";
import { ErrorTextFormElement } from "src/components/form/ErrorText/ErrorTextFormElement";
import { FormElementDescription } from "src/components/form/FormElementDescription";
import { InputLabel, type labelVariants } from "src/components/form/InputLabel";
import { Select, type SelectProps } from "src/components/form/Select/Select";
import { baseFormElementWrapperVariants } from "src/components/form/form.styles";
import { FormContext } from "src/contexts/form-context";
import type { SelectOption } from "src/types/ui.types";
import { cn } from "src/utils/tailwind";

type SelectFormElementProps = {
	label?: string;
	description?: ReactNode;
	descriptionModalContent?: ReactNode;
	wrapperClassName?: string;
	dataTestId?: string;
	defaultValue?: SelectOption["value"] | SelectOption["value"][];
	name: string;
	children?: React.ReactNode;
	tooltip?: string;
	hideOptionalText?: boolean;
	labelStyle?: VariantProps<typeof labelVariants>["filedStyle"];
	onSelectChange?: (
		value: SelectOption["value"] | MultiValue<SelectOption["value"]>,
		values: SelectOption | MultiValue<SelectOption>,
	) => void;
} & Omit<SelectProps, "onSelectChange"> &
	VariantProps<typeof baseFormElementWrapperVariants>;

type SelectValue =
	| SingleValue<SelectOption>
	| MultiValue<SelectOption>
	| SelectOption["value"];

export const SelectFormElement = forwardRef<
	HTMLInputElement,
	SelectFormElementProps
>(
	(
		{
			label,
			description,
			descriptionModalContent,
			defaultValue,
			wrapperClassName,
			dataTestId,
			options,
			name,
			layout,
			onSelectChange,
			children,
			tooltip,
			hideOptionalText,
			labelStyle,
			...rest
		},
		ref,
	) => {
		const [field, meta, helpers] = useField<SelectOption["value"]>({
			name,
			type: "input",
		});

		const { layout: formLayout } = useContext(FormContext);

		const getValue = (value: SelectValue) => {
			if (Array.isArray(value ?? defaultValue)) {
				return ((value ?? defaultValue) as SelectOption[]).map(
					(opt: SelectOption) =>
						!isNil(opt.value) ? opt.value : opt,
				);
			}

			if (!isNil((value as SelectOption)?.value)) {
				return (value as SelectOption).value;
			}

			return value ?? defaultValue;
		};

		const handleSelectChange = async (item: SelectValue) => {
			const value = getValue(item) ?? null;

			field.onChange({ target: { value, name: field.name } });

			await helpers.setTouched(true);
			helpers.setError(undefined);
			onSelectChange?.(
				value as
					| SelectOption["value"]
					| MultiValue<SelectOption["value"]>,
				item as SelectOption | MultiValue<SelectOption>,
			);
		};

		useEffect(() => {
			if (meta.error && !meta.touched) {
				void helpers.setTouched(true);
			}
		}, [helpers, meta]);

		return (
			<div
				className={cn(
					baseFormElementWrapperVariants({
						layout: layout || formLayout,
					}),
					wrapperClassName,
				)}
				data-testid={dataTestId || field.name}
				ref={ref}
			>
				{!!label && (
					<InputLabel
						meta={meta}
						htmlFor={field.name}
						required={rest.required}
						hideOptionalText={hideOptionalText}
						filedStyle={labelStyle}
					>
						{label}
					</InputLabel>
				)}
				<div>
					<div
						className={cn("w-full", {
							"flex justify-between gap-2": !!children,
						})}
					>
						<Select
							{...rest}
							inputId={field.name}
							onSelectChange={handleSelectChange}
							options={options}
							defaultValue={defaultValue}
							value={field.value as string}
							className={cn({ "flex-grow": !!children })}
						/>
						{children}
					</div>
					{!!description && (
						<FormElementDescription
							descriptionModalContent={descriptionModalContent}
							tooltip={tooltip}
						>
							{description}
						</FormElementDescription>
					)}
					<ErrorTextFormElement name={name} />
				</div>
			</div>
		);
	},
);
