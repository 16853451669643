import { type OidcConfiguration, OidcProvider } from "@axa-fr/react-oidc";
import { captureException } from "@sentry/react";
import type { PropsWithChildren } from "react";
import {
	AuthenticationError,
	AuthenticationLoading,
	AuthenticationSuccess,
	SessionLost,
} from "src/components/OidcErrorScreen";
import { getSettings, isProd } from "src/settings";

const settings = getSettings();

export const configuration: OidcConfiguration = {
	client_id: settings.oidc.clientId,
	redirect_uri: `${settings.oidc.redirectUri}/#/authentication/callback/`,
	silent_redirect_uri: `${settings.oidc.redirectUri}/#/authentication/silent-callback/`,
	scope: "openid profile email roles",
	authority: settings.oidc.URL,
	// split the SW into a clean SW for prod & a dev for combining it with Mock service worker
	service_worker_relative_url: !isProd()
		? "/DevOidcServiceWorker.js"
		: "/OidcServiceWorker.js",
	service_worker_only: false,
	demonstrating_proof_of_possession: false,
	// TODO: check with team, what do you think of renewing the token on fetch??
	// token_renew_mode: TokenAutomaticRenewMode.AutomaticOnlyWhenFetchExecuted,
};

function sentryCaptureException(message: string, err: Error) {
	captureException(err, {
		contexts: {
			additionalData: { customMessage: message },
		},
	});
}

const onEvent = (
	configurationName: string,
	eventName: string,
	data: unknown,
): void => {
	if (eventName.endsWith("_error") && data instanceof Error) {
		sentryCaptureException(`oidc:${configurationName}:${eventName}`, data);
	}
};

export function AuthContainer({ children }: PropsWithChildren) {
	return (
		<OidcProvider
			configuration={configuration}
			loadingComponent={AuthenticationLoading}
			authenticatingComponent={AuthenticationLoading}
			authenticatingErrorComponent={AuthenticationError}
			sessionLostComponent={SessionLost}
			callbackSuccessComponent={AuthenticationSuccess}
			onEvent={onEvent}
		>
			{children}
		</OidcProvider>
	);
}
