import { TRACK_TYPES } from "src/matomo/constants";
import { isDev, isPreProd, isProd } from "src/settings";
import type {
	CustomDimension,
	TrackEventParams,
	TrackLinkParams,
	TrackPageViewParams,
	TrackParams,
	TrackSiteSearchParams,
	UserOptions,
} from "src/types/matomo.type";

export class MatomoTracker {
	mutationObserver?: MutationObserver;

	userId: string | null;

	constructor(userOptions: UserOptions) {
		if (!userOptions.urlBase) {
			throw new Error("Matomo urlBase is required.");
		}
		if (!userOptions.siteId) {
			throw new Error("Matomo siteId is required.");
		}

		this.initialize(userOptions);
		this.userId = null;
	}

	private initialize({
		urlBase,
		siteId,
		userId,
		trackerUrl,
		srcUrl,
		disabled,
		heartBeat,
		linkTracking = true,
		tagManagerUrl,
		tagManagerContainer,
		configurations = {},
	}: UserOptions) {
		const normalizedUrlBase =
			urlBase.at(-1) !== "/" ? `${urlBase}/` : urlBase;

		if (typeof window === "undefined") {
			return;
		}
		const stageIdKey =
			isProd() && !isPreProd() ? "prod" : isDev() ? "dev" : "staging";

		window._paq = window._paq || [];

		if (window._paq.length > 0) {
			return;
		}

		if (disabled) {
			return;
		}

		this.pushInstruction(
			"setTrackerUrl",
			trackerUrl ?? `${normalizedUrlBase}matomo.php`,
		);

		this.pushInstruction("setSiteId", siteId[stageIdKey]);

		if (userId) {
			this.pushInstruction("setUserId", userId);
		}

		Object.entries(configurations).forEach(([name, instructions]) => {
			if (Array.isArray(instructions)) {
				this.pushInstruction(name, ...instructions);
			} else {
				this.pushInstruction(name, instructions);
			}
		});

		if (!heartBeat || heartBeat?.active) {
			this.enableHeartBeatTimer(heartBeat?.seconds ?? 15);
		}

		this.enableLinkTracking(linkTracking);

		const doc = document;
		const scriptElement = doc.createElement("script");
		const scripts = doc.querySelectorAll("script")[0];

		scriptElement.type = "text/javascript";
		scriptElement.async = true;
		scriptElement.defer = true;
		scriptElement.src = srcUrl || `${normalizedUrlBase}matomo.js`;

		if (scripts?.parentNode) {
			scripts.parentNode.insertBefore(scriptElement, scripts);
		}

		if (tagManagerUrl && tagManagerContainer) {
			const _mtm = window._mtm || [];
			_mtm.push({
				"mtm.startTime": Date.now(),
				event: "mtm.Start",
			});
			const _mtmSrc = tagManagerContainer[stageIdKey];
			const d = document;
			const g = d.createElement("script");
			const s = d.querySelectorAll("script")[0];

			g.async = true;
			g.src = `${tagManagerUrl}${_mtmSrc}`;
			if (s?.parentNode) s.parentNode.insertBefore(g, s);
		}
	}

	setDocumentTitle(documentTitle = window.document.title) {
		this.pushInstruction("setDocumentTitle", documentTitle);
	}

	setUserId(userId: string) {
		if (!this.userId) {
			this.userId = userId;
		}
	}

	trackUserId(userId: string) {
		if (this.userId !== userId) {
			this.setUserId(userId);
			this.track({
				data: ["setUserId", userId],
			});
		}
	}

	enableHeartBeatTimer(seconds: number): void {
		this.pushInstruction("enableHeartBeatTimer", seconds);
	}

	enableLinkTracking(active: boolean): void {
		this.pushInstruction("enableLinkTracking", active);
	}

	private trackEventsForElements(elements: HTMLElement[]) {
		if (elements.length > 0) {
			elements.forEach((element) => {
				element.addEventListener("click", () => {
					const {
						matomoCategory,
						matomoAction,
						matomoName,
						matomoValue,
						matomoUrl,
					} = element.dataset;
					if (matomoCategory && matomoAction) {
						this.trackEvent({
							category: matomoCategory,
							action: matomoAction,
							name: matomoName,
							value: Number(matomoValue),
							event: "click",
							href: matomoUrl,
						});
					}
				});
			});
		}
	}

	trackEvents(): void {
		const matchString = '[data-matomo-event="click"]';
		let firstTime = false;
		if (!this.mutationObserver) {
			firstTime = true;
			this.mutationObserver = new MutationObserver((mutations) => {
				mutations.forEach((mutation) => {
					mutation.addedNodes.forEach((node) => {
						if (!(node instanceof HTMLElement)) return;

						if (node.matches(matchString)) {
							this.trackEventsForElements([node]);
						}

						const elements = Array.from(
							node.querySelectorAll<HTMLElement>(matchString),
						);
						this.trackEventsForElements(elements);
					});
				});
			});
		}
		this.mutationObserver.observe(document, {
			childList: true,
			subtree: true,
		});

		if (firstTime) {
			const elements = Array.from(
				document.querySelectorAll<HTMLElement>(matchString),
			);
			this.trackEventsForElements(elements);
		}
	}

	stopObserving(): void {
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
		}
	}

	trackEvent({
		category,
		action,
		name,
		value,
		event,
		...otherParams
	}: TrackEventParams): void {
		if (category && action) {
			this.track({
				data: [
					TRACK_TYPES.TRACK_EVENT,
					category,
					action,
					name || "",
					value || "",
					event || "",
				],
				...otherParams,
			});
		} else {
			throw new Error("Error: category and action are required.");
		}
	}

	trackSiteSearch({
		keyword,
		category,
		count,
		...otherParams
	}: TrackSiteSearchParams): void {
		if (keyword) {
			this.track({
				data: [
					TRACK_TYPES.TRACK_SEARCH,
					keyword,
					category || "",
					count || "",
				],
				...otherParams,
			});
		} else {
			throw new Error("Error: keyword is required.");
		}
	}

	trackLink({ href, linkType = "link" }: TrackLinkParams): void {
		this.pushInstruction(TRACK_TYPES.TRACK_LINK, href, linkType);
	}

	trackPageView(params?: TrackPageViewParams): void {
		this.track({ data: [TRACK_TYPES.TRACK_VIEW], ...params });
	}

	track({
		data = [],
		documentTitle = window.document.title,
		href,
		customDimensions = false,
	}: TrackParams): void {
		if (data.length > 0) {
			if (
				customDimensions &&
				Array.isArray(customDimensions) &&
				customDimensions.length > 0
			) {
				customDimensions.map((customDimension: CustomDimension) =>
					this.pushInstruction(
						"setCustomDimension",
						customDimension.id.toString(),
						customDimension.value,
					),
				);
			}

			this.pushInstruction(
				"setCustomUrl",
				href?.toString() ?? window?.location?.href,
			);
			this.setDocumentTitle(documentTitle);
			this.pushInstruction(...(data as [string, ...string[]]));
		}
	}

	pushInstruction(
		name: string,
		...args: (string | number | boolean)[]
	): MatomoTracker {
		if (typeof window !== "undefined") {
			window._paq.push([name, ...args]);
		}

		return this;
	}
}
