import { type PropsWithChildren, useEffect } from "react";

import { AppConfig } from "src/configs/app-config";
import { useLocalStorageAccountId } from "src/hooks/use-local-storage-account-id";
import { client } from "src/types/_generated/client.gen";

export function AppInitiator({
	children,
	accountId,
}: PropsWithChildren<{ accountId: number }>) {
	const [_, setLocalStorageAccountId] = useLocalStorageAccountId();
	useEffect(() => {
		// Set that to localstorage so that we can redirect urls that are not prefixed with account id
		setLocalStorageAccountId(accountId);
		AppConfig.setPageUrlPrefix(accountId);
		client.setConfig({
			headers: {
				"pl-account-id": accountId,
				user: accountId,
			},
		});
	}, [accountId, setLocalStorageAccountId]);

	return children;
}
