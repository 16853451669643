import { CheckIcon } from "@heroicons/react/20/solid";
import { cva } from "class-variance-authority";
import { isString } from "lodash";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { type OptionProps, components } from "react-select";
import type { SelectOption } from "src/types/ui.types";

export const selectOptionTextVariants = cva([], {
	variants: {
		isSelected: {
			false: "pl-6",
		},
		hasLabelDetails: {
			true: "!pl-0 flex justify-between items-center w-full",
		},
	},
});

function DisplayLabel({ label, labelDetails }: SelectOption) {
	const { t } = useTranslation();

	const translateIfString = (l: SelectOption["label"]) =>
		isString(l) ? t(l) : (l as ReactNode);

	if (labelDetails) {
		return (
			<>
				<span className="truncate">{translateIfString(label)}</span>
				<span className="text-xs font-medium text-slate-400">
					{translateIfString(labelDetails)}
				</span>
			</>
		);
	}

	return <span>{translateIfString(label)}</span>;
}

export function SelectOptionElement(
	props: OptionProps<SelectOption> & {
		hasLabelDetails?: boolean;
	},
) {
	const { isSelected, hasLabelDetails, data } = props;
	const { t } = useTranslation();
	return (
		<components.Option {...props} className="p-3">
			<span
				className="flex break-all"
				data-testid={`select-option${isSelected ? "-selected" : ""}`}
				aria-selected={isSelected}
			>
				{isSelected && !hasLabelDetails && (
					<CheckIcon
						className="max-w-[24px] pr-2 text-slate-700"
						data-testid="check-image"
					/>
				)}
				<span
					className={selectOptionTextVariants({
						isSelected,
						hasLabelDetails,
					})}
					data-testid="select-option-text"
				>
					<DisplayLabel {...data} />
					{data?.labelDescription?.map((description) => (
						<span
							className="mt-2 block text-xs text-slate-600"
							key={`${String(data.value)}-${description
								?.split(" ")
								.join("-")}`}
						>
							{t(description)}
						</span>
					))}
				</span>
			</span>
		</components.Option>
	);
}
