import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React, { type ComponentProps, type ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FormFeedback } from "src/components/Feedback/FormFeedback";
import { Modal } from "src/components/Modal";
import { Button, type ButtonProps } from "src/components/ui/Button";
import { useDisclose } from "src/hooks/use-disclose";
import { cn } from "src/utils/tailwind";

export type FormElementDescriptionProps = ComponentProps<"p" | "div"> & {
	descriptionModalContent?: ReactNode;
	tooltip?: string;
};

export function FormElementDescription({
	className,
	descriptionModalContent,
	tooltip,
	children,
	...rest
}: FormElementDescriptionProps) {
	const { t } = useTranslation();
	const modalControl = useDisclose();
	const TooltipWrapperElement = descriptionModalContent
		? Button
		: React.Fragment;
	const tooltipWrapperProps = descriptionModalContent
		? ({
				variant: "iconButton",
				onClick: modalControl.open,
			} as ButtonProps)
		: {};

	return !descriptionModalContent && !tooltip ? (
		<p
			className={cn(
				"ml-1 mt-2 text-xs text-slate-500 md:col-start-2",
				className,
			)}
			{...rest}
		>
			{children}
		</p>
	) : (
		<>
			<div className="ml-1 mt-2 text-xs text-slate-500 md:col-start-2">
				<div className="flex flex-row items-center">
					<div className={cn(className)} {...rest}>
						{children}
					</div>
					<TooltipWrapperElement {...tooltipWrapperProps}>
						<QuestionMarkCircleIcon
							className="inline-block h-5 w-5 mx-1"
							data-tooltip-id="description-modal-tooltip"
							data-tooltip-html={`<span class="text-xs">${t(
								tooltip ?? "moreInformation",
							)}</span>`}
							data-tooltip-place="bottom"
						/>
					</TooltipWrapperElement>
				</div>
			</div>
			<Tooltip
				id="description-modal-tooltip"
				style={{ zIndex: "10", maxWidth: 280 }}
			/>
			<Modal {...modalControl} size="lg">
				<FormFeedback
					description={descriptionModalContent}
					onCancel={modalControl.close}
					submitHidden
					showCloseNotCancel
				/>
			</Modal>
		</>
	);
}
