import { type VariantProps, cva } from "class-variance-authority";
import type React from "react";
import { forwardRef } from "react";
import { Spinner } from "src/components/ui/Spinner";
import { cn } from "src/utils/tailwind";
export const buttonVariants = cva(
	["inline-flex items-center justify-center border"],
	{
		variants: {
			variant: {
				primary:
					"border-pl-blue bg-pl-blue text-white hover:bg-blue-900",
				outline:
					"border-pl-blue bg-transparent text-pl-blue hover:border-blue-900 hover:bg-blue-50 hover:text-blue-900",
				outlineDashed:
					"border-dashed border-borderColor bg-transparent text-slate-600 hover:border-slate-400 hover:text-slate-900",
				secondary:
					"border-blue-50 bg-blue-50 text-pl-blue hover:bg-blue-100",
				default:
					"border-gray-300 bg-white text-gray-600 hover:text-gray-900 hover:bg-slate-200 hover:border-gray-400",
				danger: "border-error bg-red-500 text-white hover:bg-red-700 focus:ring-red-700",
				dangerDark:
					"border-red-700 bg-red-700 text-white hover:bg-red-800 focus:ring-red-800",
				dangerDarkOutline:
					"border-red-700 text-red-700 hover:bg-red-800 hover:text-white focus:ring-red-800",
				warning:
					"border-warning bg-orange-600 text-white hover:bg-orange-700 focus:ring-orange-700",
				attention: "border-error text-error hover:opacity-75",
				ghost: "bg-transparent text-gray-600 hover:text-gray-900 hover:bg-slate-200 border-transparent group-data-[state=open]/dropdown:bg-slate-200",
				iconButton:
					"border-none !p-1 text-slate-400 hover:text-slate-600",
				noStyle: "border-none !p-0",
				card: "hover:text-slate-900 hover:bg-slate-50 w-full border-borderColor bg-white text-slate-800 transition-all ease-in-out justify-start",
				aiButton:
					"text-indigo-100 bg-gradient-to-r from-indigo-400 to-purple-400 border-purple-400 hover:opacity-70",
			},
			focus: {
				true: "focus:outline-none focus:ring-2 focus:ring-pl-blue focus:ring-offset-2",
				false: "focus:outline-none",
			},
			size: {
				xs: "text-xs px-1 py-1",
				sm: "text-xs px-3 py-2",
				normal: "text-sm px-4 py-2",
				lg: "text-base px-9 py-4",
				xl: "text-lg px-12 py-6",
			},
			disabled: {
				true: "pointer-events-none opacity-60",
			},
			rounded: {
				xl: "rounded-xl",
				md: "rounded-md",
				full: "rounded-full",
				none: "rounded-none",
			},
			dashed: {
				true: "border-dashed",
			},
			bg: {
				transparent: "bg-transparent",
			},
			fontWeight: {
				bold: "font-bold",
				medium: "font-medium",
				semibold: "font-semibold",
				normal: "font-regular",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "normal",
			rounded: "md",
			fontWeight: "medium",
		},
	},
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		Omit<VariantProps<typeof buttonVariants>, "disabled"> {
	type?: "submit" | "reset" | "button";
	dataTestId?: string;
	wrapperClassName?: string;
	isLoading?: boolean;
}

export const Button = forwardRef<React.ElementRef<"button">, ButtonProps>(
	(
		{
			children,
			className,
			dataTestId,
			variant = "primary",
			size = "normal",
			wrapperClassName = "",
			disabled,
			type = "button",
			focus = true,
			rounded = "md",
			isLoading,
			bg,
			dashed,
			fontWeight,
			...rest
		},
		ref,
	) => (
		<div
			className={cn(
				{
					"cursor-not-allowed": disabled,
				},
				wrapperClassName,
			)}
		>
			<button
				data-testid={dataTestId}
				className={cn(
					buttonVariants({
						variant,
						size,
						focus,
						disabled,
						rounded,
						bg,
						dashed,
						fontWeight,
					}),
					className,
					{ "cursor-wait opacity-60": isLoading },
				)}
				disabled={isLoading || disabled}
				type={type}
				ref={ref}
				{...rest}
			>
				{children}
				{isLoading && <Spinner className="pl-2" />}
			</button>
		</div>
	),
);
