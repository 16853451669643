import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";

type AdminAuthorization = {
	admin: GenericAuthorizationMap<
		| "index"
		| "accounts"
		| "users"
		| "voucherCodesList"
		| "voucherCodesUpload"
		| "authorizations"
		| "whitelabelDomains"
		| "gdpr"
		| "apitokens"
	>;
};

export const adminAuthorization: AdminAuthorization = {
	admin: {
		index: {
			can: {
				oneOf: [
					[Action.view, Model.ACCOUNT_ACCOUNT],
					[Action.view, Model.ACCOUNT_USER],
					[Action.view, Model.SERVICE_AUTHORIZABLESERVICEINSTANCE],
					[Action.view, Model.CONFIG_WHITELABELDOMAINCONFIG],
					[Action.view, Model.ACCOUNT_APITOKEN],
					[Action.view, Model.MISC_IMPORT],
					[Action.view, Model.MISC_VOUCHER],
				],
			},
		},
		accounts: {
			can: { oneOf: [[Action.view, Model.ACCOUNT_ACCOUNT]] },
		},
		users: {
			can: { oneOf: [[Action.view, Model.ACCOUNT_USER]] },
		},
		voucherCodesList: {
			can: {
				oneOf: [[Action.view, Model.MISC_VOUCHER]],
			},
		},
		voucherCodesUpload: {
			can: {
				oneOf: [[Action.view, Model.MISC_VOUCHER]],
			},
		},
		authorizations: {
			can: {
				oneOf: [
					[Action.view, Model.SERVICE_AUTHORIZABLESERVICEINSTANCE],
				],
			},
		},
		whitelabelDomains: {
			can: {
				oneOf: [[Action.view, Model.CONFIG_WHITELABELDOMAINCONFIG]],
			},
		},
		gdpr: {
			can: {
				oneOf: [[Action.view, Model.PORTAL_DATAPROTECTIONREQUEST]],
			},
		},
		apitokens: {
			can: {
				oneOf: [[Action.view, Model.ACCOUNT_APITOKEN]],
			},
		},
	},
};
