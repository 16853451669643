import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import type { DynamicFiltersViewEnum } from "src/components/DynamicFilters/Views/DynamicFilters.View.type";
import type { PortalSettingsType } from "src/module--trackings/types/tracking.type";
import {
	accountUsersMePartialUpdateMutation,
	accountUsersMeRetrieveOptions,
} from "src/types/_generated/@tanstack/react-query.gen";

export type UserSettingsOptions = {
	enabled?: boolean;
};

export const useUserSettingsMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		...accountUsersMePartialUpdateMutation(),
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: accountUsersMeRetrieveOptions().queryKey,
			});
		},
	});
};

export const usePortalSettings = () => {
	const { data: userSettings } = useQuery({
		...accountUsersMeRetrieveOptions(),
		refetchOnWindowFocus: false,
	});

	return userSettings?.portalSettings as PortalSettingsType;
};

export const useExistingViews = (view: DynamicFiltersViewEnum) => {
	const portalSettings = usePortalSettings();

	return portalSettings?.[view] ?? {};
};

export const usePortalSettingsMutation = () => {
	const portalSettings = usePortalSettings();
	const { mutate, ...rest } = useUserSettingsMutation();

	const mutateFn: typeof mutate = (payload, ...rest) => {
		mutate(
			{
				body: {
					...payload.body,
					portalSettings: {
						...portalSettings,
						...(payload.body?.portalSettings ?? {}),
					},
				},
			},
			...rest,
		);
	};

	return {
		mutate: mutateFn,
		...rest,
	};
};

export function useUserSettings(opts?: UserSettingsOptions) {
	const { i18n } = useTranslation();
	const {
		data: userDetails,
		isLoading,
		isError,
		error,
	} = useQuery({
		...accountUsersMeRetrieveOptions(),
		...opts,
		refetchOnWindowFocus: false,
	});

	const userLocale: string = userDetails?.userLocale
		? userDetails.userLocale.replaceAll(/_/gi, "-")
		: i18n.language;

	return { userLocale, userDetails, i18n, isLoading, isError, error };
}
