import { FolderPlusIcon } from "@heroicons/react/24/outline";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
/* eslint-disable react/no-unstable-nested-components */
import { type PropsWithChildren, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorState } from "src/components/ErrorState";
import { LoadingScreen } from "src/components/LoadingScreen";
import { EmptyState } from "src/components/ui/EmptyState";
import type { FeedbackVariantsProps } from "src/components/utils/feedback.variants";
import type { ErrorStateType } from "src/types/error";
import { hasError } from "src/utils/error";

export type DataStateBoundaryProps = PropsWithChildren<{
	isLoading?: boolean;
	error?: ErrorStateType;
	errorTitle?: string;
	errorDescription?: string;
	isEmptyState?: boolean;
	className?: string;
	emptyTitle?: string;
	emptyDescription?: string;
	emptyIcon?: typeof FolderPlusIcon;
	emptyTo?: string;
	variant?: FeedbackVariantsProps["variant"];
}>;

export function DataStateBoundary({
	isLoading = false,
	error: errorProp,
	errorTitle,
	errorDescription,
	isEmptyState = false,
	children,
	emptyTitle,
	emptyDescription,
	emptyIcon = FolderPlusIcon,
	emptyTo,
	variant,
	className,
}: DataStateBoundaryProps) {
	if (isLoading) {
		return <LoadingScreen className={className} variant={variant} />;
	}

	if (hasError(errorProp)) {
		return (
			<ErrorState
				error={errorProp}
				title={errorTitle}
				description={errorDescription}
				variant={variant}
			/>
		);
	}

	if (isEmptyState) {
		return (
			<EmptyState
				title={emptyTitle}
				description={emptyDescription}
				variant={variant}
				Icon={emptyIcon}
				className={className}
				to={emptyTo}
			/>
		);
	}

	return (
		<QueryErrorResetBoundary>
			{({ reset }) => (
				<ErrorBoundary
					fallbackRender={({ error, resetErrorBoundary }) => (
						<ErrorState
							error={error as Error}
							resetErrorBoundary={resetErrorBoundary}
							variant={variant}
						/>
					)}
					onReset={reset}
				>
					<Suspense fallback={<LoadingScreen />}>{children}</Suspense>
				</ErrorBoundary>
			)}
		</QueryErrorResetBoundary>
	);
}
