import { CheckIcon, Cross2Icon, MinusIcon } from "@radix-ui/react-icons";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cva } from "class-variance-authority";
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
} from "react";
import type { FormikFieldProps } from "src/components/form/form.types";
import { cn } from "src/utils/tailwind";

export const switchIndicatorStates = cva(
	[
		"pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform flex text-center items-center",
		"read-only:pointer-events-none",
	],
	{
		variants: {
			checked: {
				true: "translate-x-5",
				false: "translate-x-0",
				indeterminate: "translate-x-2.5",
			},
		},
	},
);

export const switchStates = cva(
	[
		"focus-visible:ring-ring peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
	],
	{
		variants: {
			checked: {
				true: "bg-primary",
				false: "bg-input",
				indeterminate: "bg-input",
			},
		},
	},
);

const SwitchStateIconsMapping = {
	indeterminate: MinusIcon,
	checked: CheckIcon,
	unchecked: Cross2Icon,
};

export type SwitchProps = Omit<
	ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
	"form"
> &
	FormikFieldProps<boolean>;
const Switch = forwardRef<
	ElementRef<typeof SwitchPrimitives.Root>,
	SwitchProps
>(
	(
		{ className, meta: __, field, defaultValue, onCheckedChange, ...props },
		ref,
	) => {
		const switchValue = field.value ?? defaultValue ?? props.defaultChecked;
		const isIndeterminate =
			switchValue === undefined || switchValue === null;
		const switchState = isIndeterminate ? "indeterminate" : switchValue;
		const StateIcon =
			SwitchStateIconsMapping[
				switchState === true
					? "checked"
					: switchState === false
						? "unchecked"
						: "indeterminate"
			];

		return (
			<SwitchPrimitives.Root
				className={cn(
					switchStates({
						checked: switchState,
					}),
					className,
				)}
				id={field.name}
				{...props}
				onCheckedChange={(value) => {
					field.onChange({ target: { value, name: field.name } });
					onCheckedChange?.(value);
				}}
				checked={switchValue}
				ref={ref}
			>
				<SwitchPrimitives.Thumb
					className={switchIndicatorStates({
						checked: switchState,
					})}
				>
					<StateIcon className="ml-[4px] size-3 text-gray-500" />
				</SwitchPrimitives.Thumb>
			</SwitchPrimitives.Root>
		);
	},
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
