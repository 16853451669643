import { type VariantProps, cva } from "class-variance-authority";

export const feedbackVariants = cva(
	[
		"flex w-full flex-col items-center justify-center px-6 py-10 relative rounded-lg border-2 border-dashed border-slate-300",
	],
	{
		variants: {
			variant: {
				fullScreen:
					"m-auto px-4 py-6 sm:px-6 max-w-[90vw] lg:max-w-[70vw] h-[calc(100vh_-_48px)] mt-[24px]",
				fit: "py-6 sm:px-6 h-full w-full",
				noStyle: "",
			},
		},
		defaultVariants: {
			variant: "fit",
		},
	},
);

export const feedbackImageVariants = cva(["object-contain"], {
	variants: {
		variant: {
			fullScreen: "h-28 w-28",
			fit: "max-h-[40%] h-28 w-28",
			default: "h-28 w-28",
			noStyle: "",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

export type FeedbackVariantsProps = VariantProps<typeof feedbackVariants>;
export type FeedbackImageVariantsProps = VariantProps<
	typeof feedbackImageVariants
>;
