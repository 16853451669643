import { getSettings } from "src/settings";

export const cspConfig = {
	"default-src": [
		"'self'",
		"https://static.zdassets.com",
		"https://ekr.zdassets.com",
		"https://ekr.zendesk.com",
		"https://*.zendesk.com",
		"https://api.smooch.io",
		"https://media.smooch.io",
		"https://zendesk-eu.my.sentry.io",
		"https://*.twilio.com",
		"wss://*.zendesk.com",
		"wss://api.smooch.io",
		"wss://voice-js.roaming.twilio.com",
	],
	"script-src": [
		"'self'",
		"'unsafe-eval'",
		"https://static.zdassets.com",
		"https://parcellab.matomo.cloud",
		"https://cdn.matomo.cloud",
		"https://js.hsadspixel.net",
		"https://js.hs-banner.com",
		"https://js.hs-analytics.net",
		"https://js.hscollectedforms.net",
		"https://cdn.tiny.cloud",
		"https://cdn.jsdelivr.net",
		"*.hotjar.com 'nonce-parcelLabhotjar'",
		"https://*.parcellab.com",
		"https://cdn.crowdin.com",
		"https://*.userpilot.io",
	],
	"worker-src": ["'self'", "blob:"],
	"style-src": ["'unsafe-inline'", "*"],
	"img-src": [
		"*",
		"blob:",
		"data:",
		"https://*.userpilot.io",
		"https://static.zdassets.com",
		"https://accounts.zendesk.com",
		"https://*.zendesk.com",
		"https://media.smooch.io",
	],
	"font-src": ["*"],
	"frame-src": [
		getSettings().productApi.CUSTOMER_SERVICE_URL,
		"https://app.powerbi.com",
		"https://metabase.parcellab.dev",
		"*.hotjar.com",
		"https://crowdin.com",
		"https://*.parcellab.com",
	],
	"connect-src": [
		"'self'",
		getSettings().productApi.URL,
		getSettings().backendApi.URL,
		getSettings().copilotApi.URL,
		"https://returns-api.parcellab.com",
		"https://oauth.staging.parcellab.dev",
		"https://auth.parcellab.com",
		"https://auth.staging.parcellab.dev",
		"https://parcellab.matomo.cloud",
		"https://parcellabhelp.zendesk.com",
		"https://forms.hscollectedforms.net",
		"https://api.hubapi.com",
		"https://js.hs-banner.com",
		"https://cdn.tiny.cloud",
		"https://cdn.jsdelivr.net",
		"https://sentry.parcellab.com",
		"https://*.hotjar.com",
		"https://*.hotjar.io",
		"wss://*.hotjar.com",
		"https://cdn.crowdin.com",
		"https://*.parcellab.com",
		"https://*.userpilot.io",
		"wss://*.userpilot.io",
	],
	"object-src": ["'none'"],
	"media-src": ["'self'", "https://static.zdassets.com"],
};
