import type { UserSettings } from "src/types/_generated";
import type { UserInfo } from "src/types/oidc-user-info";
import type { Roles } from "src/types/roles.type";

export type UserFeatureFlags = Omit<
	UserSettings,
	"username" | "firstName" | "lastName" | "accounts" | "permissions" | "email"
>;

export const getUserAccountIdList = (oidcUser?: UserInfo) =>
	Array.isArray(oidcUser?.accounts) ? oidcUser.accounts : [];

export const getDefaultUserAccountId = (oidcUser?: UserInfo) =>
	getUserAccountIdList(oidcUser)[0] || undefined;

const userProfileHasRole = (profile: UserInfo, role: string) => {
	try {
		return (profile.parcellab as { roles: unknown[] }).roles.includes(role);
	} catch {
		console.error(
			"Failed to verify user roles! User's permissions could not be validated!",
		);
		return false;
	}
};

export const isUserAuthorised = (
	profile: UserInfo,
	authorisedRoles: string[],
) =>
	authorisedRoles.length > 0
		? authorisedRoles.some((role) => userProfileHasRole(profile, role))
		: true;

export function isRoleAuthorised(
	profile: UserInfo | undefined,
	authorisedRoles: Roles[],
): boolean {
	if (!profile) return false;

	return isUserAuthorised(profile, authorisedRoles);
}

export const isPlAdmin = (oidcUser: UserInfo): boolean => {
	const accountIdList = getUserAccountIdList(oidcUser);

	return accountIdList.includes(1);
};

export const canAccessToAccount = (
	oidcUser: UserInfo,
	accountId: number,
): boolean => {
	const accountIdList = getUserAccountIdList(oidcUser);

	return isPlAdmin(oidcUser) || accountIdList.includes(accountId);
};
