import { cva } from "class-variance-authority";

export const baseFormElementWrapperVariants = cva(
	["grid sm:gap-x-4 max-w-form"],
	{
		variants: {
			layout: {
				default: "items-baseline md:grid-cols-form",
				horizontal: "items-baseline md:grid-cols-form",
				horizontalWide:
					"items-baseline md:grid-cols-[auto_min-content]",
				materialDesign:
					"relative [&>label]:absolute [&>label]:-top-2 [&>label]:left-2 [&>label]:z-50 [&>label]:inline-block [&>label]:bg-white [&>label]:px-1 [&>label]:text-xs [&>label]:font-medium [&>label]:text-slate-700",
				vertical: "grid-cols-1",
			},
			border: {
				true: "border-t border-slate-200",
			},
		},
		defaultVariants: {
			layout: "default",
		},
	},
);

export const baseFormWidgetWrapperVariants = cva(
	["md:grid sm:gap-x-4 max-w-form"],
	{
		variants: {
			layout: {
				default: "items-baseline grid-cols-widget",
				horizontal: "items-baseline grid-cols-widget",
				horizontalWide: "items-baseline grid-cols-widget",
				vertical: "grid-cols-1",
			},
		},
		defaultVariants: {
			layout: "default",
		},
	},
);

export const baseFormVariants = cva([""], {
	variants: {
		formLayout: {
			default: "flex flex-col md:grid gap-4 mx-auto w-full md:max-w-form",
			noStyle: "",
			horizontal: "flex flex-row gap-4",
		},
		columns: {
			single: "grid-cols-1",
			two: "grid-cols-2",
		},
	},
	defaultVariants: {
		formLayout: "default",
		columns: "single",
	},
});

export const baseFormFooterVariants = cva(["d-only-last-child mx-auto"], {
	variants: {
		layout: {
			default: "max-w-form",
			highlighted: "max-w-form bg-slate-100",
		},
	},
	defaultVariants: {
		layout: "default",
	},
});
