import { lazy } from "react";
import { type LoaderFunctionArgs, Outlet } from "react-router";
import { returnsAuthorization } from "src/configs/authorization/returns";
import { PAGES } from "src/configs/pages-urls";
import { returnsCourierApisLoader } from "src/module--returns/pages/ReturnsCourierApis.loaders";
import { authorizationLoader } from "src/router/common-loaders";
import { lazyLoad } from "src/router/lazyload";
import type { RouterConfig } from "src/router/types";

function returnReasonsV2Routes(config: RouterConfig) {
	return [
		{
			index: true,
			lazy: async () => {
				const { ReturnsReasons } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsReasons };
			},
			loader: async ({
				params: { accountId: accountIdParam },
				request,
			}: LoaderFunctionArgs) => {
				const { returnReasonsLoader } = await import(
					"src/module--returns/returns.lazy"
				);
				return returnReasonsLoader(
					{ ...config, request },
					accountIdParam,
				);
			},
		},
		{
			path: `${PAGES.edit}/:returnReasonsId`,
			lazy: async () => {
				const { ReturnsReasonsEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsReasonsEditPage };
			},
			loader: async ({
				params: { returnReasonsId },
				request,
			}: LoaderFunctionArgs) => {
				const { returnReasonsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);
				return returnReasonsDetailLoader(
					{ ...config, request },
					returnReasonsId,
				);
			},
		},
		{
			path: PAGES.new,
			lazy: async () => {
				const { ReturnsReasonsNewPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsReasonsNewPage };
			},
			loader: async ({ request }: LoaderFunctionArgs) => {
				const params = new URL(request.url).searchParams;
				const groupId = params.get("groupId");

				if (!groupId) return null;

				const { returnReasonsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnReasonsDetailLoader(
					{ ...config, request },
					groupId,
				);
			},
		},
	];
}

const ReturnsDashboardPage = lazy(() =>
	import("src/module--returns/returns.lazy").then(
		(mod): { default: () => JSX.Element } => ({
			default: mod.ReturnsDashboardPage,
		}),
	),
);

export function returnsRoutes(config: RouterConfig) {
	return [
		{
			path: "",
			element: lazyLoad(<ReturnsDashboardPage />),
		},
		{
			path: PAGES.returns.sub.approvals,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnApprovalLoader } = await import(
					"src/module--returns/returns.lazy"
				);
				return returnApprovalLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsApprovals } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsApprovals };
			},
		},
		{
			path: PAGES.returns.sub.publish,
			lazy: async () => {
				const { ReturnsPublishPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsPublishPage };
			},
		},
		{
			path: PAGES.returns.sub.portals,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnPortalsLoader } = await import(
					"src/module--returns/returns.lazy"
				);
				return returnPortalsLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsPortalsPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsPortalsPage };
			},
		},
		{
			path: `${PAGES.returns.sub.portals}/${PAGES.edit}/:portalId`,
			loader: async ({
				params: { accountId: accountIdParam, portalId },
			}: LoaderFunctionArgs) => {
				const { returnPortalsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);
				return returnPortalsDetailLoader(
					config,
					portalId,
					accountIdParam,
				);
			},
			lazy: async () => {
				const { ReturnsPortalEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsPortalEditPage };
			},
		},
		{
			path: `${PAGES.returns.sub.portals}/${PAGES.new}`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnPortalsNewLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnPortalsNewLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsPortalNewPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsPortalNewPage };
			},
		},
		{
			path: PAGES.returns.sub.translations,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnTranslationsLoader } = await import(
					"src/module--returns/returns.lazy"
				);
				return returnTranslationsLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsTranslationsPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsTranslationsPage };
			},
		},
		{
			path: `${PAGES.returns.sub.translations}/${PAGES.edit}/:translationId`,
			loader: async ({
				params: { translationId },
			}: LoaderFunctionArgs) => {
				const { returnTranslationsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnTranslationsDetailLoader(config, translationId);
			},
			lazy: async () => {
				const { ReturnsTranslationEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsTranslationEditPage };
			},
		},
		{
			path: PAGES.returns.sub.orderApis,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnOrderApisLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnOrderApisLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsOrderApisPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsOrderApisPage };
			},
		},
		{
			path: `${PAGES.returns.sub.orderApis}/${PAGES.edit}/:orderApiId`,
			loader: async ({
				params: { accountId: accountIdParam, orderApiId },
			}: LoaderFunctionArgs) => {
				const { returnOrderApisDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnOrderApisDetailLoader(
					config,
					orderApiId,
					accountIdParam,
				);
			},
			lazy: async () => {
				const { ReturnsOrderApiEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsOrderApiEditPage };
			},
		},
		{
			path: `${PAGES.returns.sub.orderApis}/${PAGES.new}`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnOrderApisNewLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnOrderApisNewLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsOrderApiNewPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsOrderApiNewPage };
			},
		},
		{
			path: PAGES.returns.sub.courierApis,
			lazy: async () => {
				const { ReturnsCourierApisPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return {
					Component: ReturnsCourierApisPage,
					loader: async ({
						params: { accountId },
						request,
					}: LoaderFunctionArgs) =>
						returnsCourierApisLoader(
							{ ...config, request },
							accountId as string,
						),
				};
			},
		},
		{
			path: `${PAGES.returns.sub.courierApis}/${PAGES.edit}/:courierApiId`,
			loader: async ({
				params: { accountId: accountIdParam, courierApiId },
			}: LoaderFunctionArgs) => {
				const { returnCourierApisDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnCourierApisDetailLoader(
					config,
					courierApiId,
					accountIdParam,
				);
			},
			lazy: async () => {
				const { ReturnsCourierApiEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsCourierApiEditPage };
			},
		},
		{
			path: `${PAGES.returns.sub.courierApis}/${PAGES.new}`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnCourierApisNewLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnCourierApisNewLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsCourierApiNewPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsCourierApiNewPage };
			},
		},
		{
			path: PAGES.returns.sub.returnReasons,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnRulesApisLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnRulesApisLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsRulesPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsRulesPage };
			},
		},
		{
			path: PAGES.returns.sub.returnReasonsV2,
			element: <Outlet />,
			children: returnReasonsV2Routes(config),
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					returnsAuthorization.returns.returnReasonsV2,
				),
		},
		{
			path: `${PAGES.returns.sub.returnReasons}/${PAGES.edit}/:ruleId`,
			loader: async ({
				params: { accountId: accountIdParam, ruleId },
			}: LoaderFunctionArgs) => {
				const { returnRulesDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnRulesDetailLoader(config, ruleId, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsRuleEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsRuleEditPage };
			},
		},
		{
			path: `${PAGES.returns.sub.returnReasons}/${PAGES.new}`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) => {
				const { returnRulesNewLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnRulesNewLoader(config, accountIdParam);
			},
			lazy: async () => {
				const { ReturnsRulesNewPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsRulesNewPage };
			},
		},
		{
			path: PAGES.returns.sub.returnsCompensationMethods,
			async lazy() {
				const { ReturnsCompensationMethodsPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsCompensationMethodsPage };
			},
			loader: ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					returnsAuthorization.returns.returnsCompensationMethods,
				),
		},
		{
			path: `${PAGES.returns.sub.returnsCompensationMethods}/edit/:portalId`,
			loader: async ({
				params: { accountId: accountIdParam, portalId },
			}: LoaderFunctionArgs) => {
				const { returnPortalsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return (
					(await returnPortalsDetailLoader(
						config,
						portalId,
						accountIdParam,
					)) &&
					authorizationLoader(
						{ ...config, accountIdParam },
						returnsAuthorization.returns.returnsCompensationMethods,
					)
				);
			},
			async lazy() {
				const { ReturnsCompensationMethodEdit } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsCompensationMethodEdit };
			},
		},
		{
			path: PAGES.returns.sub.returnEligibility,
			async lazy() {
				const { ReturnsReturnEligibilitiesPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsReturnEligibilitiesPage };
			},
		},
		{
			path: `${PAGES.returns.sub.returnEligibility}/${PAGES.edit}/:portalId`,
			loader: async ({
				params: { accountId: accountIdParam, portalId },
			}: LoaderFunctionArgs) => {
				const { returnPortalsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnPortalsDetailLoader(
					config,
					portalId,
					accountIdParam,
				);
			},
			async lazy() {
				const { ReturnsReturnEligibilityEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsReturnEligibilityEditPage };
			},
		},
		{
			path: PAGES.returns.sub.articleEligibility,
			async lazy() {
				const { ReturnsArticlesEligibilityPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsArticlesEligibilityPage };
			},
		},
		{
			path: `${PAGES.returns.sub.articleEligibility}/${PAGES.edit}/:portalId`,
			loader: async ({
				params: { accountId: accountIdParam, portalId },
			}: LoaderFunctionArgs) => {
				const { returnPortalsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnPortalsDetailLoader(
					config,
					portalId,
					accountIdParam,
				);
			},
			async lazy() {
				const { ReturnsArticleEligibilityEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsArticleEligibilityEditPage };
			},
		},
		{
			path: PAGES.returns.sub.carrierAvailability,
			async lazy() {
				const { ReturnsCarriersAvailabilityPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsCarriersAvailabilityPage };
			},
		},
		{
			path: `${PAGES.returns.sub.carrierAvailability}/${PAGES.edit}/:portalId`,
			loader: async ({
				params: { accountId: accountIdParam, portalId },
			}: LoaderFunctionArgs) => {
				const { returnPortalsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnPortalsDetailLoader(
					config,
					portalId,
					accountIdParam,
				);
			},
			async lazy() {
				const { ReturnsCarrierAvailabilityEditPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsCarrierAvailabilityEditPage };
			},
		},
		{
			path: PAGES.returns.sub.shopifyReturns,
			async lazy() {
				const { ShopifyReturnsReport } = await import(
					"src/components/Report"
				);
				return { Component: ShopifyReturnsReport };
			},
		},
		{
			path: PAGES.returns.sub.shopifyProductReturns,
			async lazy() {
				const { ShopifyProductReturnsReport } = await import(
					"src/components/Report"
				);
				return { Component: ShopifyProductReturnsReport };
			},
		},
		{
			path: PAGES.returns.sub.networkMonitoring,
			async lazy() {
				const { ReturnsNetworkMonitoringReport } = await import(
					"src/components/Report"
				);
				return { Component: ReturnsNetworkMonitoringReport };
			},
		},
		{
			path: PAGES.returns.sub.registrations,
			async lazy() {
				const { ReturnsRegistrationsReport } = await import(
					"src/components/Report"
				);
				return { Component: ReturnsRegistrationsReport };
			},
		},
		{
			path: PAGES.returns.sub.itemInsights,
			async lazy() {
				const { ReturnsItemInsightsReport } = await import(
					"src/components/Report"
				);
				return { Component: ReturnsItemInsightsReport };
			},
		},
		{
			path: PAGES.returns.sub.forecastPromotion,
			async lazy() {
				const { ReturnsForecastPromotionPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsForecastPromotionPage };
			},
		},
		{
			path: PAGES.returns.sub.forecast,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					returnsAuthorization.returns.forecast,
					[
						{
							redirectUrl: PAGES.returns.sub.forecastPromotion,
							abilityList:
								returnsAuthorization.returns.forecastPromotion,
						},
					],
				),
			async lazy() {
				const { ReturnsForecastReport } = await import(
					"src/components/Report"
				);
				return { Component: ReturnsForecastReport };
			},
		},
		{
			path: `${PAGES.returns.sub.approvals}/edit/:id`,
			loader: async ({ params: { id } }: LoaderFunctionArgs) => {
				const { returnRegistrationsDetailLoader } = await import(
					"src/module--returns/returns.lazy"
				);

				return returnRegistrationsDetailLoader(config, id);
			},
			async lazy() {
				const { ReturnsApprovalDetailPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsApprovalDetailPage };
			},
		},
	];
}

export function returnPromotionRoutes() {
	return [
		{
			path: "",
			lazy: async () => {
				const { ReturnsPromotionPage } = await import(
					"src/module--returns/returns.lazy"
				);
				return { Component: ReturnsPromotionPage };
			},
		},
	];
}
