import { ErrorMessage as FormikErrorMessage } from "formik";
import { isArray, isPlainObject } from "lodash";
import type { ComponentProps, PropsWithChildren } from "react";
import { ErrorText } from "src/components/form/ErrorText";

function ErrorComponent({ children, ...rest }: PropsWithChildren) {
	if (isArray(children) && children.some((child) => isPlainObject(child))) {
		return null;
	}

	if (isPlainObject(children)) {
		return null;
	}

	return <ErrorText message={children as string} {...rest} />;
}

export function ErrorTextFormElement(
	props: ComponentProps<typeof FormikErrorMessage>,
) {
	return <FormikErrorMessage component={ErrorComponent} {...props} />;
}
