import type { QueryClient } from "@tanstack/react-query";

import { AppConfig } from "src/configs/app-config";
import type { UserInfo } from "src/types/oidc-user-info";

import { redirect } from "react-router";
import {
	type AbilityArguments,
	checkAbilities,
	createAbility,
} from "src/contexts/authorization-context";
import {
	accountAccountsRetrieveOptions,
	accountUsersMeRetrieveOptions,
} from "src/types/_generated/@tanstack/react-query.gen";

export async function authorizationLoader(
	ctx: {
		oidcUser: UserInfo;
		idToken: string;
		queryClient: QueryClient;
		accountIdParam?: string;
		conditionalRedirect?: boolean;
	},
	abilityArguments?: AbilityArguments | undefined,
	redirectConditions?: Array<{
		redirectUrl: string;
		abilityList: AbilityArguments | undefined;
	}>,
) {
	const { oidcUser, queryClient, accountIdParam, conditionalRedirect } = ctx;
	const currentAccountId = accountIdParam
		? Number.parseInt(accountIdParam, 10)
		: undefined;
	if (!currentAccountId) {
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw new Response("Error", { status: 404 });
	}

	AppConfig.setPageUrlPrefix(currentAccountId);
	const account = await queryClient.ensureQueryData(
		accountAccountsRetrieveOptions({ path: { id: currentAccountId } }),
	);
	const userSettings = await queryClient.ensureQueryData(
		accountUsersMeRetrieveOptions(),
	);
	if (!account || !userSettings || !oidcUser) {
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw new Response("Error", { status: 404 });
	}
	const ability = createAbility(userSettings, account);
	const access = checkAbilities(ability, abilityArguments);

	if (redirectConditions && (!access || conditionalRedirect)) {
		for (const condition of redirectConditions) {
			if (checkAbilities(ability, condition.abilityList)) {
				return redirect(condition.redirectUrl);
			}
		}
	}

	if (!access) {
		// eslint-disable-next-line @typescript-eslint/no-throw-literal
		throw new Response("Not Allowed", { status: 403 });
	}
	return true;
}
