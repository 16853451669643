import { useFormikContext } from "formik";
import { type ComponentProps, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthorizationTooltip } from "src/components/Can/AuthorizationTooltip";
import { Button } from "src/components/ui/Button";
import { FormContext } from "src/contexts/form-context";

type FormSubmitButtonBaseProps = Partial<ComponentProps<typeof Button>>;

function FormSubmitButtonBase({
	children,
	disabled,
	...rest
}: FormSubmitButtonBaseProps) {
	const { t } = useTranslation();
	const { isSubmitting, dirty } = useFormikContext();

	return (
		<Button
			type="submit"
			variant="primary"
			isLoading={isSubmitting}
			disabled={
				disabled !== undefined ? disabled : !dirty || isSubmitting
			}
			{...rest}
		>
			{children ?? (isSubmitting ? t("saving") : t("save"))}
		</Button>
	);
}

type FormSubmitButtonProps = FormSubmitButtonBaseProps;
export function FormSubmitButton({ disabled, ...rest }: FormSubmitButtonProps) {
	const { authorizationArguments, authorized } = useContext(FormContext);

	if (!authorizationArguments) {
		return <FormSubmitButtonBase disabled={disabled} {...rest} />;
	}

	return (
		<AuthorizationTooltip allowed={authorized}>
			<FormSubmitButtonBase
				disabled={authorized ? disabled : true}
				{...rest}
			/>
		</AuthorizationTooltip>
	);
}
