import { createContextualCan } from "@casl/react";
import type { ComponentProps, PropsWithChildren } from "react";
import { AuthorizationTooltip } from "src/components/Can/AuthorizationTooltip";
import type { Tooltip } from "src/components/ui/Tooltip";
import {
	type AbilityArguments,
	type Action,
	AuthorizationContext,
	type Model,
} from "src/contexts/authorization-context";
import { useHasAuthorization } from "src/hooks/use-has-authorization";

export const Can = createContextualCan(AuthorizationContext.Consumer);

export type CanProps = ComponentProps<typeof Can>;

type CanWithTooltipProps = CanProps & {
	tooltipProps?: Partial<ComponentProps<typeof Tooltip>>;
};

export function CanWithTooltip(props: CanWithTooltipProps) {
	const { children, tooltipProps, ...rest } = props;

	return (
		<Can passThrough {...rest}>
			{(allowed, abilities) => (
				<AuthorizationTooltip
					allowed={allowed}
					authorizedOn={(props as { on: Model }).on}
					authorizedDo={(props as { do: Action }).do}
					{...tooltipProps}
				>
					{typeof children === "function"
						? children(allowed, abilities)
						: children}
				</AuthorizationTooltip>
			)}
		</Can>
	);
}

type CanAuthorizationProps = {
	authorizationArguments?: AbilityArguments;
};

export function CanAuthorization({
	authorizationArguments,
	children,
}: PropsWithChildren<CanAuthorizationProps>) {
	const hasAuthorization = useHasAuthorization(authorizationArguments);
	if (authorizationArguments && !hasAuthorization) return null;

	return children;
}
