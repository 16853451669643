import { useTranslation } from "react-i18next";
import { ErrorState } from "src/components/ErrorState";
import { AnchorButton } from "src/components/ui/AnchorButton";

export function NoPermission() {
	const { t } = useTranslation();

	return (
		<ErrorState errorKey="403" variant="fullScreen">
			<AnchorButton to="/" variant="primary">
				{t("accountError.goBack")}
			</AnchorButton>
		</ErrorState>
	);
}
