import { Dialog, Transition } from "@headlessui/react";
import { type VariantProps, cva } from "class-variance-authority";
import { Fragment, type PropsWithChildren, useRef } from "react";
import { cn } from "src/utils/tailwind";

export const sizeElementVariants = cva(
	[
		"mx-auto transform divide-y divide-slate-100 rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all",
	],
	{
		variants: {
			size: {
				default: "max-w-xl",
				lg: "max-w-3xl",
				xl: "max-w-5xl",
			},
		},
		defaultVariants: {
			size: "default",
		},
	},
);

type ModalProps = PropsWithChildren<{
	isOpen: boolean;
	close: () => void;
	onClose?: () => void;
	handleAfterLeave?: () => void;
	dataTestId?: string;
	disableAutoFocus?: boolean;
	noAutoClose?: boolean;
	className?: string;
}> &
	VariantProps<typeof sizeElementVariants>;

/**
 *
 * @deprecated use new {Dialog} instead.
 */
export function Modal({
	children,
	isOpen,
	disableAutoFocus,
	handleAfterLeave,
	close,
	onClose,
	className,
	dataTestId,
	noAutoClose,
	size,
}: ModalProps) {
	const handleClose = () => {
		close();
		onClose?.();
	};
	const dialogRef = useRef<HTMLDivElement>(null);

	return (
		<Transition.Root
			show={isOpen}
			as={Fragment}
			afterLeave={handleAfterLeave}
			appear
		>
			<Dialog
				as="div"
				ref={dialogRef}
				className="relative z-modal"
				onClose={noAutoClose ? () => {} : handleClose}
				data-testid={`${dataTestId || "modal"}`}
				initialFocus={disableAutoFocus ? dialogRef : undefined}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-slate-500 bg-opacity-25 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className={cn(
								sizeElementVariants({ size }),
								className,
							)}
						>
							{children}
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
