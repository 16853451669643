import { isProd, isTest } from "src/settings";
import { hasQueryParam } from "src/utils/query";

export async function setupMsw(force?: boolean) {
	const searchParams = new URLSearchParams(window.location.search);
	const mocksParam = searchParams.get("mocks");

	if ((force || hasQueryParam("mocks") || isTest()) && !isProd()) {
		const { worker } = await import("src/mock-service-worker/browser");

		await worker.start({
			serviceWorker: {
				url: "/DevOidcServiceWorker.js",
			},
			onUnhandledRequest: "bypass",
			quiet: mocksParam !== "debug",
		});
	}
}
