import type { VariantProps } from "class-variance-authority";
import { Link, type LinkProps } from "react-router";
import { buttonVariants } from "src/components/ui/Button";
import { cn } from "src/utils/tailwind";

interface AnchorButtonProps
	extends LinkProps,
		VariantProps<typeof buttonVariants> {
	track?: boolean;
	"data-matomo-name"?: string;
	"data-matomo-event"?: string;
	"data-matomo-category"?: string;
	"data-matomo-action"?: "click-event" | undefined;
}

export function AnchorButton({
	id,
	track = true,
	variant,
	size,
	rounded,
	className,
	children,
	disabled,
	...others
}: AnchorButtonProps) {
	const trackEvent = track && id;

	return (
		<Link
			id={id}
			type="button"
			className={cn(
				buttonVariants({
					variant,
					size,
					rounded,
					disabled,
				}),
				className,
			)}
			rel="noopener noreferrer"
			data-matomo-name={trackEvent ? id : undefined}
			data-matomo-event={trackEvent ? "click" : undefined}
			data-matomo-action={trackEvent ? "click-event" : undefined}
			aria-disabled={disabled ?? undefined}
			{...others}
		>
			{children}
		</Link>
	);
}
