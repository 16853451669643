export function hasQueryParam(queryName: string) {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(queryName) !== null;
}

export function getQueryParam(queryName: string) {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(queryName);
}

export function appendQueryParamToUrl(
	url: string,
	queryName: string,
	queryValue: string,
): string {
	const [baseUrlWithQuery, hash = ""] = url.split("#");
	const urlArray = baseUrlWithQuery.split("?");
	const urlSearchParams = new URLSearchParams(urlArray[1] ?? "");
	urlSearchParams.delete(queryName);
	urlSearchParams.append(queryName, queryValue);

	const hashPart = hash ? `#${hash}` : "";

	return `${urlArray[0]}?${urlSearchParams.toString()}${hashPart}`;
}
