import type { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";
import { PageStateBoundary } from "src/components/PageStateBoundary";

export type LayoutProps = PropsWithChildren<{
	metaTitle?: string;
}>;

export function PageLayout({ children, metaTitle }: LayoutProps) {
	return (
		<>
			{metaTitle && (
				<Helmet>
					<title>{metaTitle}</title>
				</Helmet>
			)}
			<PageStateBoundary>{children}</PageStateBoundary>
		</>
	);
}
