export enum AccountFeatureFlags {
	hasPortal = "hasPortal",
	hasPromise = "hasPromise",
	hasNewUserManagement = "hasNewUserManagement",
	hasTrendingDelay = "hasTrendingDelay",
	hasJourney = "hasJourney",
	hasReturns = "hasReturns",
	hasReturnsForecasting = "hasReturnsForecasting",
	hasShopifyReturnsAnalytics = "hasShopifyReturnsAnalytics",
	hasPortalBeta = "hasPortalBeta",
	hasCustomerSegmentation = "hasCustomerSegmentation",
	hasClaimsReport = "hasClaimsReport",
	hasJourneyAdvancedMode = "hasJourneyAdvancedMode",
	hasEmotionAi = "hasEmotionAi",
	hasBenchmarking = "hasBenchmarking",
	hasAppStore = "hasAppStore",
	hasPortalSubmit = "hasPortalSubmit",
	hasProductRecommendations = "hasProductRecommendations",
	hasReturnRulesV2 = "hasReturnRulesV2",
	hasSmartSurvey = "hasSmartSurvey",
	hasReturnsOverview = "hasReturnsOverview", // FrontEnd generated feat flag for hardcoded check
	hasGptOptIn = "hasGptOptIn", // FrontEnd generated feat flag for hardcoded check
	hasTrackingReturnInitiation = "hasTrackingReturnInitiation",
}

export type AccountFeatureFlagsType = {
	[_key in AccountFeatureFlags]: boolean;
};

export enum UserFeatureFlags {
	hasPortalBetaEnabled = "hasPortalBetaEnabled",
	hasMarketingOptIn = "hasMarketingOptIn",
	hasPreviewFeatures = "hasPreviewFeatures",
}

export type UserFeatureFlagsType = {
	[_key in UserFeatureFlags]: boolean;
};
