import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { initI18next } from "src/locales/i18n";

export function use18nextInit() {
	const [isTranslationReady, setTranslationReady] = useState(false);

	useEffect(() => {
		initI18next()
			.then(() => setTranslationReady(true))
			.catch((error: Error) => {
				Sentry.captureException(error);
			});
	}, []);

	return { ready: isTranslationReady };
}
