import { Slot } from "@radix-ui/react-slot";
import { uniqueId } from "lodash";
import { type ComponentProps, type ReactNode, useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { cn } from "src/utils/tailwind";

type TooltipProps = Omit<ComponentProps<typeof ReactTooltip>, "content"> & {
	content: ReactNode;
	children: ReactNode;
};

export function Tooltip({
	className,
	children,
	id,
	content,
	...others
}: TooltipProps) {
	const idRef = useRef(id ?? uniqueId("tooltip"));

	return (
		<>
			<Slot data-tooltip-id={idRef.current}>{children}</Slot>
			<ReactTooltip
				className={cn("z-tooltip", className)}
				id={idRef.current}
				{...others}
			>
				{content}
			</ReactTooltip>
		</>
	);
}
