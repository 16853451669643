import { useQuery } from "@tanstack/react-query";
import { useCurrentAccountId } from "src/hooks/use-account-id";
import { accountAccountsRetrieveOptions } from "src/types/_generated/@tanstack/react-query.gen";

export function useAccount() {
	const accountId = useCurrentAccountId();

	return useQuery({
		...accountAccountsRetrieveOptions({ path: { id: accountId } }),
		refetchOnWindowFocus: false,
		enabled: !!accountId,
	});
}
