import type { SearchParamsOption } from "ky";
import type {
	FiltersSearchOptionsProps,
	GetAppliedFiltersProps,
} from "src/types/filters";

export interface CommunicationMessageType {
	name?: string;
	message_type: string;
	prettyName: string;
}

export type CommunicationMessageTypeResponse = CommunicationMessageType[];

export interface CommunicationsResponseType {
	count: number;
	finalResult: CommunicationsType[];
}

export interface CommunicationTracking {
	id: string;
	courier: {
		name: string;
		prettyname: string;
	};
	tracking_number: string;
	country: {
		name: string;
		prettyname: string;
	};
}

export interface WebhookType {
	id: string;
	message_type: string;
	send_date: string;
	status_code: number;
	success: boolean;
	is_final_attempt: boolean;
	timing: number;
	receiver: string;
	headers: {
		[key: string]: string;
	};
	payload: {
		[key: string]: string;
	};
	reponse_headers: {
		[key: string]: string;
	};
	response_body: string;
	response_error: string | null;
	is_push_notification: boolean;
	tracking_object_id: string;
	tracking_number: string;
	order_number: string;
	courier_pretty: string;
	courier: string;
	destination_country_iso3: string;
	payload_size: number;
	client: string;
}

export interface SMSType {
	id: string;
	timestamp: string;
	messageType: string;
	message: string;
	receiver: string;
	clicked: boolean | null;
	segments: number;
	sender: string;
	trackingId: string;
	failed: boolean;
	courier: string;
	courier_pretty: string;
	tracking_number: string;
	orderNo: string;
}

export interface EmailType {
	id: string;
	rescueLink: string;
	type: string;
	client: string;
	sent: string;
	from: string;
	to: string;
	subject: string;
	opened: string | null;
	clicked: number | null;
	tracking: CommunicationTracking;
	orderNo: string;
	recipientConf: string[];
}

export type CommunicationsType = EmailType | SMSType | WebhookType;

export interface CommunicationsSearchOptionsProps
	extends FiltersSearchOptionsProps {
	pageSize: number;
	message_type?: string | null;
}

export type CommunicationsSearchOptsProps = CommunicationsSearchOptionsProps &
	SearchParamsOption;

export interface GetAppliedCommunicationFiltersProps
	extends GetAppliedFiltersProps {
	pageSize: number;
	messageType?: string | null;
}

export enum CommunicationTypes {
	Email = "email",
	Sms = "sms",
	Webhook = "webhook",
}

export interface HTMLResponseType {
	created: string;
	subject: string;
	sender: string;
	recipient: string;
	live: boolean;
	bounce: boolean;
	content: string;
}

export type ResendEmailResponseType = {
	success: boolean;
	correlationId: string;
};
