import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";
import { AccountFeatureFlags } from "src/types/portal-features.type";

type PromiseAuthorization = {
	promises: GenericAuthorizationMap<
		| "index"
		| "configuration"
		| "carrierLevel"
		| "warehouses"
		| "deliveryMethods"
		| "deliveryPredictionService"
		| "analytics"
	>;
};

export const promiseAuthorization: PromiseAuthorization = {
	promises: {
		index: {
			can: {
				oneOf: [
					[Action.view, Model.PROMISE_COURIERSERVICELEVEL],
					[Action.view, Model.PROMISE_DELIVERYMETHOD],
					[Action.view, Model.PROMISE_WAREHOUSE],
					[Action.view, Model.PROMISE_DELIVERYPREDICTIONSERVICE],
					[Action.view, Model.PORTAL_REPORT],
				],
			},
		},
		configuration: {
			can: {
				oneOf: [
					[Action.view, Model.PROMISE_COURIERSERVICELEVEL],
					[Action.view, Model.PROMISE_DELIVERYMETHOD],
					[Action.view, Model.PROMISE_WAREHOUSE],
					[Action.view, Model.PROMISE_DELIVERYPREDICTIONSERVICE],
				],
				allOf: [
					[Action.useAccountFeature, AccountFeatureFlags.hasPromise],
				],
			},
		},
		carrierLevel: {
			can: {
				allOf: [
					[Action.view, Model.PROMISE_COURIERSERVICELEVEL],
					[Action.useAccountFeature, AccountFeatureFlags.hasPromise],
				],
			},
		},
		warehouses: {
			can: {
				allOf: [
					[Action.view, Model.PROMISE_WAREHOUSE],
					[Action.useAccountFeature, AccountFeatureFlags.hasPromise],
				],
			},
		},
		deliveryMethods: {
			can: {
				allOf: [
					[Action.view, Model.PROMISE_DELIVERYMETHOD],
					[Action.useAccountFeature, AccountFeatureFlags.hasPromise],
				],
			},
		},
		deliveryPredictionService: {
			can: {
				allOf: [
					[Action.view, Model.PROMISE_DELIVERYPREDICTIONSERVICE],
					[Action.useAccountFeature, AccountFeatureFlags.hasPromise],
				],
			},
		},
		analytics: {
			can: {
				allOf: [[Action.view, Model.PORTAL_REPORT]],
			},
		},
	},
};
