import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentPropsWithoutRef } from "react";
import { cn } from "src/utils/tailwind";

const flexVariants = cva(["flex"], {
	variants: {
		dir: {
			row: "flex-row",
			col: "flex-col",
			rowReverse: "flex-row-reverse",
			colReverse: "flex-col-reverse",
		},
		centered: {
			true: "justify-center items-center",
		},
		justify: {
			center: "justify-center",
			end: "justify-end",
			start: "justify-start",
			between: "justify-between",
		},
		align: {
			center: "items-center",
			end: "items-end",
			start: "items-start",
		},
		wrap: {
			true: "flex-wrap",
		},
		noShrink: {
			true: "flex-shrink-0",
		},
		gap: {
			0.5: "gap-0.5",
			1: "gap-1",
			2: "gap-2",
			3: "gap-3",
			4: "gap-4",
			5: "gap-5",
			6: "gap-6",
			7: "gap-7",
			8: "gap-8",
			9: "gap-9",
			10: "gap-10",
		},
	},
});

type FlexProps = ComponentPropsWithoutRef<"div"> &
	VariantProps<typeof flexVariants> & {
		asChild?: boolean;
		as?: "div" | "span" | "p";
	};

export function Flex({
	children,
	asChild,
	as = "div",
	className,
	dir,
	centered,
	justify,
	gap,
	align,
	wrap,
	noShrink,
	...others
}: FlexProps) {
	const Comp = asChild ? Slot : as;

	return (
		<Comp
			className={cn(
				flexVariants({
					dir,
					centered,
					justify,
					gap,
					align,
					wrap,
					noShrink,
				}),
				className,
			)}
			{...others}
		>
			{children}
		</Comp>
	);
}
