import { type QueryClient, queryOptions } from "@tanstack/react-query";
import { redirect } from "react-router";
import type { ApiError } from "src/types/ApiError";
import {
	configClientsListOptions,
	journeyConfigurationsListOptions,
	journeyConfigurationsRetrieveOptions,
	journeyExperimentsListOptions,
	journeyExperimentsRetrieveOptions,
	journeyFlowVisualizationsListOptions,
	journeyFlowVisualizationsRetrieveOptions,
	journeyMessageTypesListOptions,
	journeyTriggersRetrieveOptions,
} from "src/types/_generated/@tanstack/react-query.gen";

export const journeyFlowsLoader = (qc: QueryClient, accountIdParam: number) => {
	const qoJourneyConfigList = journeyConfigurationsListOptions({
		query: { account: [accountIdParam] },
	});

	const qoMessageTypeList = journeyMessageTypesListOptions({
		query: { account: [accountIdParam] },
	});

	const qoClientsList = configClientsListOptions({
		query: { account: [accountIdParam] },
	});

	return Promise.all([
		qc.ensureQueryData(qoJourneyConfigList),
		qc.ensureQueryData(qoMessageTypeList),
		qc.ensureQueryData(qoClientsList),
	]);
};

export const journeyEditLoader = (
	qc: QueryClient,
	journeyId: number,
	accountIdParam: number,
) => {
	try {
		return Promise.all([
			qc.ensureQueryData(
				queryOptions(
					journeyConfigurationsRetrieveOptions({
						path: { id: journeyId },
					}),
				),
			),
			qc.ensureQueryData(
				configClientsListOptions({
					query: { account: [accountIdParam] },
				}),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const journeyCreateLoader = (
	qc: QueryClient,
	accountIdParam: number,
) => {
	try {
		return qc.ensureQueryData(
			configClientsListOptions({
				query: { account: [accountIdParam] },
			}),
		);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const journeyTriggerLoader = (
	qc: QueryClient,
	triggerId: number,
	accountIdParam: number,
) => {
	try {
		return Promise.all([
			qc.ensureQueryData(
				journeyTriggersRetrieveOptions({
					path: { id: triggerId },
				}),
			),
			qc.ensureQueryData(
				journeyMessageTypesListOptions({
					query: { account: [accountIdParam] },
				}),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const journeyNewTriggerLoader = (
	qc: QueryClient,
	request: Request,
	accountIdParam: number,
) => {
	try {
		const journeyId = new URL(request.url).searchParams.get("journey");

		return Promise.all([
			qc.ensureQueryData(
				queryOptions(
					journeyConfigurationsRetrieveOptions({
						path: { id: Number(journeyId) },
					}),
				),
			),
			qc.ensureQueryData(
				journeyMessageTypesListOptions({
					query: { account: [accountIdParam] },
				}),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const journeyFlowChartsLoader = (
	qc: QueryClient,
	accountIdParam: number,
	journeyId: number,
) => {
	try {
		return Promise.all([
			qc.ensureQueryData(
				journeyConfigurationsRetrieveOptions({
					path: { id: journeyId },
				}),
			),
			qc.ensureQueryData(
				queryOptions(
					journeyFlowVisualizationsListOptions({
						query: { account: [accountIdParam] },
					}),
				),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const journeyFlowChartLoader = (
	qc: QueryClient,
	journeyId: number,
	accountIdParam: number,
	flowChartId: number,
) => {
	try {
		return Promise.all([
			qc.ensureQueryData(
				journeyConfigurationsRetrieveOptions({
					path: { id: journeyId },
				}),
			),
			qc.ensureQueryData(
				journeyMessageTypesListOptions({
					query: { account: [accountIdParam] },
				}),
			),
			qc.ensureQueryData(
				journeyFlowVisualizationsRetrieveOptions({
					path: { id: flowChartId },
				}),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const journeyNewFlowChartLoader = (
	qc: QueryClient,
	accountIdParam: number,
	journeyId: number,
) => {
	try {
		return Promise.all([
			qc.ensureQueryData(
				journeyConfigurationsRetrieveOptions({
					path: { id: journeyId },
				}),
			),
			qc.ensureQueryData(
				journeyMessageTypesListOptions({
					query: { account: [accountIdParam] },
				}),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const experimentsListLoader = (
	qc: QueryClient,
	accountIdParam: number,
) => {
	try {
		return qc.ensureQueryData(
			journeyExperimentsListOptions({
				query: { account: [accountIdParam] },
			}),
		);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const experimentsDetailsLoader = (
	qc: QueryClient,
	id: number,
	accountIdParam: number,
) => {
	try {
		return Promise.all([
			qc.ensureQueryData(
				journeyExperimentsRetrieveOptions({ path: { id } }),
			),
			qc.ensureQueryData(
				journeyMessageTypesListOptions({
					query: { account: [accountIdParam] },
				}),
			),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const experimentsNewLoader = (
	qc: QueryClient,
	accountIdParam: number,
) => {
	try {
		return qc.ensureQueryData(
			journeyMessageTypesListOptions({
				query: { account: [accountIdParam] },
			}),
		);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};
