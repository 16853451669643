import type { VariantProps } from "class-variance-authority";
import { useField, useFormikContext } from "formik";
import {
	type ElementType,
	type InputHTMLAttributes,
	type PropsWithChildren,
	type ReactNode,
	forwardRef,
	useContext,
	useMemo,
} from "react";
import { ErrorTextFormElement } from "src/components/form/ErrorText/ErrorTextFormElement";
import { FormElementDescription } from "src/components/form/FormElementDescription";
import { InputLabel, type labelVariants } from "src/components/form/InputLabel";
import { InputResetButton } from "src/components/form/InputResetButton";
import { TextInput } from "src/components/form/Text/TextInput";
import { baseFormElementWrapperVariants } from "src/components/form/form.styles";
import { fieldValidator } from "src/components/form/validators";
import { FormContext } from "src/contexts/form-context";
import { PiiWrapper } from "src/layouts/PiiWrapper";
import { cn } from "src/utils/tailwind";

type TextInputFormElementProps = InputHTMLAttributes<HTMLInputElement> & {
	label?: string;
	name: string;
	description?: ReactNode;
	descriptionModalContent?: ReactNode;
	wrapperClassName?: string;
	max?: number;
	min?: number;
	multipleOf?: number;
	minLength?: number;
	maxLength?: number;
	isEmail?: boolean;
	hidePii?: boolean;
	tooltip?: string;
	hideOptionalText?: boolean;
	canBeEmpty?: boolean;
	isUrl?: boolean;
	hasResetButton?: boolean;
	onReset?: () => void;
	leftIcon?: ElementType<PropsWithChildren>;
	labelStyle?: VariantProps<typeof labelVariants>["filedStyle"];
} & VariantProps<typeof baseFormElementWrapperVariants>;

export const TextInputFormElement = forwardRef<
	HTMLInputElement,
	TextInputFormElementProps
>(
	(
		{
			name,
			label,
			description,
			descriptionModalContent,
			wrapperClassName,
			required,
			pattern,
			maxLength,
			minLength,
			layout,
			max,
			min,
			multipleOf,
			type = "text",
			isEmail = false,
			hidePii,
			tooltip,
			hideOptionalText,
			canBeEmpty,
			isUrl,
			hasResetButton,
			onReset,
			className,
			leftIcon: LeftIcon,
			labelStyle,
			...rest
		},
		ref,
	) => {
		const { layout: formLayout } = useContext(FormContext);
		const { setFieldValue } = useFormikContext();
		const validate = useMemo(
			() =>
				fieldValidator(type === "number" ? "number" : "string", {
					required,
					pattern,
					maxLength,
					minLength,
					maxValue: max,
					minValue: min,
					isEmail,
					canBeEmpty,
					isUrl,
					multipleOf,
				}),
			[
				max,
				maxLength,
				min,
				minLength,
				pattern,
				required,
				type,
				isEmail,
				canBeEmpty,
				isUrl,
				multipleOf,
			],
		);

		const [field, meta] = useField({
			name,
			type,
			validate,
		});

		const showResetButton = !!(hasResetButton && field.value);

		return (
			<div
				className={cn(
					baseFormElementWrapperVariants({
						layout: layout || formLayout,
					}),
					wrapperClassName,
				)}
			>
				{!!label && (
					<InputLabel
						htmlFor={field.name}
						meta={meta}
						required={required}
						hideOptionalText={hideOptionalText}
						filedStyle={labelStyle}
					>
						{label}
					</InputLabel>
				)}
				<div>
					<PiiWrapper enable={hidePii}>
						<div className="relative">
							{LeftIcon && (
								<LeftIcon className="absolute left-2 top-1/2 -translate-y-1/2 w-4 h-4" />
							)}
							<TextInput
								{...rest}
								className={cn(className, {
									"pr-8": showResetButton,
									"pl-8": !!LeftIcon,
								})}
								aria-required={required}
								field={field}
								meta={meta}
								ref={ref}
								type={type}
							/>
							{showResetButton && (
								<InputResetButton
									className="absolute right-2 top-1/2 -translate-y-1/2"
									data-testid="clear-button"
									onClick={async () => {
										await setFieldValue(name, "");
										onReset?.();
									}}
								/>
							)}
						</div>
						{!!description && (
							<FormElementDescription
								descriptionModalContent={
									descriptionModalContent
								}
								tooltip={tooltip}
							>
								{description}
							</FormElementDescription>
						)}
						<ErrorTextFormElement name={name} />
					</PiiWrapper>
				</div>
			</div>
		);
	},
);
