import type { UserInfo } from "src/types/oidc-user-info";
import { Roles } from "src/types/roles.type";

export const OidcUser = {
	sub: "28411301-2998-4541-8e83-b43dde7a6pd6",
	resource_access: {},
	email_verified: true,
	groups: ["/legacy:super-admin", "/parcellab-employee"],
	accounts: [1612197, 321321],
	email: "testaccount@parcellab.com",
	parcellab: {
		roles: Object.values(Roles),
	},
	family_name: "Test",
	given_name: "Account",
	preferred_username: "testaccount@parcellab.com",
} as UserInfo;
