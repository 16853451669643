import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";
import {
	AccountFeatureFlags,
	UserFeatureFlags,
} from "src/types/portal-features.type";

type ReturnsAuthorization = {
	returns: GenericAuthorizationMap<
		| "index"
		| "returnsPortals"
		| "translations"
		| "returnEligibility"
		| "articleEligibility"
		| "returnReasons"
		| "returnsCompensationMethods"
		| "returnReasonsV2"
		| "carrierAvailability"
		| "orderApis"
		| "courierApis"
		| "publish"
		| "insights"
		| "forecastPromotion"
		| "forecast"
		| "shopifyReturnsReports"
	>;
};

export const returnsAuthorization: ReturnsAuthorization = {
	returns: {
		index: {
			can: {
				oneOf: [
					[Action.view, Model.RETURNS_RETURNSPORTAL],
					[Action.view, Model.RETURNS_RETURNSCOURIERAPI],
					[Action.view, Model.RETURNS_RETURNSORDERAPI],
					[Action.view, Model.RETURNS_RETURNSTRANSLATION],
				],
				allOf: [
					[Action.useAccountFeature, AccountFeatureFlags.hasReturns],
				],
			},
		},
		returnsPortals: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSPORTAL]],
			},
		},
		translations: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSTRANSLATION]],
			},
		},
		returnEligibility: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSPORTAL]],
			},
		},
		articleEligibility: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSPORTAL]],
			},
		},
		returnReasons: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNREASON]],
			},
		},
		returnsCompensationMethods: {
			can: {
				allOf: [
					[Action.view, Model.RETURNS_COMPENSATIONMETHOD],
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPreviewFeatures,
					],
				],
			},
		},
		returnReasonsV2: {
			can: {
				oneOf: [
					[Action.view, Model.RETURNS_RETURNREASON],
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPreviewFeatures,
					],
				],
			},
		},
		carrierAvailability: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSPORTAL]],
			},
		},
		orderApis: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSORDERAPI]],
			},
		},
		courierApis: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSCOURIERAPI]],
			},
		},
		publish: {
			can: {
				oneOf: [[Action.view, Model.RETURNS_RETURNSPORTAL]],
			},
		},
		insights: {
			can: {
				oneOf: [[Action.view, Model.PORTAL_REPORT]],
			},
		},
		forecastPromotion: {
			cannot: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasReturnsForecasting,
					],
				],
			},
		},
		forecast: {
			can: {
				allOf: [
					[Action.view, Model.PORTAL_REPORT],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasReturnsForecasting,
					],
				],
			},
		},
		shopifyReturnsReports: {
			can: {
				allOf: [
					[Action.view, Model.PORTAL_REPORT],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasShopifyReturnsAnalytics,
					],
				],
			},
		},
	},
};
