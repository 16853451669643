import { setUser } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import {
	type LoaderFunctionArgs,
	type RouteObject,
	RouterProvider,
	redirect,
} from "react-router";
import { Logout } from "src/components/Logout/Logout";
import { AppConfig } from "src/configs/app-config";
import { APP_URL_PREFIX } from "src/configs/constants";
import { PAGE_URLS } from "src/configs/pages-urls";
import { NEXT_URL_IDENTIFIER } from "src/hooks/use-next-url";
import { Signin } from "src/pages/signin";
import { MainComponent } from "src/router/MainComponent";
import { AppErrorState } from "src/router/app-error-state";
import { appRoutes } from "src/router/app-routes";
import type { RouterConfig } from "src/router/types";
import { useOidcIdToken, useOidcUser } from "src/services/oidc-exporter";
import { sentryCreateBrowserRouter } from "src/services/sentry";
import { accountAccountsRetrieveOptions } from "src/types/_generated/@tanstack/react-query.gen";
import { client } from "src/types/_generated/client.gen";
import { canAccessToAccount, getDefaultUserAccountId } from "src/utils/user";

const authenticationRoutes: RouteObject[] = [
	{
		path: PAGE_URLS.signIn.path,
		element: <Signin />,
	},
	{
		path: PAGE_URLS.logout.path,
		element: <Logout />,
	},
];

const publicRoutes: RouteObject[] = [
	{
		path: PAGE_URLS.publicLink.path,
		async lazy() {
			const { SharedTrackingPage } = await import(
				"src/module--trackings/trackings.lazy"
			);
			return { Component: SharedTrackingPage };
		},
	},
];

const authenticatedRoutes = (config: RouterConfig): RouteObject[] => [
	{
		path: `/${APP_URL_PREFIX}/:accountId/*`,
		element: <MainComponent />,
		children: appRoutes(config),
		loader: ({ params: { accountId } }) => {
			if (accountId) {
				AppConfig.setPageUrlPrefix(accountId);
				client.setConfig({
					headers: {
						"pl-account-id": accountId,
						user: accountId,
					},
				});
				return config.queryClient.ensureQueryData(
					accountAccountsRetrieveOptions({
						path: { id: Number(accountId) },
					}),
				);
			}
			return null;
		},
		errorElement: <AppErrorState />,
	},
	{
		path: "*",

		loader: ({ request }) => {
			const url = new URL(request.url);
			const pathname = url.pathname;
			let newPathname = pathname;
			let prefix = AppConfig.getInstance().pageUrlPrefix;
			if (prefix === "") {
				const overrideAccountId =
					window.localStorage.getItem("pl-account-id");
				if (
					overrideAccountId &&
					canAccessToAccount(
						config.oidcUser,
						Number(overrideAccountId),
					)
				) {
					prefix = `/${APP_URL_PREFIX}/${overrideAccountId}`;
				} else {
					const defaultAccountId = getDefaultUserAccountId(
						config.oidcUser,
					);
					prefix = `/${APP_URL_PREFIX}/${defaultAccountId}`;
				}
			}

			if (
				pathname === "/" ||
				pathname === `/${APP_URL_PREFIX}` ||
				pathname === `/${APP_URL_PREFIX}/`
			) {
				newPathname = prefix;
			}

			if (!pathname.startsWith(`${prefix}`)) {
				newPathname = `${prefix}${pathname}`;
			}
			url.pathname = newPathname;
			return redirect(url.toString());
		},
	},
];

export function MainRouter({ isAuthenticated }: { isAuthenticated?: boolean }) {
	const { oidcUser } = useOidcUser();
	const { idToken } = useOidcIdToken() as { idToken: string };
	const queryClient = useQueryClient();

	useEffect(() => {
		if (oidcUser)
			setUser({ username: oidcUser.preferred_username ?? "none" });
	}, [oidcUser]);

	return (
		<RouterProvider
			router={sentryCreateBrowserRouter([
				...authenticationRoutes,
				...publicRoutes,
				...(isAuthenticated
					? authenticatedRoutes({
							oidcUser,
							idToken,
							queryClient,
						})
					: [
							{
								path: "*",
								loader: ({ request }: LoaderFunctionArgs) => {
									const url = request.url;
									return redirect(
										`${PAGE_URLS.signIn.path}?${NEXT_URL_IDENTIFIER}=${url}`,
									);
								},
							},
						]),
			])}
		/>
	);
}
