import { PAGES } from "src/configs/pages-urls";

const experienceDesignOldRoutes = {
	clients: "journey/clients",
	layouts: "journey/layouts",
	flows: "journey/flows",
	partials: "journey/partials",
	experiments: "journey/experiments",
};

export const redirectsMapping = {
	[experienceDesignOldRoutes.clients]: PAGES.experienceDesign.sub.clients,
	[experienceDesignOldRoutes.layouts]: PAGES.experienceDesign.sub.layouts,
	[experienceDesignOldRoutes.flows]: PAGES.experienceDesign.sub.journeys,
	[experienceDesignOldRoutes.partials]: PAGES.experienceDesign.sub.partials,
	[experienceDesignOldRoutes.experiments]:
		PAGES.experienceDesign.sub.experiments,
};
