import { useLocation, useNavigate } from "react-router";

export function useGoBack(defaultBackPath?: string): () => void {
	const location = useLocation();
	const navigate = useNavigate();

	const navigateBack = () => {
		const isHistoryEntryExists = location.key !== "default";

		if (defaultBackPath && !isHistoryEntryExists) {
			void navigate(defaultBackPath, { replace: true });
		} else {
			void navigate(-1);
		}
	};

	return navigateBack;
}
