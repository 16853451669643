import { memoize } from "lodash";
import { AppConfig } from "src/configs/app-config";

export const PAGES = Object.freeze({
	new: "new",
	edit: "edit",
	service: {
		path: "service",
		sub: {
			accounts: "account/account/",
			apitokens: "account/apitoken/",
			users: "account/user/",
			gdpr: "gdpr",
			authorizations: "service/authorizableserviceinstance/",
			whitelabeldomains: "config/whitelabeldomainconfig/",
			voucherCodesList: "misc/voucher/",
			voucherCodesUpload: "misc/import/",
		},
	},
	trackings: {
		path: "trackings",
		sub: {
			trackings: "trackings",
			return: "return",
			details: "trackings/details",
			returnsOverview: "returns-overview",
			delivery: "delivery",
			deliveryExceptions: "delivery-exceptions",
			leadTimes: "lead-times",
			liveNetwork: "live-network",
			communicationsInsights: "communications",
			exceptions: "exceptions",
			claims: "claims",
			claimsPromotion: "claims-access",
			benchmarking: "benchmarking/carrier",
			benchmarkingPromotion: "benchmarking/promotion",
		},
	},
	communications: {
		path: "communication",
		sub: {
			emails: "emails",
			sms: "sms",
			webhooks: "webhooks",
			emailDetails: "emails/details",
			smsDetails: "sms/details",
			webhookDetails: "webhooks/details",
			emailsInsights: "emails-insights",
			smsInsights: "sms-insights",
			webhooksInsights: "webhooks-insights",
			orderStatusPage: "order-status-page",
			feedback: "feedback",
			emotionAI: "emotion-ai",
			emotionAIPromotion: "emotion-ai-access",
			benchmarking: "benchmarking",
			benchmarkingPromotion: "benchmarking-access",
		},
	},
	experienceDesign: {
		path: "experience-design",
		sub: {
			journey: "journey",
			clients: "journey/shops",
			layouts: "journey/templates",
			journeys: "journey/journeys",
			flowVisualize: "visualize",
			messageTypes: "journey/message-types",
			messages: "journey/messages",
			partials: "journey/snippets",
			experiments: "journey/ab-tests",
			experimentsMessages: "journey/ab-tests/messages",
			triggers: "journey/triggers",
			audiences: "campaigns/audiences",
			campaigns: "campaigns/campaigns",
			campaignsAnalytics: "campaigns/analytics",
			campaignsPreview: "campaigns/campaigns/preview",
			surveys: "surveys/surveys",
			surveysDashboard: "surveys/dashboard",
			surveyResponses: "surveys/responses",
			journeyPromotion: "journey-access",
		},
	},
	promise: {
		path: "promises",
		sub: {
			carrierLevel: "service-levels",
			warehouses: "warehouse",
			deliveryMethods: "delivery-methods",
			deliveryPredictionService: "delivery-prediction-service",
			promiseAnalytics: "promise-analytics",
		},
	},
	returns: {
		path: "returns",
		sub: {
			portals: "portals",
			approvals: "approvals",
			translations: "translations",
			publish: "publish",
			orderApis: "order-apis",
			courierApis: "carrier-apis",
			returnReasons: "return-reasons",
			returnsCompensationMethods: "compensation-methods",
			returnReasonsV2: "return-reasons-v2",
			returnEligibility: "return-windows",
			articleEligibility: "article-eligibility",
			carrierAvailability: "carrier-availability",
			networkMonitoring: "network-monitoring",
			shopifyProductReturns: "shopify-product-returns",
			shopifyReturns: "shopify-returns",
			itemInsights: "item-insights",
			registrations: "registrations",
			forecast: "forecast",
			forecastPromotion: "forecast-access",
		},
	},
	// TODO: this would be the marketing page for accounts that do not have the feature flag enabled, but the page is not implemented yet
	returnsPromotion: {
		path: "returns-access",
	},
	appStore: {
		path: "app-store",
		sub: { all: "all", app: "app", recipe: "recipe", category: "category" },
	},
	settings: {
		path: "settings",
		sub: {
			notifications: "notifications",
			profile: "profile",
		},
	},
	notAuthorized: {
		path: "403",
	},
	notFound: {
		path: "404",
	},
	logout: {
		path: "logout",
	},
	signIn: {
		path: "signin",
	},
	publicLink: {
		path: "public-link",
	},
});

export const PAGE_URLS = {
	landing: () => `${AppConfig.getInstance().pageUrlPrefix}/`,
	service: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.service.path}`;
		return {
			index: base,
			accounts: `${base}/${PAGES.service.sub.accounts}`,
			apitokens: `${base}/${PAGES.service.sub.apitokens}`,
			users: `${base}/${PAGES.service.sub.users}`,
			gdpr: `${base}/${PAGES.service.sub.gdpr}`,
			authorizations: `${base}/${PAGES.service.sub.authorizations}`,
			whitelabeldomains: `${base}/${PAGES.service.sub.whitelabeldomains}`,
			voucherCodesList: `${base}/${PAGES.service.sub.voucherCodesList}`,
			voucherCodesUpload: `${base}/${PAGES.service.sub.voucherCodesUpload}`,
		};
	},
	trackings: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.trackings.path}`;
		return {
			index: base,
			trackings: `${base}/${PAGES.trackings.path}`,
			details: `${base}/${PAGES.trackings.sub.details}`,
			trackingsReturn: `${base}/${PAGES.trackings.sub.details}/${PAGES.trackings.sub.return}`,
			returnsOverview: `${base}/${PAGES.trackings.sub.returnsOverview}`,
			delivery: `${base}/${PAGES.trackings.sub.delivery}`,
			deliveryExceptions: `${base}/${PAGES.trackings.sub.deliveryExceptions}`,
			leadTimes: `${base}/${PAGES.trackings.sub.leadTimes}`,
			liveNetwork: `${base}/${PAGES.trackings.sub.liveNetwork}`,
			communicationsInsights: `${base}/${PAGES.trackings.sub.communicationsInsights}`,
			exceptions: `${base}/${PAGES.trackings.sub.exceptions}`,
			claims: `${base}/${PAGES.trackings.sub.claims}`,
			claimsPromotion: `${base}/${PAGES.trackings.sub.claimsPromotion}`,
			benchmarking: `${base}/${PAGES.trackings.sub.benchmarking}`,
			benchmarkingPromotion: `${base}/${PAGES.trackings.sub.benchmarkingPromotion}`,
		};
	},
	communications: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.communications.path}`;
		return {
			index: base,
			emails: `${base}/${PAGES.communications.sub.emails}`,
			sms: `${base}/${PAGES.communications.sub.sms}`,
			webhooks: `${base}/${PAGES.communications.sub.webhooks}`,
			emailDetails: `${base}/${PAGES.communications.sub.emailDetails}`,
			smsDetails: `${base}/${PAGES.communications.sub.smsDetails}`,
			webhookDetails: `${base}/${PAGES.communications.sub.webhookDetails}`,
			orderStatusPage: `${base}/${PAGES.communications.sub.orderStatusPage}`,
			feedback: `${base}/${PAGES.communications.sub.feedback}`,
			emotionAI: `${base}/${PAGES.communications.sub.emotionAI}`,
			emotionAIPromotion: `${base}/${PAGES.communications.sub.emotionAIPromotion}`,
			emailsInsights: `${base}/${PAGES.communications.sub.emailsInsights}`,
			smsInsights: `${base}/${PAGES.communications.sub.smsInsights}`,
			webhooksInsights: `${base}/${PAGES.communications.sub.webhooksInsights}`,
			benchmarking: `${base}/${PAGES.communications.sub.benchmarking}`,
			benchmarkingPromotion: `${base}/${PAGES.communications.sub.benchmarkingPromotion}`,
		};
	},
	experienceDesign: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.experienceDesign.path}`;
		return {
			index: base,
			journey: `${base}/${PAGES.experienceDesign.sub.journey}`,
			clients: `${base}/${PAGES.experienceDesign.sub.clients}`,
			layouts: `${base}/${PAGES.experienceDesign.sub.layouts}`,
			journeys: `${base}/${PAGES.experienceDesign.sub.journeys}`,
			flowCharts: (journeyId: string) =>
				`${base}/${PAGES.experienceDesign.sub.journeys}/${journeyId}/${PAGES.experienceDesign.sub.flowVisualize}`,
			flowChart: (journeyId: string, flowChartId: string) =>
				`${base}/${PAGES.experienceDesign.sub.journeys}/${journeyId}/${PAGES.experienceDesign.sub.flowVisualize}/${flowChartId}`,
			messageTypes: `${base}/${PAGES.experienceDesign.sub.messageTypes}`,
			messages: `${base}/${PAGES.experienceDesign.sub.messages}`,
			partials: `${base}/${PAGES.experienceDesign.sub.partials}`,
			experiments: `${base}/${PAGES.experienceDesign.sub.experiments}`,
			experimentsMessages: `${base}/${PAGES.experienceDesign.sub.experimentsMessages}`,
			triggers: `${base}/${PAGES.experienceDesign.sub.triggers}`,

			audiences: `${base}/${PAGES.experienceDesign.sub.audiences}`,
			campaigns: `${base}/${PAGES.experienceDesign.sub.campaigns}`,
			editCampaign: (campaignId: string) =>
				`${base}/${PAGES.experienceDesign.sub.campaigns}/${campaignId}`,
			analytics: `${base}/${PAGES.experienceDesign.sub.campaignsAnalytics}`,
			campaignPreview: `${base}/${PAGES.experienceDesign.sub.campaignsPreview}`,

			surveys: `${base}/${PAGES.experienceDesign.sub.surveys}`,
			surveysDashboard: `${base}/${PAGES.experienceDesign.sub.surveysDashboard}`,
			surveyResponses: `${base}/${PAGES.experienceDesign.sub.surveyResponses}`,
			journeyPromotion: `${base}/${PAGES.experienceDesign.sub.journeyPromotion}`,
		};
	},
	promise: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.promise.path}`;
		return {
			index: base,
			carrierLevel: `${base}/${PAGES.promise.sub.carrierLevel}`,
			warehouses: `${base}/${PAGES.promise.sub.warehouses}`,
			deliveryMethods: `${base}/${PAGES.promise.sub.deliveryMethods}`,
			deliveryPredictionService: `${base}/${PAGES.promise.sub.deliveryPredictionService}`,
			promiseAnalytics: `${base}/${PAGES.promise.sub.promiseAnalytics}`,
		};
	},
	returns: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.returns.path}`;
		return {
			index: base,
			approvals: `${base}/${PAGES.returns.sub.approvals}`,
			portals: `${base}/${PAGES.returns.sub.portals}`,
			translations: `${base}/${PAGES.returns.sub.translations}`,
			publish: `${base}/${PAGES.returns.sub.publish}`,
			orderApis: `${base}/${PAGES.returns.sub.orderApis}`,
			courierApis: `${base}/${PAGES.returns.sub.courierApis}`,
			returnReasons: `${base}/${PAGES.returns.sub.returnReasons}`,
			returnsCompensationMethods: `${base}/${PAGES.returns.sub.returnsCompensationMethods}`,
			returnReasonsV2: `${base}/${PAGES.returns.sub.returnReasonsV2}`,
			returnReasonsV2New: `${base}/${PAGES.returns.sub.returnReasonsV2}/${PAGES.new}`,
			returnReasonsV2Edit: (id: number) =>
				`${base}/${PAGES.returns.sub.returnReasonsV2}/${PAGES.edit}/${id}`,
			returnEligibility: `${base}/${PAGES.returns.sub.returnEligibility}`,
			articleEligibility: `${base}/${PAGES.returns.sub.articleEligibility}`,
			carrierAvailability: `${base}/${PAGES.returns.sub.carrierAvailability}`,
			networkMonitoring: `${base}/${PAGES.returns.sub.networkMonitoring}`,
			shopifyReturns: `${base}/${PAGES.returns.sub.shopifyReturns}`,
			shopifyProductReturns: `${base}/${PAGES.returns.sub.shopifyProductReturns}`,
			itemInsights: `${base}/${PAGES.returns.sub.itemInsights}`,
			registrations: `${base}/${PAGES.returns.sub.registrations}`,
			forecast: `${base}/${PAGES.returns.sub.forecast}`,
			forecastPromotion: `${base}/${PAGES.returns.sub.forecastPromotion}`,
		};
	},
	returnsPromotion: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.returnsPromotion.path}`;
		return {
			index: base,
		};
	},
	appStore: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.appStore.path}`;
		return {
			index: base,
			all: `${base}/${PAGES.appStore.sub.all}`,
			app: (appId: string) =>
				`${base}/${PAGES.appStore.sub.app}/${appId}`,
			recipe: (recipeId: string) =>
				`${base}/${PAGES.appStore.sub.recipe}/${recipeId}`,
			category: (tagFilter: string) =>
				`${base}/${PAGES.appStore.sub.category}/${tagFilter}`,
		};
	},
	settings: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.settings.path}`;
		return {
			index: base,
			profile: `${base}/${PAGES.settings.sub.profile}`,
			notifications: `${base}/${PAGES.settings.sub.notifications}`,
		};
	},
	notAuthorized: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.notAuthorized.path}`;
		return {
			index: base,
		};
	},
	notFound: () => {
		const base = `${AppConfig.getInstance().pageUrlPrefix}/${PAGES.notFound.path}`;
		return {
			index: base,
		};
	},
	logout: {
		path: `/${PAGES.logout.path}`,
	},
	signIn: {
		path: `/${PAGES.signIn.path}`,
	},
	publicLink: {
		path: `/${PAGES.publicLink.path}`,
	},
	howItWorks: {
		path: "https://how.parcellab.works/docs/",
	},
	extended: {
		path: "https://extended.parcellab.com/",
	},
	orderStatusPage: {
		path: "https://www.versand-status.de/v5.html",
	},
	googleMap: {
		path: "https://www.google.de/maps/place/",
	},
	legalNotice: {
		path: "https://parcellab.com/legal-notice/",
	},
	privacyPolicy: {
		path: "https://parcellab.com/privacy-policy/",
	},
	returnsPortalStaging: {
		path: "https://staging-returns.parcellab.com/preview/",
	},
};
export const getPageUrls = memoize(
	(accountId: number) => {
		AppConfig.setPageUrlPrefix(accountId);

		return PAGE_URLS;
	},
	(accountId: number) => `with-account-${accountId}`,
);
