import { type QueryClient, queryOptions } from "@tanstack/react-query";

import { redirect } from "react-router";
import type { ApiError } from "src/types/ApiError";
import {
	analyticsSurveyListOptions,
	analyticsSurveyResultsListOptions,
	analyticsSurveyRetrieveOptions,
} from "src/types/_generated/@tanstack/react-query.gen";

export const surveyAnalyticsLoader = (qc: QueryClient, surveyId?: string) => {
	try {
		return qc.ensureQueryData(
			analyticsSurveyRetrieveOptions({
				path: { id: surveyId as string },
			}),
		);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const surveyAnalyticsListLoader = (
	qc: QueryClient,
	accountIdParam?: string,
) => {
	try {
		if (!accountIdParam) {
			throw new Error("No account id");
		}
		return qc.ensureQueryData(
			queryOptions(
				analyticsSurveyListOptions({
					query: { account: [Number.parseInt(accountIdParam, 10)] },
				}),
			),
		);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};

export const surveyResultLoader = (
	qc: QueryClient,
	accountIdParam?: string,
	surveyId?: string,
) => {
	try {
		if (!surveyId) {
			return redirect("/404");
		}
		if (!accountIdParam) {
			throw new Error("No account id");
		}
		const qoSurveyDetails = queryOptions(
			analyticsSurveyRetrieveOptions({ path: { id: surveyId } }),
		);
		const qoSurveyResult = queryOptions(
			analyticsSurveyResultsListOptions({
				path: { id: surveyId },
				query: { account: [Number.parseInt(accountIdParam, 10)] }, // TODO: Do we need to add the accountId in the query?
			}),
		);

		return Promise.all([
			qc.ensureQueryData(qoSurveyDetails),
			qc.ensureQueryData(qoSurveyResult),
		]);
	} catch (err) {
		const error = err as ApiError;

		if (error.status === 404) return redirect("/404");

		throw error;
	}
};
