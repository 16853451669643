import {
	type ComponentProps,
	type ReactNode,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import animatedParcel from "src/assets/icons/animated-parcel.webp";
import {
	type FeedbackImageVariantsProps,
	type FeedbackVariantsProps,
	feedbackImageVariants,
	feedbackVariants,
} from "src/components/utils/feedback.variants";
import { cn } from "src/utils/tailwind";

type WithLoadingStateProps = ComponentProps<"div"> &
	FeedbackVariantsProps &
	FeedbackImageVariantsProps;

type ReactNodeWithProps = (props: { variant?: string | null }) => ReactNode;

const loadingStates = [
	"loadingStates.dataProcessing",
	"loadingStates.dataTransit",
	"loadingStates.dataDelivery",
];

function withLoadingState(WrappedComponent?: ReactNodeWithProps) {
	return function WrappedWithLoadingState({
		variant = "fullScreen",
		className,
		...others
	}: WithLoadingStateProps) {
		if (WrappedComponent)
			return (
				<div
					data-testid="loading-state"
					className={cn(feedbackVariants({ variant }), className)}
					{...others}
				>
					<img
						src={animatedParcel}
						className={feedbackImageVariants({ variant })}
						alt=""
					/>
					{WrappedComponent && <WrappedComponent variant={variant} />}
				</div>
			);
	};
}

function LoadingState() {
	const { t } = useTranslation();
	const [textIndex, setTextIndex] = useState(0);
	useEffect(() => {
		const intervalId = setInterval(() => {
			setTextIndex((prev) => {
				if (prev < loadingStates.length - 1) return prev + 1;
				return prev;
			});
		}, 800);
		return () => clearInterval(intervalId);
	}, []);
	return (
		<span className="text-base text-slate-400">
			{t(loadingStates[textIndex])}...
		</span>
	);
}

export const LoadingScreen = withLoadingState(LoadingState);
export const SimpleLoadingScreen = withLoadingState(() => null);
