import { useEffect, useRef } from "react";
import { useGetNextUrlParam } from "src/hooks/use-next-url";
import { useOidcLogin } from "src/services/oidc-exporter";

export function Signin() {
	const nextUrl = useGetNextUrlParam();
	const login = useOidcLogin(nextUrl);
	const loginInvokedRef = useRef(false);

	useEffect(() => {
		if (!loginInvokedRef.current) {
			loginInvokedRef.current = true;
			void login();
		}
	}, [login]);

	return <div />;
}
