import * as Sentry from "@sentry/react";
import { HTTPError } from "ky";
import { ApiError } from "src/types/ApiError";
import type { ErrorStateType } from "src/types/error";

const errorMap = new Map([
	[400, "400"],
	[401, "401"],
	[403, "403"],
	[404, "404"],
	[500, "500"],
	[502, "502"],
	[503, "503"],
]);

function getErrorState(status?: number) {
	return status ? errorMap.get(status) || "generic" : "generic";
}

export function extractStatusFromErrors(error?: ErrorStateType): string {
	try {
		let errorList: Error[] = [];
		if (Array.isArray(error)) {
			errorList = error as Error[];
		} else if (error) {
			errorList = [error];
		}
		let highestStatus = 0;
		for (const err of errorList) {
			const status =
				err instanceof HTTPError
					? err.response?.status
					: err instanceof ApiError
						? err.status
						: undefined;
			if (status && status > highestStatus) {
				highestStatus = status;
			}
		}
		return getErrorState(highestStatus);
	} catch (err) {
		Sentry.captureException(err);
		return "generic";
	}
}
