import {
	each,
	isBoolean,
	isEmpty,
	isObject,
	isPlainObject,
	omitBy,
} from "lodash";
import type { JsonObject, JsonPrimitive, JsonValue } from "type-fest";

export const omitDeepBy = (
	object: Parameters<typeof omitBy>[0],
	fn: Parameters<typeof omitBy>[1],
) => {
	if (Array.isArray(object)) return object as never;

	const r = omitBy(object, fn);

	each(r, (val, key) => {
		if (isPlainObject(val))
			(r as Record<string, unknown>)[key] = omitDeepBy(val, fn);
	});

	return r;
};

type FlattenObjectOptions = {
	prefix?: string;
	separator?: string;
	/** When enabled, it iterates through only plain object not arrays */
	onlyPlainObject?: boolean;
};

export const flattenObject = (
	object: JsonObject,
	options: FlattenObjectOptions = {},
): Record<string, JsonPrimitive> => {
	const { prefix = "", separator = ".", onlyPlainObject = false } = options;

	const isObj = (obj: JsonPrimitive | JsonValue) => {
		if (onlyPlainObject) {
			return isPlainObject(obj);
		}
		return typeof obj === "object";
	};

	return Object.keys(object).reduce((acc, k) => {
		const pre = prefix.length > 0 ? `${prefix}${separator}` : "";

		if (isObj(object[k]) && object[k] !== null) {
			Object.assign(
				acc,
				flattenObject(object[k] as never, {
					...options,
					prefix: pre + k,
				}),
			);
		} else {
			// @ts-expect-error disable any warning
			acc[pre + k] = object[k];
		}
		return acc;
	}, {});
};

export function hasAnyValue(values: JsonObject): boolean {
	if (!values) return false;

	return Object.values(values).some((value) => {
		if (isBoolean(value)) {
			return true;
		}

		if (isPlainObject(value)) {
			return Object.values(value as JsonObject).some((val) =>
				hasAnyValue(val as JsonObject),
			);
		}

		if (isEmpty(value)) return false;

		return true;
	});
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function immutableMergeDeep(...objects: JsonObject[]) {
	return objects.reduce((prev, obj) => {
		Object.keys(obj || {}).forEach((key) => {
			const pVal = prev[key];
			const oVal = obj[key];

			if (Array.isArray(pVal) && Array.isArray(oVal)) {
				prev[key] = pVal.concat(...oVal);
			} else if (isObject(pVal) && isObject(oVal)) {
				prev[key] = immutableMergeDeep(
					pVal as JsonObject,
					oVal as JsonObject,
				);
			} else {
				prev[key] = oVal;
			}
		});

		return prev;
	}, {});
}
