import {
	ClockIcon,
	ExclamationCircleIcon,
	XCircleIcon,
} from "@heroicons/react/24/outline";
import { cva } from "class-variance-authority";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { DataStateBoundary } from "src/components/DataStateBoundary";
import { SimpleLoadingScreen } from "src/components/LoadingScreen";
import { Button } from "src/components/ui/Button";
import {
	type FeedbackVariantsProps,
	feedbackVariants,
} from "src/components/utils/feedback.variants";
import { useOnTimeout } from "src/hooks/use-on-timeout";
import { useOidcLogin } from "src/services/oidc-exporter";
import { cn } from "src/utils/tailwind";

type EventName =
	| "session-lost"
	| "session-expire"
	| "auth-error"
	| "auth-success"
	| "auth-in-progress";

type OidcErrorProps = ComponentProps<"div"> &
	FeedbackVariantsProps & {
		eventTranslationKey: string;
		eventName: EventName;
		icon: typeof ExclamationCircleIcon;
		loginBtn?: boolean;
	};

export const oidcErrorVariants = cva(["h-20 w-20"], {
	variants: {
		variant: {
			"session-lost": "text-yellow-500",
			"session-expire": "text-gray-500",
			"auth-error": "text-red-500",
			"auth-success": "text-green-500",
			"auth-in-progress": "text-gray-500",
		},
	},
	defaultVariants: {
		variant: "auth-error",
	},
});

export function OidcError({
	variant = "fit",
	eventTranslationKey,
	eventName,
	icon: Icon,
	loginBtn = false,
	...others
}: OidcErrorProps) {
	const login = useOidcLogin();
	const { t } = useTranslation();

	return (
		<div
			data-testid="session-lost"
			className={cn(
				feedbackVariants({ variant }),
				"h-screen border-transparent bg-pl-light-slate",
			)}
			{...others}
		>
			<Icon className={oidcErrorVariants({ variant: eventName })} />
			<p className="text-center m-4">{t(eventTranslationKey)}</p>
			{loginBtn && (
				<Button
					variant="primary"
					onClick={() => login()}
					data-testid="login-button"
				>
					{t("login")}
				</Button>
			)}
		</div>
	);
}

export function SessionLost() {
	return (
		<DataStateBoundary>
			<OidcError
				eventTranslationKey="oidc.sessionLost"
				eventName="session-lost"
				icon={ExclamationCircleIcon}
				loginBtn
			/>
		</DataStateBoundary>
	);
}

export function SessionExpired() {
	return (
		<DataStateBoundary>
			<OidcError
				eventTranslationKey="oidc.sessionExpired"
				eventName="session-expire"
				icon={ClockIcon}
				loginBtn
			/>
		</DataStateBoundary>
	);
}

export function AuthenticationError() {
	return (
		<DataStateBoundary>
			<OidcError
				eventTranslationKey="oidc.authenticationError"
				eventName="auth-error"
				icon={XCircleIcon}
				loginBtn
			/>
		</DataStateBoundary>
	);
}

export function AuthenticationLoading() {
	return (
		<SimpleLoadingScreen
			className="h-screen border-transparent bg-pl-light-slate"
			variant="fit"
		/>
	);
}

export function AuthenticationSuccess() {
	useOnTimeout(() => {
		// authentication success should redirect you to the page.
		// but the redirection fails sometimes (on safari).
		// make sure to reload the page on certain timeout. That should trigger redirect user.
		window.location.reload();
	});

	return <AuthenticationLoading />;
}
