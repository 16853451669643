import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NuqsAdapter } from "nuqs/adapters/react-router/v7";
import { useEffect, useMemo } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ErrorState } from "src/components/ErrorState";
import { SimpleLoadingScreen } from "src/components/LoadingScreen/LoadingScreen";
import { Logout } from "src/components/Logout";
import { Flex } from "src/components/ui/Flex";
import { MATOMO_CONFIGS } from "src/configs/matomo";
import { MatomoContext } from "src/contexts/matomo";
import { useHotjar } from "src/hooks/use-hotjar";
import { useLanguageFromUserSettings } from "src/locales/use-language-from-user-settings";
import { MatomoTracker } from "src/matomo/MatomoTracker";
import { setupMsw } from "src/mock-service-worker/setup";
import { MainRouter } from "src/router/main-router";
import {
	useIsOidcUserLoading,
	useOidc,
	useOidcIdToken,
	useOidcUser,
} from "src/services/oidc-exporter";
import { getSettings, isTest } from "src/settings";
import { client } from "src/types/_generated/client.gen";
import { initializeLocaleConfig } from "src/utils/dayjs";

function App() {
	void setupMsw();
	const { oidcUser } = useOidcUser();
	const isUserLoading = useIsOidcUserLoading();
	const { t } = useTranslation(undefined, {
		keyPrefix: "oidc.noSessionAvailable",
	});
	const { isAuthenticated } = useOidc();
	const { idToken } = useOidcIdToken() as { idToken: string | undefined };
	const settings = getSettings();

	// if the user is authenticated and we got the token, we can update the request client accordingly
	if (idToken) {
		client.setConfig({
			headers: {
				Authorization: `Bearer ${idToken}`,
			},
			baseUrl: settings.productApi.URL,
		});
	}

	const { isLoading: isLanguageSettingsLoading } =
		useLanguageFromUserSettings({
			enabled: !!idToken && !!oidcUser,
		});

	const matomoContextValue = useMemo(
		() => ({
			matomoTracker: !isTest() ? new MatomoTracker(MATOMO_CONFIGS) : null,
		}),
		[],
	);
	useHotjar();

	useEffect(() => {
		if (!matomoContextValue?.matomoTracker?.mutationObserver) {
			matomoContextValue.matomoTracker?.trackPageView();
			matomoContextValue.matomoTracker?.trackEvents();

			initializeLocaleConfig().catch((error) =>
				console.error("failed to initiate locale config", error),
			);
		}

		const { email, sub } = oidcUser ?? {};
		if (email && sub) {
			const domain = email.split("@")?.[1] ?? "-";
			const userId = sub;
			matomoContextValue.matomoTracker?.trackUserId(
				`${userId}@${domain}`,
			);
		}
	}, [matomoContextValue.matomoTracker, oidcUser]);

	if (isLanguageSettingsLoading || isUserLoading) {
		return (
			<SimpleLoadingScreen
				className="h-screen border-transparent bg-pl-light-slate"
				variant="fit"
			/>
		);
	}

	/**
	 * If the user is unauthenticated they end up in sign in page within the main router
	 * If the user is authenticated but oidcUser client fails to load user or token we have to log them out manually
	 * Note: The case is reproduced when oidcUser fetch fails and gets unauthenticated response, however isAuthenticated=true and token is still valid within oidc client
	 */
	if (isAuthenticated && (!oidcUser || !idToken)) {
		return (
			<Flex dir="col" gap={4} align="center">
				<ErrorState title={t("title")} description={t("subTitle")} />
				<Logout />
			</Flex>
		);
	}
	return (
		<MatomoContext.Provider value={matomoContextValue}>
			<NuqsAdapter>
				<MainRouter isAuthenticated={isAuthenticated} />
				<Toaster />
				<ReactQueryDevtools initialIsOpen={false} />
			</NuqsAdapter>
		</MatomoContext.Provider>
	);
}

export { App };
