import { useEffect } from "react";
import { useOidcLogout } from "src/services/oidc-exporter";

export function Logout() {
	const logout = useOidcLogout();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		void logout();
	}, []);

	return <div className="p-4">Logging out...</div>;
}
