import * as Sentry from "@sentry/react";
import type { HTTPError } from "ky";
import { compact, first, isPlainObject, isString } from "lodash";
import { getPageUrls } from "src/configs/pages-urls.ts";
import { Model } from "src/contexts/authorization-context.tsx";
import type { ApiError } from "src/types/ApiError";
import type { MessageDetail, ReturnsPortal } from "src/types/_generated";
import type { ErrorData, ErrorStateType } from "src/types/error";

export const getModelToErrorMapping = (
	accountId: number,
	data?: ErrorData,
) => ({
	[Model.RETURNS_RETURNSPORTAL]: {
		"At least one return reason needs to be configured": {
			key: "form.errors.returnsReasonRequired",
			link: `${getPageUrls(accountId).returns().returnReasons}/edit/${(data as ReturnsPortal)?.returnsRules}`,
		},
		"Returns Translation is invalid: CourierLabelBox.HEADING_qr-code missing":
			{
				key: "form.errors.returnsTranslationLabelBox",
				link: (data as ReturnsPortal)?.returnsTranslation
					? `${getPageUrls(accountId).returns().translations}/edit/${(data as ReturnsPortal)?.returnsTranslation}`
					: getPageUrls(accountId).returns().translations,
			},
		"Returns Translation is invalid": {
			key: "form.errors.returnsTranslation",
			link: (data as ReturnsPortal)?.returnsTranslation
				? `${getPageUrls(accountId).returns().translations}/edit/${(data as ReturnsPortal)?.returnsTranslation}`
				: getPageUrls(accountId).returns().translations,
		},
		"Returns portal must have a default non-warranty return period": {
			key: "form.errors.returnsPeriod",
			link: getPageUrls(accountId).returns().returnEligibility,
		},
	},
	[Model.JOURNEY_MESSAGE]: {
		"Layout must be published before publishing the message": {
			key: "form.errors.journeyLayoutNotPublished",
			link: `${getPageUrls(accountId).experienceDesign().layouts}/${(data as MessageDetail)?.layout?.id}`,
		},
		"Layout is not complete": {
			key: "form.errors.journeyLayoutNotComplete",
		},
	},
});

export function handleApiError(
	accountId: number,
	errorData: { error: unknown; model: Model; data?: ErrorData },
) {
	const modelToErrorMapping = getModelToErrorMapping(
		accountId,
		errorData.data,
	);
	const errorMsg =
		(errorData.error as { error: string })?.error ||
		(errorData.error as { body: { error: string } })?.body?.error ||
		null;
	if (errorMsg?.includes("['") && errorMsg.includes("']")) {
		const msg = errorMsg.replace("['", "").replace("']", "");
		const modelErrors =
			modelToErrorMapping[
				errorData.model as keyof typeof modelToErrorMapping
			];
		for (const key in modelErrors) {
			if (msg.startsWith(key)) {
				return modelErrors[key as keyof typeof modelErrors];
			}
		}
		return { key: msg, link: undefined };
	}
	return null;
}

export const throwWithSentry: typeof Sentry.captureException = (
	error,
	opts,
) => {
	Sentry.captureException(error, opts ?? { level: "info" });
	throw error;
};

export function getDetailsTextFromError(
	err: Error & { body?: { error?: string; detail?: string } },
) {
	return (err?.body?.error || err?.body?.detail || "").replaceAll(
		/\['|']|\[|]|"|'/g,
		"",
	);
}

export function extractErrorMessage(
	err: Error & { error?: string; detail?: string },
) {
	return (err?.error || err?.detail || "").replaceAll(/\['|']|\[|]|"|'/g, "");
}

export function apiErrorResToText(
	err: Error & { body?: { [key: string]: string } },
): string {
	if (!err.body || typeof err.body !== "object") return err.message || "";
	return Object.values(err.body)
		.map((v) => (Array.isArray(v) ? v.join(", ") : v))
		.join(", ");
}

export function extractErrorCode(error: unknown): string {
	if (typeof error === "object" && error !== null && "code" in error) {
		const errorCode = error.code;

		if (Array.isArray(errorCode)) {
			return errorCode.join(", ");
		}
		return String(errorCode);
	}
	return "An unknown error occurred.";
}

export function hasError(error: ErrorStateType | undefined) {
	if (Array.isArray(error)) {
		const validErrors = compact(error);
		return validErrors.length > 0;
	}

	return !!error;
}

export function handleServerError(
	error: HTTPError | ApiError | Error,
	info?: unknown,
) {
	console.error({ serverError: error, info });

	if (
		(error as HTTPError)?.response &&
		(error as HTTPError).response.status >= 500
	) {
		Sentry.captureException(error);
	} else if (
		(error as HTTPError)?.response &&
		(error as HTTPError).response.status === 403
	) {
		Sentry.captureException(error);
	} else if (
		(error as ApiError)?.status &&
		(error as ApiError).status >= 500
	) {
		Sentry.captureException(error);
	} else if (
		(error as ApiError)?.status &&
		(error as ApiError).status === 403
	) {
		Sentry.captureException(error);
	} else if (
		error &&
		isPlainObject(error) &&
		isString(first(Object.values(error))) &&
		/You do not have permission/i.test(first(Object.values(error)))
	) {
		Sentry.captureException(error, {
			extra: info as Record<string, unknown>,
		});
	}
}
