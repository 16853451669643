import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";
import { AccountFeatureFlags } from "src/types/portal-features.type";

type AppStoreAuthorization = {
	appStore: GenericAuthorizationMap<"index" | "app" | "recipe">;
};

export const appStoreAuthorization: AppStoreAuthorization = {
	appStore: {
		index: {
			can: {
				allOf: [
					[Action.view, Model.SERVICE_RECIPEMODEL],
					[Action.view, Model.APPS_CUSTOMAPP],
					[Action.useAccountFeature, AccountFeatureFlags.hasAppStore],
				],
			},
		},
		app: {
			can: {
				allOf: [
					[Action.view, Model.APPS_CUSTOMAPP],
					[Action.useAccountFeature, AccountFeatureFlags.hasAppStore],
				],
			},
		},
		recipe: {
			can: {
				allOf: [
					[Action.view, Model.SERVICE_RECIPEMODEL],
					[Action.useAccountFeature, AccountFeatureFlags.hasAppStore],
				],
			},
		},
	},
};
