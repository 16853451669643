import {
	Action,
	type GenericAuthorizationMap,
	Model,
} from "src/contexts/authorization-context";
import {
	AccountFeatureFlags,
	UserFeatureFlags,
} from "src/types/portal-features.type";

type ExperienceDesignAuthorization = {
	experienceDesign: GenericAuthorizationMap<
		| "index"
		| "journey"
		| "journeyPromotion"
		| "journeyExperiments"
		| "clients"
		| "flows"
		| "layouts"
		| "messages"
		| "partials"
		| "campaignsGroup"
		| "audiences"
		| "customerSegmentation"
		| "campaigns"
		| "analytics"
		| "surveys"
	>;
	advancedMode: GenericAuthorizationMap<"journey">;
	smartSurvey: GenericAuthorizationMap<"surveys">;
	journeyPublish: GenericAuthorizationMap<"message" | "layouts">;
	campaignsContentBlock: GenericAuthorizationMap<"returnThreshold">;
};

export const experienceDesignAuthorization: ExperienceDesignAuthorization = {
	experienceDesign: {
		index: {
			can: {
				oneOf: [
					[Action.view, Model.CONFIG_CLIENT],
					[Action.view, Model.CAMPAIGN_CAMPAIGN],
					[Action.view, Model.ANALYTICS_SURVEY],
				],
			},
		},
		journey: {
			can: {
				oneOf: [
					[Action.view, Model.CONFIG_CLIENT],
					[Action.view, Model.JOURNEY_JOURNEYCONFIGURATION],
					[Action.view, Model.JOURNEY_LAYOUT],
					[Action.view, Model.JOURNEY_MESSAGE],
					[Action.view, Model.JOURNEY_PARTIAL],
				],
				allOf: [
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		journeyExperiments: {
			can: {
				oneOf: [
					[Action.view, Model.CONFIG_CLIENT],
					[Action.view, Model.JOURNEY_JOURNEYCONFIGURATION],
					[Action.view, Model.JOURNEY_LAYOUT],
					[Action.view, Model.JOURNEY_MESSAGE],
					[Action.view, Model.JOURNEY_PARTIAL],
				],
				allOf: [
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPreviewFeatures,
					],
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		journeyPromotion: {
			cannot: {
				allOf: [
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		clients: {
			can: {
				allOf: [
					[Action.view, Model.CONFIG_CLIENT],
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		flows: {
			can: {
				allOf: [
					[Action.view, Model.JOURNEY_JOURNEYCONFIGURATION],
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		layouts: {
			can: {
				allOf: [
					[Action.view, Model.JOURNEY_LAYOUT],
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		messages: {
			can: {
				allOf: [
					[Action.view, Model.JOURNEY_MESSAGE],
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		partials: {
			can: {
				allOf: [
					[Action.view, Model.JOURNEY_PARTIAL],
					[Action.useAccountFeature, AccountFeatureFlags.hasJourney],
				],
			},
		},
		campaignsGroup: {
			can: {
				oneOf: [
					[Action.view, Model.CAMPAIGN_AUDIENCE],
					[Action.view, Model.CAMPAIGN_CAMPAIGN],
					[Action.view, Model.CONFIG_CUSTOMERSEGMENTCONFIG],
				],
			},
		},
		audiences: {
			can: {
				allOf: [[Action.view, Model.CAMPAIGN_AUDIENCE]],
			},
		},
		customerSegmentation: {
			can: {
				allOf: [
					[Action.view, Model.CONFIG_CUSTOMERSEGMENTCONFIG],
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasCustomerSegmentation,
					],
				],
			},
		},
		campaigns: {
			can: {
				allOf: [[Action.view, Model.CAMPAIGN_CAMPAIGN]],
			},
		},
		analytics: {
			can: {
				allOf: [[Action.view, Model.PORTAL_REPORT]],
			},
		},
		surveys: {
			can: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasSmartSurvey,
					],
					[Action.view, Model.ANALYTICS_SURVEY],
				],
			},
		},
	},
	advancedMode: {
		journey: {
			can: {
				oneOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasJourneyAdvancedMode,
					],
					[
						Action.useUserFeature,
						UserFeatureFlags.hasPreviewFeatures,
					],
				],
			},
		},
	},
	smartSurvey: {
		surveys: {
			can: {
				allOf: [
					[
						Action.useAccountFeature,
						AccountFeatureFlags.hasSmartSurvey,
					],
				],
			},
		},
	},
	journeyPublish: {
		message: {
			can: {
				allOf: [[Action.publish, Model.JOURNEY_MESSAGE]],
			},
		},
		layouts: {
			can: {
				allOf: [[Action.publish, Model.JOURNEY_LAYOUT]],
			},
		},
	},
	campaignsContentBlock: {
		returnThreshold: {
			can: {
				allOf: [
					[Action.useAccountFeature, AccountFeatureFlags.hasReturns],
				],
			},
		},
	},
};
