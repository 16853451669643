import { useEffect, useRef } from "react";

export function useOnTimeout(fn: () => void, timeout = 3000) {
	const initializedRef = useRef(true);

	// biome-ignore lint/correctness/useExhaustiveDependencies:
	useEffect(() => {
		setTimeout(() => {
			if (initializedRef.current) {
				fn();
			}
			initializedRef.current = false;
		}, timeout);
	}, []);

	useEffect(() => {
		initializedRef.current = true;

		return function resetRef() {
			initializedRef.current = false;
		};
	}, []);
}
