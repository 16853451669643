/* eslint-disable no-console */
/* eslint-disable promise/no-promise-in-callback */
import { useEffect, useState } from "react";
import { parseFormError } from "src/components/form/utils";

export function useErrorMessage(error: Error | undefined): string | undefined {
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	useEffect(() => {
		parseFormError(error as never)
			.then(({ message }) => {
				setErrorMessage(message);
				return message;
			})
			.catch((err: Error) => {
				// biome-ignore lint/suspicious/noConsole: <explanation>
				console.log({ "route-error": err });
				return err;
			});
	}, [error]);

	return errorMessage;
}
