import { queryOptions } from "@tanstack/react-query";
import type {
	CheckpointsRequestType,
	TrackingDetailsRequestType,
	TrackingsSearchOptions,
} from "src/module--trackings/types/tracking.type";
import {
	getArticleList,
	getCheckpointsList,
	getNotifications,
	getSharedTrackingDetails,
	getTrackingDetails,
	getTrackings,
} from "src/services/api";
import type { GetArticleListParams } from "src/types/api.types";
import type { NotificationsRequestType } from "src/types/notifications.type";

export const trackingArticleQueries = {
	all: () => ["articles"],
	list: (params: GetArticleListParams) =>
		queryOptions({
			queryKey: ["getArticleList", JSON.stringify(params)],
			queryFn: () => getArticleList(params),
			enabled: !!params.headers || !!params.user,
			placeholderData: (prev) =>
				prev ? { ...prev, results: [] } : undefined,
		}),
};

export const trackingsQueries = {
	allKey: () => ["trackings"],
	listKey: () => [...trackingsQueries.allKey(), "list"],
	list: (
		options: TrackingsSearchOptions,
		headers: Headers,
		opts: { enabled?: boolean } = {},
	) =>
		queryOptions({
			queryKey: [
				...trackingsQueries.listKey(),
				options,
				headers.get("user"),
			],
			queryFn: () =>
				getTrackings({
					headers,
					query: options as never,
				}),
			...opts,
		}),
	detailKey: () => [...trackingsQueries.allKey(), "detail"],
	detail: (query: TrackingDetailsRequestType, headers: Headers) =>
		queryOptions({
			queryKey: [
				...trackingsQueries.detailKey(),
				query,
				headers.get("user"),
			] as const,
			queryFn: () => getTrackingDetails({ query, headers }),
			enabled: !!query.trackingId,
		}),
	sharedTrackingDetailKey: () => [
		...trackingsQueries.allKey(),
		"shared-detail",
	],
	sharedTrackingDetail: (
		query: TrackingDetailsRequestType,
		headers: Headers,
	) =>
		queryOptions({
			queryKey: [
				...trackingsQueries.sharedTrackingDetailKey(),
				query,
				headers.get("user"),
			] as const,
			queryFn: () => getSharedTrackingDetails({ query, headers }),
			enabled: !!query.token,
		}),
};

export const checkpointsQueries = {
	listKey: () => ["checkpoints"],
	list: (query: CheckpointsRequestType, headers: Headers) =>
		queryOptions({
			queryKey: [
				...checkpointsQueries.listKey(),
				query,
				headers.get("user"),
			],
			queryFn: () => {
				const { trackingId, orderNo, ...rest } = query;
				return getCheckpointsList({
					headers,
					query: {
						...rest,
						showReturns: true,
						showMissingMatching: true,
						skip_lr: true,
						...(orderNo ? { orderNo } : { trackingId }),
					},
				});
			},

			enabled:
				!!headers &&
				(!!query.orderNo || !!query.trackingId) &&
				!!query.u,
		}),
};

export const notificationsQueries = {
	listKey: () => ["notifications"],
	list: (query: NotificationsRequestType, headers: Headers) =>
		queryOptions({
			queryKey: [
				...notificationsQueries.listKey(),
				JSON.stringify(query),
				headers.get("user"),
			],
			queryFn: () => getNotifications({ query, headers }),
		}),
};
