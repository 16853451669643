import * as i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { customLanguageDetector } from "src/locales/custom-language-detector";
import {
	defaultLanguage,
	defaultNameSpace,
	fetchResources,
} from "src/locales/language-config";

declare module "i18next" {
	interface CustomTypeOptions {
		returnNull: false;
	}
}

export async function initI18next() {
	const resources = await fetchResources();

	return i18next.use(customLanguageDetector).use(initReactI18next).init({
		returnNull: false,
		fallbackLng: defaultLanguage,
		defaultNS: defaultNameSpace,
		fallbackNS: defaultNameSpace,
		load: "currentOnly",
		nsSeparator: ".",
		resources,
	});
}
