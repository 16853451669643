import { type FormikValues, useFormikContext } from "formik";
import React, { useEffect } from "react";

type Props<T> = {
	onValuesChange: (values: T) => void;
};

export const FormValuesChangeListener = React.memo(
	<T extends FormikValues = never>({ onValuesChange }: Props<T>) => {
		const { values } = useFormikContext<T>();

		// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
		useEffect(() => {
			onValuesChange(values);
		}, [values]);

		return null;
	},
) as <T extends FormikValues = never>(props: Props<T>) => null;
