import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Feedback } from "src/components/Feedback";
import { FormSubmitButton } from "src/components/form/SubmitButton";
import { Button } from "src/components/ui/Button";

export type FormFeedbackProps = {
	onCancel?: (event?: React.MouseEvent) => void;
	onSubmit?: (event?: React.MouseEvent) => void;
	submitText?: string;
	submitDisabled?: boolean;
	submitHidden?: boolean;
	showCloseNotCancel?: boolean;
	cancelText?: string;
	submitButtonProps?: ComponentProps<typeof Button>;
} & ComponentProps<typeof Feedback>;

export function FormFeedback({
	onCancel,
	onSubmit,
	submitText: submitTextProp,
	submitDisabled,
	submitHidden,
	showCloseNotCancel,
	submitButtonProps,
	cancelText,
	...rest
}: FormFeedbackProps) {
	const { t } = useTranslation();

	const submitText = submitTextProp ?? t("confirm");

	return (
		<Feedback
			footerProps={{
				className: "rounded-b-lg",
			}}
			footer={[
				showCloseNotCancel ? (
					<Button
						type="button"
						variant="default"
						data-testid="close-button"
						aria-label={t("close")}
						key="close"
						onClick={onCancel}
					>
						{t("close")}
					</Button>
				) : (
					<Button
						type="button"
						variant="default"
						data-testid="cancel-button"
						aria-label={t("cancel")}
						key="cancel"
						onClick={onCancel}
					>
						{cancelText ?? t("cancel")}
					</Button>
				),
				!submitHidden && (
					<FormSubmitButton
						data-testid="confirm-button"
						key="submit"
						aria-label={submitText}
						disabled={submitDisabled}
						{...(onSubmit && { onClick: onSubmit })}
						{...submitButtonProps}
					>
						{submitText}
					</FormSubmitButton>
				),
			]}
			{...rest}
		/>
	);
}
