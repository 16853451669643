import { lazy } from "react";
import { type LoaderFunctionArgs, redirect } from "react-router";
import { experienceDesignAuthorization } from "src/configs/authorization/experience-design";
import { PAGES, PAGE_URLS } from "src/configs/pages-urls";
import { redirectsMapping } from "src/module--journey/config/router";
import {
	experimentsDetailsLoader,
	experimentsListLoader,
	experimentsNewLoader,
	journeyCreateLoader,
	journeyEditLoader,
	journeyFlowChartLoader,
	journeyFlowChartsLoader,
	journeyFlowsLoader,
	journeyNewFlowChartLoader,
	journeyNewTriggerLoader,
	journeyTriggerLoader,
} from "src/module--journey/journey.loaders";
import {
	generateRedirectsMapping,
	getRedirectRoutes,
} from "src/module--journey/utils/router";
import { authorizationLoader } from "src/router/common-loaders";
import { lazyLoad } from "src/router/lazyload";
import type { RouterConfig } from "src/router/types";

const ClientListPage = lazy(() =>
	import("src/module--journey/journey.lazy").then(
		(mod): { default: () => JSX.Element } => ({
			default: mod.ClientListPage,
		}),
	),
);

export function journeyRoutes(config: RouterConfig) {
	const { queryClient } = config;
	return [
		{
			path: PAGES.experienceDesign.sub.journeyPromotion,
			lazy: async () => {
				const { JourneyPromotionPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return {
					Component: JourneyPromotionPage,
				};
			},
		},
		{
			path: PAGES.experienceDesign.sub.journey,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.journey,
					[
						{
							redirectUrl:
								PAGE_URLS.experienceDesign().journeyPromotion,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journeyPromotion,
						},
					],
				)) && redirect(PAGE_URLS.experienceDesign().clients),
		},
		{
			path: PAGES.experienceDesign.sub.clients,
			element: lazyLoad(<ClientListPage />),
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.clients,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.clients}/new`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.clients,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { ClientCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: ClientCreatePage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.clients}/:id`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.clients,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { ClientEditPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: ClientEditPage };
			},
		},
		{
			path: PAGES.experienceDesign.sub.layouts,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.layouts,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { LayoutListPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: LayoutListPage };
			},
		},
		{
			path: PAGES.experienceDesign.sub.journeys,
			async lazy() {
				const { FlowsListPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: FlowsListPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) && journeyFlowsLoader(queryClient, Number(accountIdParam)),
		},
		{
			path: `${PAGES.experienceDesign.sub.journeys}/:journeyId`,
			async lazy() {
				const { JourneyEditPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: JourneyEditPage };
			},
			loader: async ({
				params: { accountId: accountIdParam, journeyId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				journeyEditLoader(
					queryClient,
					Number(journeyId),
					Number(accountIdParam),
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.journeys}/new`,
			async lazy() {
				const { JourneyCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: JourneyCreatePage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) && journeyCreateLoader(queryClient, Number(accountIdParam)),
		},
		{
			path: `${PAGES.experienceDesign.sub.journeys}/:journeyId/${PAGES.experienceDesign.sub.flowVisualize}`,
			async lazy() {
				const { FlowChartsPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return {
					Component: FlowChartsPage,
				};
			},
			loader: async ({
				params: { accountId: accountIdParam, journeyId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				journeyFlowChartsLoader(
					queryClient,
					Number(accountIdParam),
					Number(journeyId),
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.journeys}/:journeyId/${PAGES.experienceDesign.sub.flowVisualize}/new`,
			async lazy() {
				const { FlowChartNewPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return {
					Component: FlowChartNewPage,
				};
			},
			loader: async ({
				params: { accountId: accountIdParam, journeyId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				journeyNewFlowChartLoader(
					queryClient,
					Number(accountIdParam),
					Number(journeyId),
				),
			children: [
				{
					path: ":messageTypeId",
					async lazy() {
						const { FlowChartDetailsPage } = await import(
							"src/module--journey/journey.lazy"
						);
						return {
							Component: FlowChartDetailsPage,
						};
					},
				},
			],
		},
		{
			path: `${PAGES.experienceDesign.sub.journeys}/:journeyId/${PAGES.experienceDesign.sub.flowVisualize}/:flowChartId`,
			async lazy() {
				const { FlowChartPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return {
					Component: FlowChartPage,
				};
			},
			loader: async ({
				params: { accountId: accountIdParam, journeyId, flowChartId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				journeyFlowChartLoader(
					queryClient,
					Number(journeyId),
					Number(accountIdParam),
					Number(flowChartId),
				),
			children: [
				{
					path: ":messageTypeId",
					async lazy() {
						const { FlowChartDetailsPage } = await import(
							"src/module--journey/journey.lazy"
						);
						return {
							Component: FlowChartDetailsPage,
						};
					},
				},
			],
		},
		{
			path: `${PAGES.experienceDesign.sub.layouts}/new`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.layouts,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { LayoutCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: LayoutCreatePage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.layouts}/:id`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.layouts,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { LayoutEditPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: LayoutEditPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.layouts}/:id/history`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.layouts,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { LayoutRevisionPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: LayoutRevisionPage };
			},
		},
		{
			path: PAGES.experienceDesign.sub.messages,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.messages,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageTypeListPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageTypeListPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.triggers}/:triggerId`,
			async lazy() {
				const { TriggerDetailsPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: TriggerDetailsPage };
			},
			loader: async ({
				params: { accountId: accountIdParam, triggerId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				journeyTriggerLoader(
					queryClient,
					Number(triggerId),
					Number(accountIdParam),
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.triggers}/new`,
			async lazy() {
				const { TriggerDetailsCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: TriggerDetailsCreatePage };
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.flows,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				journeyNewTriggerLoader(
					queryClient,
					request,
					Number(accountIdParam),
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.messages}/new`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.messages,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageTypeCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageTypeCreatePage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.messages}/:id`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.messages,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageTypeDetailsPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageTypeDetailsPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.messages}/:id/new`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.messages,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageCreatePage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.messages}/:id/:messageId`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.messages,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageEditPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageEditPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.messages}/:id/:messageId/history`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.messages,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageRevisionPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageRevisionPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.partials}`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.partials,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { PartialListPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: PartialListPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.partials}/new`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.partials,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { PartialCreatePage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: PartialCreatePage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.partials}/:id/history`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.partials,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { PartialRevisionPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: PartialRevisionPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.partials}/:id`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.partials,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { PartialEditPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: PartialEditPage };
			},
		},
		{
			path: `${PAGES.experienceDesign.sub.experiments}`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign
						.journeyExperiments,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				)) &&
				experimentsListLoader(queryClient, Number(accountIdParam)),
			async lazy() {
				const { ExperimentsListPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: ExperimentsListPage };
			},
			children: [
				{
					path: "new",
					loader: async ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						(await authorizationLoader(
							{ ...config, accountIdParam },
							experienceDesignAuthorization.experienceDesign
								.journeyExperiments,
							[
								{
									redirectUrl:
										PAGE_URLS.experienceDesign().journey,
									abilityList:
										experienceDesignAuthorization
											.experienceDesign.journey,
								},
							],
						)) &&
						experimentsNewLoader(
							queryClient,
							Number(accountIdParam),
						),
					async lazy() {
						const { ExperimentNewPage } = await import(
							"src/module--journey/journey.lazy"
						);
						return { Component: ExperimentNewPage };
					},
				},
				{
					path: ":id",
					loader: async ({
						params: { accountId: accountIdParam, id },
					}: LoaderFunctionArgs) =>
						(await authorizationLoader(
							{ ...config, accountIdParam },
							experienceDesignAuthorization.experienceDesign
								.journeyExperiments,
							[
								{
									redirectUrl:
										PAGE_URLS.experienceDesign().journey,
									abilityList:
										experienceDesignAuthorization
											.experienceDesign.journey,
								},
							],
						)) &&
						experimentsDetailsLoader(
							queryClient,
							Number(id),
							Number(accountIdParam),
						),
					async lazy() {
						const { ExperimentEditPage } = await import(
							"src/module--journey/journey.lazy"
						);
						return { Component: ExperimentEditPage };
					},
				},
				{
					path: "result/:id",
					loader: async ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							experienceDesignAuthorization.experienceDesign
								.journeyExperiments,
							[
								{
									redirectUrl:
										PAGE_URLS.experienceDesign().journey,
									abilityList:
										experienceDesignAuthorization
											.experienceDesign.journey,
								},
							],
						),
					async lazy() {
						const { ExperimentResultPage } = await import(
							"src/module--journey/journey.lazy"
						);
						return { Component: ExperimentResultPage };
					},
				},
			],
		},
		{
			path: `${PAGES.experienceDesign.sub.experimentsMessages}/:experimentId/:id/:messageId/`,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign
						.journeyExperiments,
					[
						{
							redirectUrl: PAGE_URLS.experienceDesign().journey,
							abilityList:
								experienceDesignAuthorization.experienceDesign
									.journey,
						},
					],
				),
			async lazy() {
				const { MessageEditPage } = await import(
					"src/module--journey/journey.lazy"
				);
				return { Component: MessageEditPage };
			},
		},
		...getRedirectRoutes(generateRedirectsMapping(redirectsMapping)),
	];
}
