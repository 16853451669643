import type { QueryClient } from "@tanstack/react-query";
import { redirect } from "react-router";
import type { ApiError } from "src/types/ApiError";
import { notificationsNotificationListOptions } from "src/types/_generated/@tanstack/react-query.gen";
import {
	getDaysBeforeDate,
	setDateTimeToStartOfTheDayDateString,
} from "src/utils/dates";

export const notificationsListLoader = (
	qc: QueryClient,
	accountIdParam: number,
) => {
	const nowDate = new Date();
	const dateSevenDaysBeforeDateString = setDateTimeToStartOfTheDayDateString(
		getDaysBeforeDate(nowDate, 7).start,
	);
	try {
		return qc.ensureQueryData(
			notificationsNotificationListOptions({
				query: {
					account: [accountIdParam],
					created_at_after: dateSevenDaysBeforeDateString,
				},
			}),
		);
	} catch (err) {
		const error = err as ApiError;
		if (error.status === 404) return redirect("/404");
		throw error;
	}
};
