import { queryOptions } from "@tanstack/react-query";
import {
	getCommunication,
	getCommunicationMessageTypes,
	getEmailPreview,
	getNotifications,
} from "src/services/api";

import type { CommunicationsSearchOptsProps } from "src/module--communications/types/communications.type";
import type { GetEmailPreviewQueryParams } from "src/module--communications/utils/filters";
import type { MessageChannelTypeChoices } from "src/types/_generated";
import type { NotificationsRequestType } from "src/types/notifications.type";

export const emailPreviewQueries = {
	allKey: () => ["email-preview"],
	detailKey: () => [...emailPreviewQueries.allKey(), "detail"],
	detail: (query: GetEmailPreviewQueryParams, headers: Headers) =>
		queryOptions({
			queryKey: [
				...emailPreviewQueries.detailKey(),
				query,
				headers.get("user"),
			],
			queryFn: () =>
				getEmailPreview({
					messageId: query.messageId,
					userId: query.userId,
					headers,
				}),
		}),
};

export const notificationQueries = {
	allKey: () => ["notifications"],
	detailKey: () => [...notificationQueries.allKey(), "detail"],
	detail: (query: NotificationsRequestType, headers: Headers) =>
		queryOptions({
			queryKey: [
				...notificationQueries.detailKey(),
				JSON.stringify(query),
				headers.get("user"),
			],
			queryFn: () => getNotifications({ query, headers }),
		}),
};

export const communicationMessageTypesQueries = {
	allKey: () => ["communication-message-types"],
	listKey: () => [...communicationMessageTypesQueries.allKey(), "list"],
	list: (
		type: MessageChannelTypeChoices,
		query: CommunicationsSearchOptsProps,
		headers: Headers,
	) =>
		queryOptions({
			queryKey: [
				...communicationMessageTypesQueries.listKey(),
				type,
				headers.get("user"),
			],
			queryFn: () =>
				getCommunicationMessageTypes({
					headers,
					query,
					type,
				}),
		}),
};
export const communicationMessagesQueries = {
	allKey: () => ["communication-messages"],
	listKey: () => [...communicationMessagesQueries.allKey(), "list"],
	list: (
		type: MessageChannelTypeChoices,
		query: CommunicationsSearchOptsProps,
		headers: Headers,
	) =>
		queryOptions({
			queryKey: [
				...communicationMessagesQueries.listKey(),
				query,
				type,
				headers.get("user"),
			],
			queryFn: () =>
				getCommunication({
					headers,
					query,
					type,
				}),
		}),
};
