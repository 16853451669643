import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "src/components/ui/Tooltip";
import type { Action, Model } from "src/contexts/authorization-context";

type AuthorizationTooltipProps = Partial<ComponentProps<typeof Tooltip>> & {
	allowed: boolean;
	authorizedOn?: Model;
	authorizedDo?: Action;
};

export function AuthorizationTooltip({
	children,
	allowed,
	authorizedDo,
	authorizedOn,
	...rest
}: AuthorizationTooltipProps) {
	const { t } = useTranslation(undefined, {
		keyPrefix: "can.tooltip.notAllowed",
	});

	return (
		<Tooltip
			disableTooltip={() => allowed}
			content={
				<span>
					{t(
						`${authorizedDo ?? "default"}.${authorizedOn ?? "default"}`,
						{ defaultValue: t("default.default") },
					)}
				</span>
			}
			{...rest}
		>
			<div>{children}</div>
		</Tooltip>
	);
}
