import type { Resource } from "i18next";
import type { JSONSchema4Object } from "json-schema";
import merge from "lodash/merge";
import { isProd } from "src/settings";

export interface Language {
	code: LanguageCode;
	name: string;
	flag: string;
}

export type LanguageCode =
	| "ach-UG"
	| "en-US"
	| "en-GB"
	| "de-DE"
	| "es-ES"
	| "fr-FR"
	| "it-IT";

export const defaultLanguage: LanguageCode = "en-GB";

export const languages: Language[] = [
	{ code: "en-US", name: "English US", flag: "us" },
	{ code: "en-GB", name: "English UK", flag: "gb" },
	{ code: "es-ES", name: "Español", flag: "es" },
	{ code: "fr-FR", name: "Français", flag: "fr" },
	{ code: "de-DE", name: "Deutsch", flag: "de" },
	{ code: "it-IT", name: "Italiano", flag: "it" },
];
// InContextTranslation is a special language code that is used for in-context translations in non-prod stages
if (!isProd()) {
	languages.push({
		code: "ach-UG",
		name: "In Context Translation",
		flag: "ach",
	});
}
export const supportedLanguages = new Set(languages.map((lang) => lang.code));

export const languageMapping: { [key: string]: string } = languages.reduce(
	(acc, lang) => {
		acc[lang.flag] = lang.code;
		return acc;
	},
	{} as { [key: string]: string },
);

export const defaultNameSpace = "common";

export const nameSpaces = [
	"returns-translation-schema",
	"returns-rule-schema",
	"returns-portal-schema",
	"returns-order-api-schema",
	"returns-courier-api-schema",
] as const;

const importResourceFile = async (nameSpace: string, lng: LanguageCode) =>
	import(`../locales/${nameSpace}/${lng}.json`) as Promise<JSONSchema4Object>;

type Config = { nameSpace: string; lngCode: LanguageCode };

async function generateResource(params: Config) {
	const { nameSpace, lngCode } = params;

	let resource: Resource | null = null;

	try {
		resource = (await importResourceFile(nameSpace, lngCode)) as Resource;
	} catch { }

	return {
		[lngCode]: {
			[nameSpace]: resource?.default,
		},
	};
}

export async function fetchResources(): Promise<Resource> {
	const configs: Config[] = [defaultNameSpace, ...nameSpaces].flatMap(
		(nameSpace) =>
			languages.map((lng) => {
				const lngCode = lng.code;
				return { nameSpace, lngCode };
			}),
	);

	const resource = await Promise.all(
		configs.map((config) => generateResource(config)),
	).then((resources) => {
		const res: Resource = merge(...(resources as [never])) as Resource;
		return res;
	});

	return resource;
}
