import { type LoaderFunctionArgs, redirect } from "react-router";
import { settingsAuthorization } from "src/configs/authorization/settings";
import { PAGES } from "src/configs/pages-urls";
import { SettingsPage } from "src/module--settings/pages/Settings.page";
import { notificationsListLoader } from "src/module--settings/settings.loaders";
import { authorizationLoader } from "src/router/common-loaders";
import type { RouterConfig } from "src/router/types";

export function settingsRoutes(config: RouterConfig) {
	return [
		{
			path: "",
			loader: () => redirect(PAGES.settings.sub.profile),
		},
		{
			path: PAGES.settings.sub.profile,
			element: <SettingsPage />,
		},
		{
			path: PAGES.settings.sub.notifications,
			async lazy() {
				const { NotificationsListPage } = await import(
					"src/module--settings/pages/NotificationsList.page"
				);
				return { Component: NotificationsListPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					settingsAuthorization.settings.notifications,
				)) &&
				notificationsListLoader(
					config.queryClient,
					Number(accountIdParam),
				),
		},
	];
}
