import type * as SwitchPrimitives from "@radix-ui/react-switch";
import type { VariantProps } from "class-variance-authority";
import { useField } from "formik";
import {
	type ComponentProps,
	type ReactElement,
	type ReactNode,
	useMemo,
} from "react";
import type { labelVariants } from "src/components/form/InputLabel";
import { SwitchFormElement } from "src/components/form/Switch/SwitchFormElement";
import type { baseFormElementWrapperVariants } from "src/components/form/form.styles";
import { fieldValidator } from "src/components/form/validators";

export type FormikFieldSwitchProps = Omit<
	typeof SwitchPrimitives.Root,
	"form" | "$$typeof"
> & {
	label?: ReactElement<string> | string;
	description?: ReactNode;
	descriptionModalContent?: ReactNode;
	name: string;
	id?: string;
	className?: string;
	required?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	defaultChecked?: boolean;
	wrapperClassName?: string;
	tooltip?: string;
	hideOptionalText?: boolean;
	labelStyle?: VariantProps<typeof labelVariants>["filedStyle"];
} & VariantProps<typeof baseFormElementWrapperVariants> &
	Omit<ComponentProps<typeof SwitchFormElement>, "field" | "meta">;

export function FormikFieldSwitch({
	name,
	required,
	labelStyle,
	...rest
}: FormikFieldSwitchProps) {
	const validate = useMemo(
		() =>
			fieldValidator("boolean", {
				required,
			}),
		[required],
	);
	const [field, meta] = useField({ name, type: "checkbox", validate });
	return (
		<SwitchFormElement
			field={field}
			meta={meta}
			required={required}
			labelStyle={labelStyle}
			{...rest}
		/>
	);
}
