import { cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { type NoticeProps, components } from "react-select";
import type { SelectOption } from "src/types/ui.types";

export const selectNoOptionsMessageVariants = cva(["px-4 py-2 text-sm"], {
	variants: {
		errorMessage: {
			true: "text-error",
		},
	},
});

export function SelectNoOptionsMessage(
	props: NoticeProps<SelectOption> & { errorMessage?: string },
) {
	const { t } = useTranslation();
	const { errorMessage } = props;

	return (
		<components.NoOptionsMessage {...props}>
			<span
				className={selectNoOptionsMessageVariants({
					errorMessage: !!errorMessage,
				})}
			>
				{t(errorMessage || "noOptions")}
			</span>
		</components.NoOptionsMessage>
	);
}
