import * as Sentry from "@sentry/react";
import type { Query } from "@tanstack/react-query";

const isValidQueryKey = (key: unknown): key is { _id: string } => {
	return (
		typeof key === "object" &&
		key !== null &&
		"_id" in key &&
		typeof key._id === "string"
	);
};

const excludedQueryKeys: string[] = [
	"accountUsersMeRetrieve",
	"accountAccountsRetrieve",
];

export function invalidateQuery(query: Query): boolean {
	try {
		const keyItem = query.queryKey[0];

		if (!keyItem) {
			return false;
		}

		const keyId =
			typeof keyItem === "string"
				? keyItem
				: isValidQueryKey(keyItem)
					? keyItem._id
					: null;

		if (!keyId) {
			Sentry.captureException(
				new Error(`Invalid query key on invalidateQueries: ${keyItem}`),
			);
			return false;
		}

		return !excludedQueryKeys.includes(keyId);
	} catch (error) {
		Sentry.captureException(error);
		return false;
	}
}
