import Hotjar from "@hotjar/browser";
import { useEffect } from "react";
import { useCurrentAccountId } from "src/hooks/use-account-id";
import { useOidcUser } from "src/services/oidc-exporter";
import { getSettings, isProd } from "src/settings";

export const useHotjar = () => {
	const { oidcUser } = useOidcUser();
	const accountId = useCurrentAccountId();

	useEffect(() => {
		try {
			const settings = getSettings();
			if (isProd() && settings.hotjar) {
				Hotjar.init(settings.hotjar?.id, settings.hotjar?.version, {
					debug: settings.hotjar?.debug || false,
					nonce: "parcelLabhotjar",
				});
			}
		} catch (error) {
			console.error("Hotjar init error:", error);
		}
	}, []);

	useEffect(() => {
		try {
			if (oidcUser && isProd()) {
				const isPlEmployee =
					oidcUser?.email?.endsWith("@parcellab.com");
				Hotjar.identify(oidcUser.sub, {
					account: accountId || "",
					is_pl_employee: isPlEmployee || false,
				});
			}
		} catch (error) {
			console.error("Hotjar identify error:", error);
		}
	}, [oidcUser, accountId]);
};
