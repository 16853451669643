import type { LoaderFunctionArgs } from "react-router";
import { experienceDesignAuthorization } from "src/configs/authorization/experience-design";
import { PAGES } from "src/configs/pages-urls";
import { authorizationLoader } from "src/router/common-loaders";
import type { RouterConfig } from "src/router/types";

export function campaignRoutes(config: RouterConfig) {
	return [
		{
			path: PAGES.experienceDesign.sub.audiences,
			async lazy() {
				const { AudiencesPage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: AudiencesPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.audiences,
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.audiences}/${PAGES.new}`,
			async lazy() {
				const { NewAudiencePage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: NewAudiencePage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.audiences,
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.audiences}/:id`,
			async lazy() {
				const { EditAudiencePage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: EditAudiencePage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.audiences,
				),
		},
		{
			path: PAGES.experienceDesign.sub.campaigns,
			async lazy() {
				const { CampaignsPage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: CampaignsPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.campaigns,
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.campaigns}/${PAGES.new}`,
			async lazy() {
				const { NewCampaignPage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: NewCampaignPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.campaigns,
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.campaigns}/:id`,
			async lazy() {
				const { EditCampaignPage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: EditCampaignPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.campaigns,
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.campaigns}/preview/:id`,
			async lazy() {
				const { PreviewCampaignPage } = await import(
					"src/module--campaigns/campaigns.lazy"
				);
				return { Component: PreviewCampaignPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.campaigns,
				),
		},
		{
			path: PAGES.experienceDesign.sub.campaignsAnalytics,
			async lazy() {
				const { CampaignsReport } = await import(
					"src/components/Report"
				);
				return { Component: CampaignsReport };
			},
		},
	];
}
