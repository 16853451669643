import { useAbility } from "@casl/react";
import {
	type AbilityArguments,
	AuthorizationContext,
	checkAbilities,
} from "src/contexts/authorization-context";

export function useHasAuthorization(
	authorizationArguments?: AbilityArguments,
	skipAuthorization?: boolean,
) {
	if (skipAuthorization) return true;

	const ability = useAbility(AuthorizationContext);

	if (!authorizationArguments) return false;

	return checkAbilities(ability, authorizationArguments);
}
