import { useMemo } from "react";
import { useCurrentAccountId } from "src/hooks/use-account-id";
import { useOidcIdToken, useOidcUser } from "src/services/oidc-exporter";
import type { UserInfo } from "src/types/oidc-user-info";
import { getDefaultUserAccountId } from "src/utils/user";

export const getAuthHeaders = (
	user: UserInfo | null | undefined,
	currentAccountId: number | undefined,
	idToken: string | undefined,
	skipAccountOverride = false,
) => {
	if (!user || !idToken) {
		return new Headers();
	}
	const accountId = getDefaultUserAccountId(user);
	const headers = {
		"pl-account-id": String(accountId),
		user: String(accountId),
		Authorization: `Bearer ${idToken}`,
	};
	if (currentAccountId && !skipAccountOverride) {
		headers["pl-account-id"] = String(currentAccountId);
		headers.user = String(currentAccountId);
	}
	return new Headers(headers);
};

export const useAuthHeaders = (skipAccountOverride = false) => {
	const { oidcUser } = useOidcUser();
	const { idToken } = useOidcIdToken() as { idToken: string | undefined };
	const currentAccountId = useCurrentAccountId();
	const authHeaders = useMemo(
		() =>
			getAuthHeaders(
				oidcUser,
				currentAccountId,
				idToken,
				skipAccountOverride,
			),
		[oidcUser, currentAccountId, idToken, skipAccountOverride],
	);
	return authHeaders;
};
