import { cva } from "class-variance-authority";
import type { ComponentProps } from "react";
import { cn } from "src/utils/tailwind";

const errorVariants = cva("text-error md:col-start-2 block my-2", {
	variants: {
		variant: {
			critical: "text-sm bg-error-light text-error p-2 rounded",
			warning: "text-warning bg-warning-light text-warning p-2 rounded",
			default: "text-error md:col-start-2 block",
		},
	},
	defaultVariants: {
		variant: "default",
	},
});

export type ErrorTextProps = ComponentProps<"small"> & {
	message: string;
	variant?: "critical" | "default" | "warning";
};

export function ErrorText({ message, className, variant }: ErrorTextProps) {
	return (
		<small
			data-testid={`${variant || "error"}-message`}
			data-error={message}
			className={cn(errorVariants({ variant }), className)}
		>
			{message}
		</small>
	);
}
