export enum Roles {
	UserManagement = "user-management",
	Configuration = "configuration",
	CampaignAdmin = "campaign:admin",
	BenchmarkViewer = "reporting-benchmark",
	TrackingMember = "tracking:member",
	TrackingAdmin = "tracking:admin",
	TrackingViewer = "tracking:viewer",
	PromiseAdmin = "promise-admin",
	ReturnsAdmin = "returns:admin",
	JourneyAdmin = "journey:admin",
	JourneyMember = "journey:member",
	// SuperAdmin = "super-admin",
	LogisticExpert = "logistic-expert",
	ClientAdmin = "client-admin",
	DataProtection = "data-protection",
	AnalyticsMember = "analytics:member",
	Export = "export",
	APIAdmin = "api-admin",
	AppsMember = "apps:member",
	ServiceMember = "service:member",
}
