import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { type VariantProps, cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button } from "src/components/ui/Button";
import { useGoBack } from "src/hooks/use-go-back";
import { cn } from "src/utils/tailwind";

export const goBackButtonVariants = cva(
	"p-0 border-0 bg-transparent flex items-center hover:opacity-75 hover:bg-transparent",
	{
		variants: {
			color: {
				dark: "text-foregroundDark",
				light: "text-white",
			},
			size: {
				small: "w-5 h-5 md:w-5 md:h-5",
				medium: "w-6 h-6 md:w-7 md:h-7",
			},
		},
		defaultVariants: {
			color: "light",
			size: "medium",
		},
	},
);

interface Props extends Partial<React.ComponentProps<typeof Button>> {
	ariaLabelTextKey?: string;
	defaultBackPath?: string;
	backPath?: string;
	iconSize?: VariantProps<typeof goBackButtonVariants>["size"];
	colorVariant?: VariantProps<typeof goBackButtonVariants>["color"];
}

export function GoBackButton({
	ariaLabelTextKey = "aria.goBack",
	dataTestId = "back-btn",
	className,
	backPath,
	defaultBackPath,
	colorVariant,
	iconSize,
	...rest
}: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const goBack = useGoBack(defaultBackPath);

	const handleBackButton = async () => {
		if (backPath) {
			await navigate(backPath);
		} else {
			goBack();
		}
	};

	return (
		<Button
			type="button"
			dataTestId={dataTestId}
			aria-label={t(ariaLabelTextKey)}
			focus={false}
			onClick={handleBackButton}
			className={cn(
				goBackButtonVariants({
					color: colorVariant || "light",
					size: iconSize || "medium",
				}),
				className,
			)}
			{...rest}
		>
			<ArrowLeftIcon className="w-full h-full" />
		</Button>
	);
}
