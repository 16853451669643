import { type VariantProps, cva } from "class-variance-authority";
import { type ComponentProps, forwardRef } from "react";
import { cn } from "src/utils/tailwind";

const cardVariants = cva(["relative rounded-lg bg-card text-card-foreground"], {
	variants: {
		clickable: {
			true: "cursor-pointer focus-within:ring-2 focus-within:ring-inset focus-within:ring-pl-blue hover:shadow-lg",
		},
		appStoreCard: {
			true: "flex flex-col justify-between h-72",
		},
		bordered: {
			true: "border",
		},
		shadowed: {
			true: "shadow-sm",
		},
		fieldGroupCard: {
			true: "p-4 space-y-formElement",
		},
	},
	defaultVariants: {
		bordered: true,
		clickable: false,
		shadowed: true,
		appStoreCard: false,
	},
});

type Props = ComponentProps<"div"> & VariantProps<typeof cardVariants>;

const CardComponent = forwardRef<HTMLDivElement, Props>(
	(
		{
			className,
			clickable,
			bordered,
			shadowed,
			appStoreCard,
			fieldGroupCard,
			...props
		},
		ref,
	) => (
		<div
			ref={ref}
			className={cn(
				cardVariants({
					clickable,
					appStoreCard,
					fieldGroupCard,
					bordered,
					shadowed,
				}),
				className,
			)}
			{...props}
		/>
	),
);
CardComponent.displayName = "Card";

const CardHeader = forwardRef<HTMLDivElement, ComponentProps<"div">>(
	({ className, ...props }, ref) => (
		<div
			ref={ref}
			className={cn("flex flex-col space-y-1.5 p-6", className)}
			{...props}
		/>
	),
);
CardHeader.displayName = "CardHeader";

const CardTitle = forwardRef<HTMLHeadingElement, ComponentProps<"h3">>(
	({ className, children, ...props }, ref) => (
		<h3
			ref={ref}
			className={cn(
				"text-2xl font-semibold leading-none tracking-tight",
				className,
			)}
			{...props}
		>
			{children}
		</h3>
	),
);
CardTitle.displayName = "CardTitle";

const CardDescription = forwardRef<HTMLParagraphElement, ComponentProps<"p">>(
	({ className, ...props }, ref) => (
		<p
			ref={ref}
			className={cn("text-sm text-muted-foreground", className)}
			{...props}
		/>
	),
);
CardDescription.displayName = "CardDescription";

const CardContent = forwardRef<HTMLDivElement, ComponentProps<"div">>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
	),
);
CardContent.displayName = "CardContent";

const CardFooter = forwardRef<HTMLDivElement, ComponentProps<"div">>(
	({ className, ...props }, ref) => (
		<div ref={ref} className={cn("flex p-6 pt-0", className)} {...props} />
	),
);
CardFooter.displayName = "CardFooter";

const Card = Object.assign(CardComponent, {
	Header: CardHeader,
	Footer: CardFooter,
	Title: CardTitle,
	Description: CardDescription,
	Content: CardContent,
});
export { Card };
