import type { ComponentProps } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import type { SingleValue } from "react-select";
import { InputLabel } from "src/components/form";
import { Select } from "src/components/form/Select/Select";
import { Card } from "src/components/ui/Card";
import { Divider } from "src/components/ui/Divider";
import { Flex } from "src/components/ui/Flex";
import { P } from "src/components/ui/Typography";
import {
	useUserSettings,
	useUserSettingsMutation,
} from "src/hooks/use-user-settings";
import { PageLayout, PageSection } from "src/layouts/PageLayout";
import { PiiWrapper } from "src/layouts/PiiWrapper";
import { languages } from "src/locales/language-config";
import { UserForm } from "src/module--settings/components/UserForm";
import type { SelectOption } from "src/types/ui.types";

function SettingsItem({ children, ...rest }: ComponentProps<"div">) {
	return (
		<div className="grid gap-4 md:grid-cols-form" {...rest}>
			{children}
		</div>
	);
}

export function SettingsPage() {
	const { t, i18n } = useTranslation();
	const updateUserDetailsMutation = useUserSettingsMutation();
	const { userLocale, isLoading, userDetails } = useUserSettings();

	const languageOptions = languages.map((language) => ({
		label: language.name,
		value: language.code,
	}));

	const handleUserLanguageChange = async (
		newValue: SingleValue<SelectOption>,
	) => {
		// ach-UG is a special language code that is used for in-context translations
		if (newValue && newValue.value !== "ach-UG") {
			const updatedLanguage = newValue.value?.toString();
			const updatedLanguageWithUnderscore = newValue.value
				?.toString()
				.replaceAll(/-/gi, "_");
			const updatedUserDetails = {
				body: {
					userLocale: updatedLanguageWithUnderscore,
				},
			};
			updateUserDetailsMutation.mutate(updatedUserDetails, {
				onSuccess: async () => {
					toast.success(t("toast.systemLanguageUpdated"));
					await i18n.changeLanguage(updatedLanguage);
				},
				onError: () => {
					toast.error(t("toast.systemLanguageUpdatedError"));
				},
			});
		}
		// ach-UG is a special language code that is used for in-context translations
		// we only switch to the language client-side so translators can use the in-context translation
		if (newValue && newValue.value === "ach-UG") {
			await i18n.changeLanguage(newValue.value?.toString());
		}
	};

	return (
		<PageLayout metaTitle={t("settings.title")}>
			<PageSection>
				<Card>
					<Card.Header className="pb-4">
						<Card.Title>{t("title")}</Card.Title>
						{userDetails && !userDetails.profileCompleted && (
							<Card.Description>
								{t("settings.missingInformation.description")}
							</Card.Description>
						)}
					</Card.Header>
					<Card.Content>
						<Flex dir="col" gap={8}>
							<Flex className="max-w-form" dir="col" gap={4}>
								<SettingsItem>
									<InputLabel hideOptionalText>
										{t("settings.username")}:
									</InputLabel>
									<PiiWrapper>
										<P>{userDetails?.username}</P>
									</PiiWrapper>
								</SettingsItem>
								<SettingsItem>
									<InputLabel hideOptionalText>
										{t("settings.email")}:
									</InputLabel>
									<PiiWrapper>
										<P>{userDetails?.email}</P>
									</PiiWrapper>
								</SettingsItem>
								<SettingsItem data-testid="language-select-wrapper">
									<InputLabel hideOptionalText>
										{t("settings.systemLanguage")}:
									</InputLabel>
									<Select
										value={userLocale}
										options={languageOptions}
										name="language-select"
										className="react-sortable-select-single mb-4 md:mb-0 min-w-52"
										onSelectChange={
											handleUserLanguageChange
										}
										isNotClearable
										isLoading={isLoading}
									/>
								</SettingsItem>
							</Flex>
							<Divider className="max-w-form" />
							{userDetails && (
								<UserForm userSettings={userDetails} />
							)}
						</Flex>
					</Card.Content>
				</Card>
			</PageSection>
		</PageLayout>
	);
}
