import { type MenuListProps, components } from "react-select";
import { MultiSelectControlButton } from "src/components/form/Select/MultiSelectControlButton";

export function MultiSelectFilterableMenuList(
	selectAllOptions: () => void,
	deselectAllOptions: () => void,
) {
	return function Component({ children, ...props }: MenuListProps) {
		return (
			<components.MenuList {...props}>
				<div className="-mt-1 pt-1 mb-2 flex items-stretch justify-between">
					<MultiSelectControlButton
						onClick={selectAllOptions}
						textKey="selectAll"
						dataTestId="multi-select-select-all"
						className="rounded-tl"
					/>
					<MultiSelectControlButton
						onClick={deselectAllOptions}
						textKey="deselectAll"
						dataTestId="multi-select-deselect-all"
						className="rounded-tr border-l text-slate-600"
					/>
				</div>
				{children}
			</components.MenuList>
		);
	};
}
