import { useQuery } from "@tanstack/react-query";
import { useCurrentAccountId } from "src/hooks/use-account-id";
import { PublishedMenuNotification } from "src/layouts/PublishedMenuNotification";
import { ReleaseStatus68aEnum } from "src/types/_generated";
import { returnsPortalsListOptions } from "src/types/_generated/@tanstack/react-query.gen";

export function ReturnsPublishedMenuNotification() {
	const currentAccountId = useCurrentAccountId();

	const { data: portalsList } = useQuery(
		returnsPortalsListOptions({
			query: {
				account: [Number(currentAccountId)],
			},
		}),
	);

	const draftPortalsList = (portalsList?.results || []).filter(
		(portal) =>
			(portal.releaseStatus as ReleaseStatus68aEnum) ===
			ReleaseStatus68aEnum.DRAFT,
	);

	const hasNotification = draftPortalsList.length > 0;
	return (
		<PublishedMenuNotification
			hasNotification={hasNotification}
			selectorId="publishSetup"
		/>
	);
}
