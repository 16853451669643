import type { AccountInfoType } from "src/types/account-info.type";
import type { Option, SelectOption } from "src/types/ui.types";

import type { TFunction } from "i18next";
import type { CommunicationMessageType } from "src/module--communications/types/communications.type";
import type { DeliveryMethodDetails } from "src/types/_generated";

export const couriersArrayToOptionArray = (
	arr: AccountInfoType[],
): SelectOption[] =>
	arr.map((item) => ({
		value: item.code ?? item.name,
		label: `${item.name}${item.code ? ` (${item.code})` : ""}`,
	}));

export const countryArrayToOptionArray = (
	arr: AccountInfoType[],
): SelectOption[] =>
	arr.map((item) => ({
		value: item.code ?? item.name,
		label: item.code ?? item.name,
	}));

export const arrayToOptionArray = (arr: string[]): SelectOption[] =>
	arr.map((item) => ({ value: item, label: item }));

export const getTranslatedArray = (
	t: TFunction,
	arr: string[] = [],
	prefix = "",
) => arr.map((item) => t(`${prefix}${item}`));

export const getOptionsWithLabelTranslate = (
	t: TFunction,
	arr: string[] = [],
	prefix = "",
) => arr.map((item) => ({ label: t(`${prefix}${item}`), value: item }));

export const getValuesArrayFromSelectOptions = (options: SelectOption[]) =>
	options.map((item) => item.value);

export const getDeliveryPredictionOptionFromArray = (
	arr: DeliveryMethodDetails[],
	t: TFunction,
): SelectOption[] =>
	arr.map(
		(item) =>
			({
				value: `${item.id}`,
				label: item.name,
				labelDescription: [
					item?.warehouse?.name,
					`${
						item?.countries
							? getTranslatedArray(
									t,
									item?.countries,
									"country.",
								).join(", ")
							: ""
					}`,
				],
			}) as SelectOption,
	);

// TODO: this needs refactoring, it's half way between generic and specific for Option and SelectOption
export const getFilteredArrayFromArray = <T>(arr: T[], arr1: T[]): T[] => {
	const arrIds = arr?.map(
		(item) => (item as Option).id || (item as SelectOption).value,
	);
	return [...arr1].filter((item) =>
		(item as Option).id
			? !arrIds.includes((item as Option).id)
			: !arrIds.includes((item as SelectOption).value),
	);
};

export const getOptionsFromEmailMessages = (
	messages: CommunicationMessageType[],
): { label: string; value: string }[] =>
	messages.map((message) => ({
		label: message.prettyName ?? "",
		value: message.name ?? message.prettyName ?? "",
	}));

export const getOptionFromValue = (
	options: SelectOption[] | { label: string; options: SelectOption[] }[],
	value: SelectOption["value"],
): SelectOption | undefined => {
	for (const item of options) {
		if ("options" in item) {
			const found = item.options.find((option) => option.value === value);
			if (found) return found;
		} else if (item.value === value) {
			return item;
		}
	}
	return undefined;
};
