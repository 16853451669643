import { type LoaderFunctionArgs, Outlet } from "react-router";
import { adminAuthorization } from "src/configs/authorization/admin";
import { appStoreAuthorization } from "src/configs/authorization/app-store";
import { communicationsAuthorization } from "src/configs/authorization/communication";
import { experienceDesignAuthorization } from "src/configs/authorization/experience-design";
import { promiseAuthorization } from "src/configs/authorization/promise";
import { promotionsAuthorization } from "src/configs/authorization/promotions";
import { returnsAuthorization } from "src/configs/authorization/returns";
import { trackingsAuthorization } from "src/configs/authorization/trackings";
import { PAGES, PAGE_URLS } from "src/configs/pages-urls";
import { appStoreRoutes } from "src/module--app-store/app-store.router";
import { campaignRoutes } from "src/module--campaigns/campaigns.router";
import { communicationsRoutes } from "src/module--communications/communications.router";
import { journeyRoutes } from "src/module--journey/journey.router";
import { promisesRoutes } from "src/module--promise/promise.router";
import { ReturnsPublishedMenuNotification } from "src/module--returns/components/ReturnsPublishedMenuNotification";
import {
	returnPromotionRoutes,
	returnsRoutes,
} from "src/module--returns/returns.router";
import { settingsRoutes } from "src/module--settings/settings.router";
import { surveyRoutes } from "src/module--survey/survey.router";
import { trackingsRoutes } from "src/module--trackings/trackings.router";
import { NoPermission } from "src/pages/403";
import { NotFound } from "src/pages/404";
import { LandingPage } from "src/pages/landing-page";
import { AppErrorState } from "src/router/app-error-state";
import { authorizationLoader } from "src/router/common-loaders";
import { serviceRoutes } from "src/router/service";
import type { RouterConfig } from "src/router/types";

export const appRoutes = (config: RouterConfig) => {
	const { oidcUser, queryClient, idToken } = config;
	return [
		{
			lazy: async () => {
				const { AppLayout } = await import("src/layouts/AppLayout");
				return { Component: AppLayout };
			},
			errorElement: <AppErrorState />,
			children: [
				{
					index: true,
					element: <LandingPage />,
				},
				{
					path: PAGES.settings.path,
					element: <Outlet />,

					children: settingsRoutes(config),
				},
				{
					path: PAGES.trackings.path,
					children: trackingsRoutes(config),
					loader: ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							trackingsAuthorization.trackings.index,
						),
				},
				{
					path: PAGES.communications.path,
					element: <Outlet />,

					children: communicationsRoutes(config),
					loader: ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							communicationsAuthorization.communications.index,
						),
				},
				{
					path: PAGES.experienceDesign.path,
					element: <Outlet />,

					children: [
						{
							index: true,
							loader: async ({
								params: { accountId: accountIdParam },
							}: LoaderFunctionArgs) =>
								authorizationLoader(
									{
										...config,
										accountIdParam,
										conditionalRedirect: true,
									},
									experienceDesignAuthorization
										.experienceDesign.index,
									[
										{
											redirectUrl:
												PAGE_URLS.experienceDesign()
													.clients,
											abilityList:
												experienceDesignAuthorization
													.experienceDesign.clients,
										},
										{
											redirectUrl:
												PAGE_URLS.experienceDesign()
													.audiences,
											abilityList:
												experienceDesignAuthorization
													.experienceDesign.audiences,
										},
										{
											redirectUrl:
												PAGE_URLS.experienceDesign()
													.surveys,
											abilityList:
												experienceDesignAuthorization
													.experienceDesign.surveys,
										},
									],
								),
						},
						...journeyRoutes(config),
						...campaignRoutes(config),
						...surveyRoutes(config),
					],
					loader: async ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							experienceDesignAuthorization.experienceDesign
								.index,
						),
				},
				{
					path: PAGES.returns.path,
					element: (
						<>
							<ReturnsPublishedMenuNotification />
							<Outlet />
						</>
					),

					children: returnsRoutes({
						oidcUser,
						idToken,
						queryClient,
					}),
					loader: async ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							returnsAuthorization.returns.index,
							[
								{
									redirectUrl:
										PAGE_URLS.returnsPromotion().index,
									abilityList:
										promotionsAuthorization.returnsPromotion
											.index,
								},
							],
						),
				},
				{
					path: PAGES.returnsPromotion.path,
					element: <Outlet />,

					children: returnPromotionRoutes(),
					loader: ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							promotionsAuthorization.returnsPromotion.index,
						),
				},
				{
					path: PAGES.promise.path,
					element: <Outlet />,

					children: promisesRoutes(config),
					loader: ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							promiseAuthorization.promises.index,
						),
				},
				{
					path: PAGES.appStore.path,
					element: <Outlet />,
					children: appStoreRoutes(config),
					loader: ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							appStoreAuthorization.appStore.index,
						),
				},
				{
					path: PAGES.service.path,
					element: <Outlet />,
					children: serviceRoutes(config),
					loader: ({
						params: { accountId: accountIdParam },
					}: LoaderFunctionArgs) =>
						authorizationLoader(
							{ ...config, accountIdParam },
							adminAuthorization.admin.index,
						),
				},
				{
					path: PAGES.notAuthorized.path,
					element: <NoPermission />,
				},
				{
					path: "*",
					element: <NotFound />,
				},
			],
		},
	];
};
