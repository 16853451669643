import { t } from "i18next";
import { isEmpty } from "lodash";
import * as yup from "yup";

type InputType = "string" | "number" | "boolean" | "array" | "object";

type ValidatorOptions = {
	required?: boolean;
	pattern?: string;
	maxValue?: number;
	minValue?: number;
	maxLength?: number;
	minLength?: number;
	isEmail?: boolean;
	canBeEmpty?: boolean;
	shape?: yup.ObjectShape;
	isUrl?: boolean;
	multipleOf?: number;
};

type YupValidatorError = { errors?: string[] };

async function yupFieldValidator(value: unknown, schema: yup.Schema) {
	let error: string | undefined;
	try {
		await schema.validate(value);
	} catch (err) {
		error = (err as YupValidatorError).errors?.[0];
	}
	return error;
}

function schemaSelector(type: InputType): yup.Schema {
	switch (type) {
		case "number": {
			return yup
				.number()
				.nullable()
				.transform((value) =>
					Number.isNaN(value as never) ? null : (value as never),
				);
		}
		case "boolean": {
			return yup.boolean().nullable();
		}
		case "array": {
			return yup.array().nullable();
		}
		case "object": {
			return yup.object().nullable();
		}
		default: {
			return yup.string().nullable();
		}
	}
}

export function fieldValidator(
	type: InputType,
	{
		required,
		pattern,
		maxValue,
		minValue,
		maxLength,
		minLength,
		isEmail,
		canBeEmpty,
		shape,
		isUrl,
		multipleOf,
	}: ValidatorOptions,
) {
	let schema: yup.Schema = schemaSelector(type);

	if (required && type === "array") {
		schema = yup.array().min(1);
	}

	if (required) {
		schema = schema.required(t("form.errors.required")) as yup.Schema;
	}

	if (pattern) {
		schema = (schema as yup.StringSchema).matches(
			new RegExp(pattern),
			t("form.errors.pattern"),
		);
	}

	if (isEmail) {
		schema = (schema as yup.StringSchema).email(
			t("form.errors.invalidEmail"),
		);
	}

	if (isUrl) {
		schema = (schema as yup.StringSchema).url(t("form.errors.invalidUrl"));
	}

	if (maxValue) {
		schema = (schema as yup.NumberSchema).max(
			maxValue,
			t("form.errors.maxValue", { maxValue }),
		);
	}

	if (minValue) {
		schema = (schema as yup.NumberSchema).min(
			minValue,
			t("form.errors.minValue", { minValue }),
		);
	}

	if (maxLength) {
		schema = (schema as yup.StringSchema).max(
			maxLength,
			t("form.errors.maxLength", { maxLength }),
		);
	}

	if (minLength) {
		schema = (schema as yup.StringSchema).min(
			minLength,
			t("form.errors.minLength", { minLength }),
		);
	}

	if (shape) {
		schema = (schema as yup.ObjectSchema<never>).shape(shape);
	}

	if (multipleOf) {
		schema = (schema as yup.NumberSchema).test(
			"multipleOf",
			t("form.errors.multipleOf", { multipleOf }),
			(value) => {
				if (value === undefined || value === null) return true;
				return value % multipleOf === 0;
			},
		);
	}

	return (value: unknown) => {
		if (canBeEmpty && isEmpty(value)) {
			return yupFieldValidator(value, schemaSelector(type));
		}
		return yupFieldValidator(value, schema);
	};
}
