import * as LabelPrimitive from "@radix-ui/react-label";
import { type VariantProps, cva } from "class-variance-authority";
import type { FieldProps } from "formik/dist/Field";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "src/utils/tailwind";

export const labelVariants = cva(
	[
		"block break-words text-sm leading-tight peer-disabled:cursor-not-allowed peer-disabled:opacity-70 mb-2",
	],
	{
		variants: {
			fieldState: {
				default: "text-slate-700",
				error: "text-error",
			},
			filedStyle: {
				default: "font-medium",
				regular: "font-regular text-foregroundMedium",
			},
		},
	},
);
type InputLabelProps = React.ComponentPropsWithoutRef<
	typeof LabelPrimitive.Root
> & { hideOptionalText?: boolean } & VariantProps<typeof labelVariants> & {
		meta?: FieldProps["meta"];
		required?: boolean;
	};

export function OptionalMarker({
	className,
	...rest
}: React.ComponentProps<"span">) {
	const { t } = useTranslation();

	return (
		<span
			className={cn("inline-block text-xs text-slate-400", className)}
			{...rest}
		>
			{t("form.optional-field-marker")}
		</span>
	);
}

export const InputLabel = React.forwardRef<
	React.ElementRef<typeof LabelPrimitive.Root>,
	InputLabelProps
>(
	(
		{
			required = false,
			children,
			className,
			filedStyle,
			meta,
			hideOptionalText,
			...props
		},
		ref,
	) => (
		<LabelPrimitive.Root
			ref={ref}
			className={cn(
				labelVariants({
					fieldState:
						meta?.touched && meta?.error ? "error" : "default",
					filedStyle: filedStyle || "default",
				}),
				className,
			)}
			{...props}
		>
			<span>{children}</span>
			{!required && !hideOptionalText && (
				<OptionalMarker className="ml-2" />
			)}
		</LabelPrimitive.Root>
	),
);
InputLabel.displayName = LabelPrimitive.Root.displayName;
