import ky from "ky";
import type { CheckpointsResponseType } from "src/module--checkpoints/types/checkpoint.type";
import type {
	CommunicationMessageTypeResponse,
	CommunicationsResponseType,
	HTMLResponseType,
	ResendEmailResponseType,
} from "src/module--communications/types/communications.type";
import type { ReturnsLabelResponse } from "src/module--trackings/types/details.type";
import type {
	TrackingArticlesResponseType,
	TrackingDetailsRequestType,
	TrackingDetailsResponseType,
	TrackingsType,
} from "src/module--trackings/types/tracking.type";
import { getSettings } from "src/settings";
import type { MessageType, Placeholder } from "src/types/_generated";
import type { AccountInfoType } from "src/types/account-info.type";
import type {
	GDPRDiscloseParams,
	GDPRResponse,
	GenerateEmailPreviewParams,
	GetAccountLanguagesParams,
	GetArticleListParams,
	GetCancelRMARequestParams,
	GetCollectionParams,
	GetCollectionSpecificParams,
	GetMetabaseEmbedUrlParams,
	GetMetabaseEmbedUrlResponseType,
	PostClaimedTracking,
	PostExportsParams,
	RemoveFromDenyListParams,
	UpdateParams,
	UpdateTrackingEmailParams,
	getEmailPreviewParams,
} from "src/types/api.types";
import type {
	NotificationsRequestType,
	NotificationsResponseType,
} from "src/types/notifications.type";

const settings = getSettings();

const apiBackend = ky.create({ prefixUrl: settings.backendApi.URL });
const returnsApi = ky.create({ prefixUrl: settings.returnsApi.URL });

export const createCampaignPreview = async ({
	query,
	headers,
}: GetCollectionParams) =>
	apiBackend
		.get("build-email/preview", {
			searchParams: query,
			headers,
		})
		.text();

export const getAccountInfo = async <T = AccountInfoType>({
	query,
	headers,
}: GetCollectionParams) =>
	apiBackend
		.get("account-info", {
			headers,
			searchParams: query,
		})
		.json<T>();

export const getTrackings = async ({ headers, query }: GetCollectionParams) =>
	apiBackend
		.get("v2/search/", {
			searchParams: query,
			headers,
		})
		.json<TrackingsType>();

export const postExports = async ({
	headers,
	query,
	exportOption,
}: PostExportsParams) => {
	const requestHeaders = new Headers(headers);
	requestHeaders.set("exportopt", exportOption);
	requestHeaders.set("content-type", "application/json");

	return apiBackend
		.post("v2/export/", {
			headers: requestHeaders,
			body: JSON.stringify(query),
		})
		.text();
};

export const createReturnsLabel = async ({
	headers,
	payload,
}: Omit<UpdateParams, "id">) => {
	const requestHeaders = new Headers(headers);
	requestHeaders.delete("pl-account-id");
	requestHeaders.delete("authorization");
	requestHeaders.set("content-type", "application/json");

	return returnsApi
		.post("addLabelById", {
			headers: requestHeaders,
			body: JSON.stringify(payload),
		})
		.json<ReturnsLabelResponse>();
};

export const cancelRMA = async ({
	headers,
	query,
}: GetCancelRMARequestParams) => {
	const requestHeaders = new Headers(headers);
	requestHeaders.delete("pl-account-id");
	requestHeaders.set("content-type", "application/json");

	return returnsApi
		.get("cancelRma", {
			headers: requestHeaders,
			searchParams: query,
		})
		.json<HTMLResponseType>();
};

export const closeById = async ({
	headers,
	payload,
}: Omit<UpdateParams, "id">) => {
	const requestHeaders = new Headers(headers);
	requestHeaders.delete("pl-account-id");
	requestHeaders.set("content-type", "application/json");

	return returnsApi
		.post("closeById", {
			headers: requestHeaders,
			body: JSON.stringify(payload),
		})
		.json<HTMLResponseType>();
};

export const resendEmail = async ({
	headers,
	payload,
}: Omit<UpdateParams, "id">) =>
	apiBackend
		.post("v2/re-trigger-message", {
			headers,
			json: payload,
		})
		.json<ResendEmailResponseType>();

export const getTrackingDetails = async ({
	query,
	headers,
}: GetCollectionSpecificParams<TrackingDetailsRequestType>) => {
	const { trackingNo, courier, orderNo, trackingId, lang, user } = query;
	let queryFinal = {};

	if (trackingId) {
		queryFinal = { lang, trackingId };
	} else if (trackingNo && courier) {
		queryFinal = { lang, trackingNo, courier };
	} else if (orderNo && user) {
		queryFinal = { lang, orderNo, user };
	}

	return apiBackend
		.get("v2/tracking-details/", {
			searchParams: queryFinal,
			headers,
		})
		.json<TrackingDetailsResponseType>();
};

export const getSharedTrackingDetails = async ({
	query,
}: GetCollectionSpecificParams<TrackingDetailsRequestType>) =>
	apiBackend
		.get("v2/tracking-details/shared", {
			headers: {
				Authorization: `Bearer ${query.token || ""}`,
			},
		})
		.json<TrackingDetailsResponseType>();

export const claimTracking = async ({
	payload,
	headers,
}: PostClaimedTracking) =>
	apiBackend
		.post("tracking-edit/claim", {
			headers,
			json: payload,
		})
		.text();

export const getCheckpointsList = async ({
	query,
	headers,
}: GetCollectionParams) =>
	apiBackend
		// increasing timeout for checkpoints API as it's failing to respond in time in STG.
		.get("v2/checkpoints", { searchParams: query, headers, timeout: 15000 })
		.json<CheckpointsResponseType>();

export const getNotifications = async ({
	query,
	headers,
}: GetCollectionSpecificParams<NotificationsRequestType>) => {
	const { trackingId, lang, emailId } = query;
	let queryFinal = {};

	if (!trackingId) {
		return [];
	}

	if (emailId) {
		queryFinal = { lang, emailId, trackingId };
	} else if (trackingId) {
		queryFinal = { lang, trackingId };
	}

	return apiBackend
		.get("v2/notifications/", {
			searchParams: queryFinal,
			headers,
		})
		.json<NotificationsResponseType>();
};

export const getCommunicationMessageTypes = async ({
	headers,
	query,
	type,
}: GetCollectionParams) => {
	const endpoints = {
		email: "email/message_type",
		sms: "sms/message_type",
		webhook: "webhook/message_type",
	};

	const endpoint =
		endpoints[type as keyof typeof endpoints] || endpoints.email;
	return apiBackend
		.get(endpoint, { searchParams: query, headers })
		.json<CommunicationMessageTypeResponse>();
};

export const getCommunication = async ({
	headers,
	query,
	type,
}: GetCollectionParams) => {
	const endpoints = {
		email: "email",
		sms: "sms",
		webhook: "webhook",
	};

	const endpoint =
		endpoints[type as keyof typeof endpoints] || endpoints.email;

	return apiBackend
		.get(endpoint, { searchParams: query, headers })
		.json<CommunicationsResponseType>();
};

export const getEmailPreview = async ({
	headers,
	messageId,
	userId,
}: getEmailPreviewParams) =>
	apiBackend
		.get(`email-with-content/${messageId}`, {
			headers,
			searchParams: { user_id: userId },
		})
		.json<HTMLResponseType>();

export const updateTrackingEmail = async ({
	payload,
	headers,
	query,
}: UpdateTrackingEmailParams) =>
	apiBackend
		.post("tracking-edit/email", {
			searchParams: query,
			json: payload,
			headers,
		})
		.text();

export const removeFromDenyList = ({
	hash,
	headers,
}: RemoveFromDenyListParams) =>
	apiBackend
		.get("unsubscribe/blacklist/undo", {
			headers,
			searchParams: {
				code: hash,
			},
		})
		.text();

export const getAccountLanguages = async ({
	headers,
}: GetAccountLanguagesParams) =>
	apiBackend
		.get("account-info/?id=languages", {
			headers,
		})
		.json<string[]>();

export const getMetabaseEmbedUrl = async ({
	metabaseDashboardId,
	headers,
}: GetMetabaseEmbedUrlParams) =>
	apiBackend
		.get("account-info/metabase", {
			headers,
			searchParams: {
				id: metabaseDashboardId,
			},
		})
		.json<GetMetabaseEmbedUrlResponseType>();

export const getArticleList = async ({
	user,
	lang,
	headers,
	securityHash,
	...others
}: GetArticleListParams) =>
	apiBackend
		.get("article-list", {
			headers,
			searchParams: {
				user,
				lang,
				s: securityHash,
				...others,
			},
		})
		.json<TrackingArticlesResponseType>();

export const generateMessagePreview = async ({
	headers,
	content,
	message,
	layoutContent,
	liquid,
	trackingId,
	eventType,
}: GenerateEmailPreviewParams) => {
	const messageType = message.messageType as MessageType;
	const payload = {
		message: {
			envelope: {
				name: messageType.prettyName,
			},
			content: {
				Subject: message.subject,
				ShortInfo: message.shortInfo,
				LongInfo: content,
			},
		},
		layout: layoutContent,
		liquid,
		trackingId,
		eventType,
	};
	message.placeholderValues.forEach((pv) => {
		const placeholder = pv.placeholder as Placeholder;
		if (placeholder.name && pv.value !== undefined) {
			payload.message.content[
				placeholder.name as keyof typeof payload.message.content
			] = pv.value;
		}
	});

	return apiBackend
		.post("email/preview", {
			headers,
			json: payload,
		})
		.json<{ preview: string }>();
};

export const gdprDisclose = async ({
	headers,
	emailList,
}: GDPRDiscloseParams) =>
	apiBackend
		.post("disclose", {
			headers,
			json: { emailList },
		})
		.json<GDPRResponse>();
