import {
	OidcUserStatus,
	useOidc as useOidcExported,
	useOidcIdToken as useOidcIdTokenExported,
	useOidcUser as useOidcUserExported,
} from "@axa-fr/react-oidc";
import { useCallback, useEffect, useState } from "react";
import { APP_SIGNED_IN_FLAG } from "src/configs/constants";
import { PAGE_URLS } from "src/configs/pages-urls";
import { getSettings, isProd, isStorybook, isTest } from "src/settings";
import { appendQueryParamToUrl } from "src/utils/query";
import { OidcUser } from "tests/fixtures/oidc/user";

const mockOidcUser = {
	oidcUserLoadingState: OidcUserStatus.Loaded,
	oidcUser: OidcUser,
};

const preventOidcCall = (isTest() || isStorybook()) && !isProd();

export function useOidcUser() {
	return preventOidcCall
		? mockOidcUser
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useOidcUserExported();
}

export function useOidc() {
	if (preventOidcCall) {
		return {
			isAuthenticated: true,
			login: () => {},
		};
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useOidcExported();
}

export function useOidcIdToken() {
	if (preventOidcCall) {
		return {
			idToken: "mock-id-token",
		};
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useOidcIdTokenExported();
}

export function useOidcLogout() {
	const { logout } = useOidcExported();

	return useCallback((): Promise<void> => {
		const {
			oidc: { redirectUri, logoutRedirectUri, clientId },
		} = getSettings();

		const nextQueryParam = `${redirectUri}${PAGE_URLS.signIn.path}`;

		try {
			sessionStorage.clear();
		} catch (err) {
			console.error("Error while logging out", err);
		}

		return logout(`${logoutRedirectUri}${nextQueryParam}`, {
			client_id: clientId,
		});
	}, [logout]);
}

export function useIsOidcUserLoading() {
	const { oidcUserLoadingState } = useOidcUser();
	const { isAuthenticated } = useOidc();

	const [isUserLoadingStarted, setIsUserLoadingStarted] = useState(
		oidcUserLoadingState === OidcUserStatus.Loading,
	);
	const [isUserLoadingFinished, setIsUserLoadingFinished] = useState(
		!isAuthenticated,
	);

	useEffect(() => {
		if (oidcUserLoadingState === OidcUserStatus.Loading) {
			setIsUserLoadingStarted(true);
		}
	}, [oidcUserLoadingState]);

	useEffect(() => {
		if (
			isUserLoadingStarted &&
			oidcUserLoadingState !== OidcUserStatus.Loading
		) {
			setIsUserLoadingFinished(true);
		}
	}, [oidcUserLoadingState, isUserLoadingStarted]);

	if (preventOidcCall) {
		return false;
	}

	return !isUserLoadingFinished;
}

function isValidPath(redirectPath: string): boolean {
	return (
		(!redirectPath.includes("/?state=") &&
			redirectPath.startsWith(getSettings().oidc.redirectUri)) ||
		redirectPath === "/"
	);
}

function getNextUrl() {
	const { href } = window.location;
	return isValidPath(href) ? href : "/";
}

function getRedirectUrl(nextUrl?: string | null) {
	const redirectUri = nextUrl ?? getNextUrl();

	return appendQueryParamToUrl(redirectUri, APP_SIGNED_IN_FLAG, "true");
}

export function useOidcLogin(overrideNextUrl?: string | null) {
	const { login } = useOidc();

	return useCallback(() => {
		if (preventOidcCall) {
			return () => {};
		}

		return login(getRedirectUrl(overrideNextUrl));
	}, [login, overrideNextUrl]);
}
