import { createContext } from "react";
import type { MatomoTracker } from "src/matomo/MatomoTracker";

type MatomoContextType = {
	matomoTracker: null | MatomoTracker;
};

export const MatomoContext = createContext<MatomoContextType>({
	matomoTracker: null,
});
