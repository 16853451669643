import {
	EnvelopeIcon,
	NewspaperIcon,
	TruckIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { CanAuthorization } from "src/components/Can/Can";
import { PowerBi } from "src/components/PowerBi";
import { DashboardCard } from "src/components/ui/DashboardCard";
import { Flex } from "src/components/ui/Flex";
import { communicationsAuthorization } from "src/configs/authorization/communication";
import { trackingsAuthorization } from "src/configs/authorization/trackings";
import { PAGE_URLS } from "src/configs/pages-urls";
import { REPORTS } from "src/configs/reports";
import { PageLayout, PageSection } from "src/layouts/PageLayout";

export const actions = [
	{
		titleKey: "trackings",
		href: PAGE_URLS.trackings().index,
		Icon: TruckIcon,
		iconForeground: "text-pl-blue",
		iconBackground: "bg-indigo-50",
		descriptionKey: "landingPage.trackingsCard",
		className: "flex-1",
		authorizationArguments: trackingsAuthorization.trackings.index,
	},
	{
		titleKey: "communication",
		href: PAGE_URLS.communications().index,
		Icon: EnvelopeIcon,
		iconForeground: "text-pl-blue",
		iconBackground: "bg-indigo-50",
		descriptionKey: "landingPage.communicationCard",
		className: "flex-1",
		authorizationArguments:
			communicationsAuthorization.communications.index,
	},
	{
		titleKey: "landingPage.documentation",
		href: "https://how.parcellab.works/docs",
		Icon: NewspaperIcon,
		iconForeground: "text-pl-blue",
		iconBackground: "bg-indigo-50",
		descriptionKey: "landingPage.documentationCard",
		className: "flex-1",
	},
];

export function LandingPage() {
	const { t } = useTranslation();

	return (
		<PageLayout>
			<PageSection className="grid gap-4">
				<div className="col-span-full">
					<Flex
						align="center"
						className="overflow-hidden col-span-full pb-2"
						data-testid="section-header"
					>
						<h2 className="text-foreground-medium">
							{t("landingPage.tracking")}
						</h2>
					</Flex>
					<div className="max-h-[90px] sm:max-h-[110px] md:max-h-[175px] lg:max-h-none bg-white overflow-hidden border shadow-sm rounded-lg">
						<PowerBi
							className="lg:max-w-[1280px] mx-auto h-auto md:pt-15 lg:pt-10 xl:pt-0 my-5"
							feedbackVariant="fit"
							wrapperClassName="block pointer-events-none"
							isFullViewButton={false}
							dashboardInfo={REPORTS.landingPageTracking}
						/>
					</div>
				</div>

				<div className="col-span-full grid justify-between gap-4 grid-cols-1 md:grid-cols-3">
					{actions.map((action) => (
						<CanAuthorization
							authorizationArguments={
								action.authorizationArguments
							}
							key={action.titleKey}
						>
							<DashboardCard action={action} />
						</CanAuthorization>
					))}
				</div>

				<div className="col-span-full">
					<Flex
						align="center"
						className="overflow-hidden col-span-full pb-2"
						data-testid="section-header"
					>
						<h2 className="text-foreground-medium">
							{t("landingPage.emails")}
						</h2>
					</Flex>
					<div className="max-h-[90px] sm:max-h-[110px] md:max-h-[175px] lg:max-h-none bg-white overflow-hidden border shadow-sm rounded-lg">
						<PowerBi
							className="lg:max-w-[1280px] mx-auto h-auto md:pt-15 lg:pt-10 xl:pt-0 my-5"
							feedbackVariant="fit"
							wrapperClassName="block pointer-events-none"
							isFullViewButton={false}
							dashboardInfo={REPORTS.landingPageEmails}
						/>
					</div>
				</div>
			</PageSection>
		</PageLayout>
	);
}
