import { lazy } from "react";
import type { LoaderFunctionArgs } from "react-router";
import { experienceDesignAuthorization } from "src/configs/authorization/experience-design";
import { PAGES } from "src/configs/pages-urls";
import {
	surveyAnalyticsListLoader,
	surveyAnalyticsLoader,
	surveyResultLoader,
} from "src/module--survey/survey.loaders";
import { authorizationLoader } from "src/router/common-loaders";
import { lazyLoad } from "src/router/lazyload";
import type { RouterConfig } from "src/router/types";

const SurveyListPage = lazy(() =>
	import("src/module--survey/survey.lazy").then(
		(mod): { default: () => JSX.Element } => ({
			default: mod.SurveyListPage,
		}),
	),
);

export function surveyRoutes(config: RouterConfig) {
	const { queryClient } = config;
	return [
		{
			path: PAGES.experienceDesign.sub.surveys,
			element: lazyLoad(<SurveyListPage />),
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.surveys,
				)) && surveyAnalyticsListLoader(queryClient, accountIdParam),
		},
		{
			path: `${PAGES.experienceDesign.sub.surveys}/new`,

			async lazy() {
				const { SurveyCreatePage } = await import(
					"src/module--survey/survey.lazy"
				);
				return { Component: SurveyCreatePage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.surveys,
				),
		},
		{
			path: `${PAGES.experienceDesign.sub.surveys}/:surveyId`,
			async lazy() {
				const { SurveyEditPage } = await import(
					"src/module--survey/survey.lazy"
				);
				return { Component: SurveyEditPage };
			},
			loader: async ({
				params: { accountId: accountIdParam, surveyId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.surveys,
				)) && surveyAnalyticsLoader(queryClient, surveyId),
		},
		{
			path: `${PAGES.experienceDesign.sub.surveysDashboard}/:surveyId`,
			async lazy() {
				const { SurveyDashboardPage } = await import(
					"src/module--survey/survey.lazy"
				);
				return { Component: SurveyDashboardPage };
			},
			loader: async ({
				params: { accountId: accountIdParam, surveyId },
			}: LoaderFunctionArgs) =>
				(await authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.surveys,
				)) && surveyResultLoader(queryClient, accountIdParam, surveyId),
		},
		{
			path: PAGES.experienceDesign.sub.surveyResponses,
			async lazy() {
				const { SurveyMetabaseResponsesPage } = await import(
					"src/module--survey/survey.lazy"
				);
				return { Component: SurveyMetabaseResponsesPage };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					experienceDesignAuthorization.experienceDesign.surveys,
				),
		},
	];
}
