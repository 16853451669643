import {
	Action,
	type GenericAuthorizationMap,
} from "src/contexts/authorization-context";
import { AccountFeatureFlags } from "src/types/portal-features.type";

type PromotionsAuthorization = {
	returnsPromotion: GenericAuthorizationMap<"index">;
};

export const promotionsAuthorization: PromotionsAuthorization = {
	returnsPromotion: {
		index: {
			cannot: {
				allOf: [
					[Action.useAccountFeature, AccountFeatureFlags.hasReturns],
				],
			},
		},
	},
};
