import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import type { UsersIcon as UsersIconType } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { Card } from "src/components/ui/Card";
import { cn } from "src/utils/tailwind";

export type DashboardCardProps = {
	titleKey: string;
	href: string;
	className?: string;
	Icon?: typeof UsersIconType;
	iconForeground: string;
	iconBackground: string;
	descriptionKey?: string;
	Pill?: React.ReactElement;
};

export function DashboardCard({ action }: { action: DashboardCardProps }) {
	const {
		titleKey,
		href,
		className,
		Icon,
		iconForeground,
		iconBackground,
		descriptionKey,
		Pill,
	} = action;
	const { t } = useTranslation();
	return (
		<Card
			key={titleKey}
			clickable
			data-testid="card"
			className={cn(className)}
		>
			<Card.Header>
				{Icon && (
					<div>
						<span
							className={cn(
								iconBackground,
								iconForeground,
								"inline-flex rounded-lg p-3 ring-4 ring-white",
							)}
						>
							<Icon className="h-6 w-6" aria-hidden="true" />
						</span>
					</div>
				)}
				<Card.Title className="!mt-8 text-lg font-medium flex gap-x-2">
					<Link to={href} className="focus:outline-none">
						<span className="absolute inset-0" aria-hidden="true" />
						{t(titleKey)}
					</Link>
					{Pill && Pill}
				</Card.Title>
				{descriptionKey && (
					<Card.Description
						className="text-sm text-slate-500"
						data-testid="card-description"
					>
						{t(descriptionKey)}
					</Card.Description>
				)}
			</Card.Header>
			<span
				className="pointer-events-none absolute right-6 top-6 text-slate-300 group-hover:text-slate-400"
				aria-hidden="true"
			>
				<ArrowUpRightIcon className="w-6 h-6" />
			</span>
		</Card>
	);
}
