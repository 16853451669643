import dayjs, { extend, locale, Ls } from "dayjs";
import type { Locale } from "dayjs/locale/*";
import calendar from "dayjs/plugin/calendar";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import updateLocalePlugin from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import i18next, { t } from "i18next";
import { merge, omitBy } from "lodash";

extend(utc);
extend(calendar);
extend(timezone);
extend(localizedFormat);
extend(updateLocalePlugin);

export type DayjsCalender = {
	lastDay: string;
	sameDay: string;
	nextDay: string;
	lastWeek: string;
	nextWeek: string;
	sameElse: string;
};

export const normalizeLocale = (lang: string) => {
	// Mapping of language codes of i18next to Day.js supported formats
	const languageMapping: {
		[key: string]: {
			code: string;
			loader: () => Promise<Locale>;
		};
	} = {
		en: { code: "en", loader: () => import("dayjs/locale/en") }, // dayjs uses en for en-US
		"de-DE": { code: "de", loader: () => import("dayjs/locale/de") },
		"en-US": { code: "en", loader: () => import("dayjs/locale/en") }, // dayjs uses en for en-US
		"es-ES": { code: "es", loader: () => import("dayjs/locale/es") },
		"fr-FR": { code: "fr", loader: () => import("dayjs/locale/fr") },
		"it-IT": { code: "it", loader: () => import("dayjs/locale/it") },
		"ach-UG": { code: "en", loader: () => import("dayjs/locale/en") },
		"en-GB": { code: "en-gb", loader: () => import("dayjs/locale/en-gb") },
	};

	return languageMapping[lang] ?? languageMapping.en;
};

function updateLocale(language: string, cal: DayjsCalender) {
	locale(language);
	dayjs.updateLocale(language, {
		calendar: cal,
	});
}

async function initializeLocaleConfig(langCode: string = i18next.language) {
	try {
		const { loader, code } = normalizeLocale(langCode);
		await loader();
		const longFormat = Ls[code]?.formats?.L;
		const localizedCalendar = omitBy(
			{ sameElse: longFormat, nextWeek: longFormat },
			(val) => !val,
		);

		updateLocale(
			code,
			merge(
				localizedCalendar,
				t("calendar", {
					returnObjects: true,
					lng: langCode,
				}),
			) as DayjsCalender,
		);
	} catch (e) {
		console.error(e);
	}
}

export { initializeLocaleConfig };
