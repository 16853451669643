import { useFormikContext } from "formik";
import { useEffect } from "react";

const getErrorElement = () => document.querySelector(".text-error[data-error]");

const scrollToErrorElement = (el: Element) =>
	(el?.parentElement ?? el).scrollIntoView({
		behavior: "smooth",
		block: "center",
	});

export function ScrollToError() {
	const { submitCount, isSubmitting, errors } = useFormikContext();

	// biome-ignore lint/correctness/useExhaustiveDependencies: we want to run this also when those other dependencies change
	useEffect(() => {
		try {
			if (submitCount === 0) return;

			const el = getErrorElement();

			if (!el) {
				// retry to find the error element if there is delay
				setTimeout(() => {
					const elRetry = getErrorElement();

					if (elRetry) {
						scrollToErrorElement(elRetry);
					}
				}, 400);
			} else {
				scrollToErrorElement(el);
			}
		} catch {}
	}, [submitCount, isSubmitting, errors]);

	return null;
}
