import type { FormikValues } from "formik";
import { HTTPError } from "ky";
import {
	first,
	intersectionWith,
	isArray,
	isPlainObject,
	isString,
	last,
	take,
} from "lodash";

import { fetchErrorResponse } from "src/utils/http-error";
import { flattenObject } from "src/utils/objects";
import type { JsonValue } from "type-fest";

export type FormError = Error | HTTPError | string;

type ParsedError = Record<string, string> | undefined;

export type ParsedFormError = {
	message: string | undefined;
	error: ParsedError;
	isHTTPMessage?: boolean;
};

export function parseMessages(messages: JsonValue): string | undefined {
	if (isArray(messages)) {
		return take(
			messages
				.flatMap((i) => parseMessages(i))
				.filter((i) => isString(i)),
			3,
		).join("\n");
	}

	if (isString(messages)) {
		return messages;
	}

	if (isPlainObject(messages)) {
		return first(
			Object.entries(messages as object)
				.map(([_, value]) => parseMessages(value as JsonValue))
				.filter((i) => !!i),
		);
	}

	return undefined;
}

function parseApiMessage(body: {
	messages: Array<{ message: string }>;
}): string | undefined {
	const bodyMessage = body.messages;

	if (bodyMessage && bodyMessage.length > 0) {
		return bodyMessage[0].message;
	}

	return parseMessages(body as JsonValue) ?? JSON.stringify(body);
}

export function errorIsPartOfForm(
	values: FormikValues,
	error: ParsedError,
): boolean {
	if (!error) return false;
	if (!isPlainObject(error) || !isPlainObject(values)) return false;

	let flattenedErrorKeys = Object.keys(flattenObject(error));

	if (flattenedErrorKeys.length > 0) {
		// remove last part of error if it ends with integer key(which means the error has array of strings)
		flattenedErrorKeys = flattenedErrorKeys.map((key) => {
			const keysArr = key.split(".");
			const lastElStr = last(keysArr) ?? "";
			const isLastElNumber = Number.isInteger(
				Number.parseInt(lastElStr, 10),
			);

			if (isLastElNumber) {
				keysArr.pop();
			}

			return keysArr.join(".");
		});
	}

	return (
		intersectionWith(Object.keys(flattenObject(values)), flattenedErrorKeys)
			.length > 0
	);
}

const defaultParsedError: ParsedFormError = {
	error: undefined,
	message: undefined,
};

export async function parseFormError(
	error: FormError,
): Promise<ParsedFormError> {
	if (!error) {
		return defaultParsedError;
	}

	if (isString(error)) {
		return {
			error: { error },
			message: error,
		};
	}

	if ("detail" in error && isString(error.detail)) {
		return {
			error: { detail: error.detail },
			message: error.detail,
		};
	}

	if ("status" in error && "body" in error) {
		return {
			error: error.body as ParsedError,
			message:
				typeof error.body === "object" &&
				error.body !== null &&
				"messages" in error.body
					? parseApiMessage(
							error.body as {
								messages: Array<{ message: string }>;
							},
						)
					: undefined,
			isHTTPMessage:
				error.status && typeof error.status === "number"
					? error.status > 400
					: false,
		};
	}

	if (error instanceof HTTPError) {
		const errorResponse = await fetchErrorResponse(error);

		return {
			error: errorResponse as ParsedError,
			message: parseMessages(
				errorResponse?.messages ?? error?.message ?? errorResponse,
			),
		};
	}

	if (error instanceof Error) {
		return {
			error: { [`${error.name}`]: error.message },
			message: error.message,
		};
	}

	return defaultParsedError;
}

export function isSingleTestRecipient(recipientConf: string[]) {
	return (
		recipientConf &&
		recipientConf.length === 1 &&
		recipientConf[0] === "test"
	);
}
