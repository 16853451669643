interface Settings {
	host: string;
	portal3: {
		URL: string;
	};
	oidc: {
		URL: string;
		realm: string;
		clientId: string;
		redirectUri: string;
		logoutRedirectUri: string;
	};
	backendApi: {
		URL: string;
	};
	productApi: {
		URL: string;
		CUSTOMER_SERVICE_URL: string;
	};
	returnsApi: {
		URL: string;
	};
	returnsPortal: {
		URL: string;
	};
	copilotApi: {
		URL: string;
	};
	powerBiEmbedReport: {
		URL: string;
	};
	zendesk: {
		URL: string;
	};
	sentry: {
		environment: string;
		enabled: boolean;
		dsn: string;
		tracesSampleRate: number;
	};
	hotjar?: {
		id: number;
		version: number;
		debug?: boolean;
	};
	userpilot: {
		key: string;
	};
}

let settings: { [host: string]: Settings } = {
	prod: {
		host: "app.parcellab.com",
		portal3: {
			URL: "https://prtl.parcellab.com",
		},
		oidc: {
			URL: "https://auth.parcellab.com/realms/parcellab",
			clientId: "business-api",
			realm: "parcellab",
			redirectUri: "https://app.parcellab.com",
			logoutRedirectUri:
				"https://login.parcellab.com/accounts/logout/?next=",
		},
		backendApi: {
			URL: "https://api.parcellab.com/",
		},
		productApi: {
			URL: "https://product-api.parcellab.com",
			CUSTOMER_SERVICE_URL: "https://service.parcellab.com",
		},
		returnsApi: {
			URL: "https://returns-api.parcellab.com/prod",
		},
		returnsPortal: {
			URL: "https://returns.parcellab.com",
		},
		copilotApi: {
			URL: "https://copilot.parcellab.dev",
		},
		powerBiEmbedReport: {
			URL: "https://app.powerbi.com/reportEmbed?reportId=",
		},
		zendesk: {
			URL: "https://parcellabhelp.zendesk.com",
		},
		sentry: {
			environment: "production",
			enabled: true,
			dsn: "https://cfc7daebbadd4c46a5edc2f5894207a2@sentry.parcellab.com/10",
			tracesSampleRate: 0.1,
		},
		hotjar: {
			id: 5091617,
			version: 6,
			debug: false,
		},
		userpilot: {
			key: "NX-ff22f3ac",
		},
	},

	staging: {
		host: `${window.location.hostname}`,
		portal3: {
			URL: "https://portal-3.staging.parcellab.dev",
		},
		oidc: {
			URL: "https://auth.staging.parcellab.dev/realms/parcellab",
			clientId: "business-api",
			realm: "parcellab",
			redirectUri: `${window.location.protocol}//${window.location.hostname}`,
			logoutRedirectUri:
				"https://service.staging.parcellab.dev/accounts/logout/?next=",
		},
		backendApi: {
			URL: "https://backend.staging.parcellab.dev",
		},
		productApi: {
			URL: "https://product-api.staging.parcellab.dev",
			CUSTOMER_SERVICE_URL: "https://service.staging.parcellab.dev",
		},
		copilotApi: {
			URL: "https://copilot.staging.parcellab.dev",
		},
		returnsApi: {
			URL: "https://returns-api.parcellab.com/staging",
		},
		returnsPortal: {
			URL: "https://staging-returns.parcellab.com",
		},
		powerBiEmbedReport: {
			URL: "https://app.powerbi.com/reportEmbed?reportId=",
		},
		zendesk: {
			URL: "https://parcellabhelp.zendesk.com",
		},
		sentry: {
			environment: "staging",
			enabled: true,
			dsn: "https://cfc7daebbadd4c46a5edc2f5894207a2@sentry.parcellab.com/10",
			tracesSampleRate: 1,
		},
		userpilot: {
			key: "STG-NX-ff22f3ac",
		},
	},

	dev: {
		host: "localhost:3000",
		portal3: {
			URL: "https://portal-3.staging.parcellab.dev",
		},
		oidc: {
			URL: "https://auth.staging.parcellab.dev/realms/parcellab",
			clientId: "business-api",
			realm: "parcellab",
			redirectUri: "http://localhost:3000",
			logoutRedirectUri:
				"https://service.staging.parcellab.dev/accounts/logout/?next=",
		},
		backendApi: {
			URL: "https://backend.staging.parcellab.dev",
		},
		productApi: {
			URL: "https://product-api.staging.parcellab.dev",
			CUSTOMER_SERVICE_URL: "https://service.staging.parcellab.dev",
		},
		copilotApi: {
			URL: "https://copilot.staging.parcellab.dev",
		},
		returnsApi: {
			URL: "https://returns-api.parcellab.com/staging",
		},
		returnsPortal: {
			URL: "https://staging-returns.parcellab.com",
		},
		powerBiEmbedReport: {
			URL: "https://app.powerbi.com/reportEmbed?reportId=",
		},
		zendesk: {
			URL: "https://parcellabhelp.zendesk.com",
		},
		sentry: {
			environment: "development",
			enabled: false,
			dsn: "https://cfc7daebbadd4c46a5edc2f5894207a2@sentry.parcellab.com/10",
			tracesSampleRate: 0,
		},
		userpilot: {
			key: "STG-NX-ff22f3ac",
		},
	},
};

settings = {
	...settings,
	preprod: {
		...settings.prod,
		oidc: {
			...settings.prod.oidc,
			redirectUri: "https://portal-preprod.staging.parcellab.dev",
		},
		host: "portal-preprod.staging.parcellab.dev",
	},
	preview: {
		...settings.staging,
		sentry: {
			...settings.staging.sentry,
			environment: "preview",
		},
	},
};

// since the ENVIRONMENT variable is set at the CI level and we only want to pull the different API config
// here, we are using the hostname approach.
export function isPreProd() {
	return window.location.hostname === "portal-preprod.staging.parcellab.dev";
}

export function isPreview() {
	return window.location.hostname.startsWith("portal-preview");
}

export function isProd(): boolean {
	return window.ENVIRONMENT === "prod";
}

export function isDev(): boolean {
	return (
		window.ENVIRONMENT === "__ENVIRONMENT__" ||
		window.ENVIRONMENT === "storybook" ||
		process.env.NODE_ENV === "test" ||
		process.env.REACT_APP_TEST === "integration"
	);
}

export function isTest(): boolean {
	return process.env.REACT_APP_TEST === "integration";
}

export function isStorybook(): boolean {
	return window.ENVIRONMENT === "storybook";
}

export function getSettings(): Settings {
	const environment = isDev()
		? "dev"
		: isPreProd()
			? "preprod"
			: isPreview()
				? "preview"
				: (window.ENVIRONMENT as string);
	if (!settings[environment]) {
		throw new Error(`No settings found for environment '${environment}'.`);
	}

	return settings[environment] || settings.dev;
}
