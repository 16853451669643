import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentProps } from "react";
import { commonTextVariants } from "src/components/ui/Typography/constants";
import { cn } from "src/utils/tailwind";

export const h1Variants = cva(
	"scroll-m-20 text-4xl font-semibold tracking-tight",
	{
		variants: {
			...commonTextVariants,
		},
	},
);

export type LinkProps = ComponentProps<"h1"> & VariantProps<typeof h1Variants>;

export function H1({
	className,
	children,
	color,
	size,
	muted,
	fontWeight,
	uppercase,
	capitalized,
	ellipsis,
	...others
}: LinkProps) {
	return (
		<h1
			className={cn(
				h1Variants({
					color,
					size,
					muted,
					fontWeight,
					uppercase,
					capitalized,
					ellipsis,
				}),
				className,
			)}
			{...others}
		>
			{children}
		</h1>
	);
}
