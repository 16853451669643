import type { PropsWithChildren } from "react";
import { Helmet } from "react-helmet-async";

export type HelmetMetaTagWrapperProps = {
	defaultTitle?: string;
	titleTemplate?: string;
};

export function HelmetMetaTagWrapper({
	children,
	defaultTitle,
	titleTemplate,
}: PropsWithChildren<HelmetMetaTagWrapperProps>) {
	return (
		<Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
			{children}
			<meta
				httpEquiv="Cross-Origin-Opener-Policy"
				content="same-origin"
			/>
			<meta
				httpEquiv="Referrer-Policy"
				content="no-referrer-when-downgrade"
			/>
			<meta
				httpEquiv="Permissions-Policy"
				content="geolocation=(), microphone=(), midi=(), camera=(), usb=()"
			/>
			<meta httpEquiv="X-Content-Type-Options" content="nosniff" />
			<meta charSet="utf-8" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1.0"
			/>
		</Helmet>
	);
}
