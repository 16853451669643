import { isDev } from "src/settings";

export const MATOMO_CONFIGS = {
	urlBase: "https://parcellab.matomo.cloud",
	siteId: {
		prod: 5,
		dev: 7,
		staging: 6,
	},
	trackerUrl: "https://parcellab.matomo.cloud/matomo.php",
	srcUrl: "https://cdn.matomo.cloud/parcellab.matomo.cloud/matomo.js",
	tagManagerUrl: "https://cdn.matomo.cloud/parcellab.matomo.cloud/",
	tagManagerContainer: {
		prod: "container_Wc8aaBFV.js",
		dev: "container_TKgGfRVB_dev_b96d78cde732e6804cc76199.js", //FIXME: update this once the container was created
		staging: "container_TKgGfRVB.js",
	},
	heartBeat: {
		active: true,
		seconds: 10,
	},
	linkTracking: true,
	configurations: {
		disableCookies: true,
		setSecureCookie: !isDev(),
		setRequestMethod: "POST",
	},
};
