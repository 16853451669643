import { useLocation } from "react-router";
import { ErrorState } from "src/components/ErrorState";
import { useErrorMessage } from "src/hooks/use-error-message";

export function NotFound() {
	const { state } = useLocation() as { state: { error: Error } };
	const errorMessage = useErrorMessage(state?.error);

	if (errorMessage) {
		return (
			<ErrorState
				title=" "
				description={errorMessage}
				variant="fullScreen"
			/>
		);
	}

	return <ErrorState errorKey="404" variant="fullScreen" />;
}
