import { Outlet, useParams } from "react-router";
import { AuthorizationProvider } from "src/contexts/authorization-context";
import { AppInitiator } from "src/router/app-initiator";

export function MainComponent() {
	const { accountId } = useParams<{ accountId: string }>();
	if (!accountId) {
		return null;
	}
	return (
		<AppInitiator key={accountId} accountId={Number(accountId)}>
			<AuthorizationProvider>
				<Outlet />
			</AuthorizationProvider>
		</AppInitiator>
	);
}
