import { XMarkIcon } from "@heroicons/react/24/outline";
import type { ComponentProps } from "react";
import { Button } from "src/components/ui/Button";

export function InputResetButton({
	onClick,
	...reset
}: ComponentProps<typeof Button>) {
	return (
		<Button
			type="button"
			variant="iconButton"
			size="sm"
			focus={false}
			onClick={onClick}
			{...reset}
		>
			<XMarkIcon className="w-4 h-4" aria-hidden />
		</Button>
	);
}
