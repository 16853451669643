import type { DashboardType } from "src/types/dashboard.type";

export const REPORTS: { [key: string]: DashboardType } = Object.freeze({
	landingPageTracking: {
		type: "report",
		dataset_id: "b2e12f37-ffa2-4796-a22f-5be505818901",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "1ef2bfe3-c782-4751-b04a-9076d1ac0464",
		report_key: "landing_page_tracking",
		report_display_name: {
			en: "Landing Page Tracking",
		},
		pageName: "ReportSection48458d9142b54d0dc0f1",
	},
	landingPageCampaign: {
		type: "report",
		dataset_id: "2758de30-f4f1-427a-8e7d-71e6d9832beb",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "b28a8ed4-c517-4c53-8f1b-708cd62b431d",
		report_key: "landing_page_campaign",
		report_display_name: {
			en: "Landing Page Campaign",
		},
		pageName: "ReportSection9f75a56f80b765e38243",
	},
	landingPageEmails: {
		type: "report",
		dataset_id: "57151666-6748-484e-ad7b-05ece6d9b8e4",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "7a7cb144-bda6-4c09-bc78-b37404b177fb",
		report_key: "landing_page_emails",
		report_display_name: {
			en: "Landing Page Emails",
		},
		pageName: "ReportSectionf06efdf6698da080f7fc",
	},
	campaigns: {
		type: "report",
		dataset_id: "6a566cbe-1ae0-4a0f-b9fb-b8b063a2379a",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "a07e0eed-31e4-4967-9b81-5e19ae01e28d",
		report_key: "benchmarks",
		report_display_name: {
			en: "Campaigns",
		},
	},
	carrier: {
		type: "report",
		dataset_id: "8ea72efd-e12c-441d-a7a1-69c1ab1c66c8",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "36106415-316c-43bb-b190-dee38d57ad25",
		report_key: "benchmarks",
		report_display_name: {
			en: "Carrier",
		},
		pageName: "ReportSection986736acb8c8e97a7c29",
	},
	communications: {
		type: "report",
		dataset_id: "8ea72efd-e12c-441d-a7a1-69c1ab1c66c8",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "36106415-316c-43bb-b190-dee38d57ad25",
		report_key: "benchmarks",
		report_display_name: {
			en: "Communications",
		},
		pageName: "ReportSection1152006253e89e8b6472",
	},
	delivery: {
		type: "report",
		dataset_id: "b3fa0781-aa90-4e8d-979d-f24ad4311b5c",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a3345e5-2d0e-4f83-b9c8-614718410c2e",
		report_key: "fulfillment_analytics",
		report_display_name: {
			en: "Delivery",
		},
		pageName: "ReportSection2ad7812edab8bc4eb489",
	},
	deliveryExceptions: {
		type: "report",
		dataset_id: "b3fa0781-aa90-4e8d-979d-f24ad4311b5c",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a3345e5-2d0e-4f83-b9c8-614718410c2e",
		report_key: "fulfillment_analytics",
		report_display_name: {
			en: "Exceptions",
		},
		pageName: "ReportSection015c75342a09583336b4",
	},
	leadTimes: {
		type: "report",
		dataset_id: "b3fa0781-aa90-4e8d-979d-f24ad4311b5c",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a3345e5-2d0e-4f83-b9c8-614718410c2e",
		report_key: "fulfillment_analytics",
		report_display_name: {
			en: "Leadtimes",
		},
		pageName: "ReportSection36ef08ca4a0cd88ea98a",
	},
	liveNetwork: {
		type: "report",
		dataset_id: "f86bb912-33ea-4bf3-8745-e557bccba0aa",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a2266dd-92d7-418b-bb10-ec77945dfa0b",
		report_key: "live_monitoring",
		report_display_name: {
			en: "Live Network",
		},
		pageName: "ReportSection",
	},
	communicationsInsights: {
		type: "report",
		dataset_id: "f86bb912-33ea-4bf3-8745-e557bccba0aa",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a2266dd-92d7-418b-bb10-ec77945dfa0b",
		report_key: "live_monitoring",
		report_display_name: {
			en: "Communications",
		},
		pageName: "ReportSection21907480b0a58b1a0e16",
	},
	exceptions: {
		type: "report",
		dataset_id: "f86bb912-33ea-4bf3-8745-e557bccba0aa",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a2266dd-92d7-418b-bb10-ec77945dfa0b",
		report_key: "live_monitoring",
		report_display_name: {
			en: "Exceptions",
		},
		pageName: "ReportSection97b474707528d6bc9ec8",
	},
	emailsInsights: {
		type: "report",
		dataset_id: "a8fcd1fe-be84-4fcd-8a78-b92eb4064ff6",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "f4ba1841-c4dd-4d80-995b-878a0e9f2114",
		report_key: "customer_engagement",
		report_display_name: {
			en: "Emails",
		},
		pageName: "ReportSection",
	},
	smsInsights: {
		type: "report",
		dataset_id: "a8fcd1fe-be84-4fcd-8a78-b92eb4064ff6",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "f4ba1841-c4dd-4d80-995b-878a0e9f2114",
		report_key: "customer_engagement",
		report_display_name: {
			en: "SMS",
		},
		pageName: "ReportSection7ac1082f1dbefaccd4df",
	},
	webhooksInsights: {
		type: "report",
		dataset_id: "a8fcd1fe-be84-4fcd-8a78-b92eb4064ff6",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "f4ba1841-c4dd-4d80-995b-878a0e9f2114",
		report_key: "customer_engagement",
		report_display_name: {
			en: "Webhooks",
		},
		pageName: "ReportSection5fdb8685b7abf3f87505",
	},
	orderStatusPage: {
		type: "report",
		dataset_id: "a8fcd1fe-be84-4fcd-8a78-b92eb4064ff6",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "f4ba1841-c4dd-4d80-995b-878a0e9f2114",
		report_key: "customer_engagement",
		report_display_name: {
			en: "Order Status Page",
		},
		pageName: "ReportSection70f908fb528e1807b863",
	},
	feedback: {
		type: "report",
		dataset_id: "a8fcd1fe-be84-4fcd-8a78-b92eb4064ff6",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "f4ba1841-c4dd-4d80-995b-878a0e9f2114",
		report_key: "customer_engagement",
		report_display_name: {
			en: "Feedback",
		},
		pageName: "ReportSection241f8fb675ed25928fa1",
	},
	emotionAI: {
		type: "report",
		dataset_id: "a8fcd1fe-be84-4fcd-8a78-b92eb4064ff6",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "f4ba1841-c4dd-4d80-995b-878a0e9f2114",
		report_key: "customer_engagement",
		report_display_name: {
			en: "Emotion AI",
		},
		pageName: "ReportSectionc174e2337ba3925e1ccc",
	},
	promiseAnalytics: {
		type: "report",
		dataset_id: "7d4d967e-a37f-46dc-a436-e1a05aa867bd",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "a82788b6-8863-4843-92a0-c99084b6fddf",
		report_key: "carrier_commitment",
		report_display_name: {
			en: "Promise",
		},
		pageName: "a2056405a344b7de13a6",
	},
	returns: {
		type: "report",
		dataset_id: "3ab1fa17-0934-4f82-ac4e-169655b06470",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "61ceb951-5fed-4c09-be4c-0091deb1f035",
		report_key: "returns",
		report_display_name: {
			en: "Returns",
		},
		pageName: "ReportSection869f75493fddb4413f7d",
	},
	returnsNetworkMonitoring: {
		type: "report",
		dataset_id: "cf2dcc37-20a2-46c8-b2c3-0ca6438d6a4f",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "92beb2c5-5ece-413d-b14f-4cfbf44d8433",
		report_key: "returns_experience",
		report_display_name: {
			en: "Network Monitoring",
		},
		pageName: "ReportSection",
	},
	returnsRegistrations: {
		type: "report",
		dataset_id: "cf2dcc37-20a2-46c8-b2c3-0ca6438d6a4f",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "92beb2c5-5ece-413d-b14f-4cfbf44d8433",
		report_key: "returns_experience",
		report_display_name: {
			en: "Registrations",
		},
		pageName: "ReportSection55717ef68d16255efdbf",
	},
	returnsItemInsights: {
		type: "report",
		dataset_id: "cf2dcc37-20a2-46c8-b2c3-0ca6438d6a4f",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "92beb2c5-5ece-413d-b14f-4cfbf44d8433",
		report_key: "returns_experience",
		report_display_name: {
			en: "Item Insights",
		},
		pageName: "ReportSection82361a6263bf3542f89c",
	},
	returnsForecast: {
		type: "report",
		dataset_id: "cf2dcc37-20a2-46c8-b2c3-0ca6438d6a4f",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "92beb2c5-5ece-413d-b14f-4cfbf44d8433",
		report_key: "returns_experience",
		report_display_name: {
			en: "Returns Forecast",
		},
		pageName: "ReportSection3f2380f08925c803c37d",
	},
	claims: {
		type: "report",
		dataset_id: "b3fa0781-aa90-4e8d-979d-f24ad4311b5c",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "4a3345e5-2d0e-4f83-b9c8-614718410c2e",
		report_key: "claims",
		report_display_name: {
			en: "Claims",
		},
		pageName: "ReportSection3795b09ed2ac40e3457a",
	},
	shopifyReturns: {
		type: "report",
		dataset_id: "6d76e4ed-b04e-493f-9386-a30acc2695a5",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "e7e9d4b6-f12a-437b-99f5-9b8b8b8b426c",
		report_key: "shopify_returns",
		report_display_name: {
			en: "Shopify Returns",
		},
		pageName: "19150ff88c90e8f10a9e",
	},
	shopifyProductReturns: {
		type: "report",
		dataset_id: "6d76e4ed-b04e-493f-9386-a30acc2695a5",
		workspace_id: "3f3ce4b0-4e2f-40f0-8386-8357786bb3c2",
		report_id: "e7e9d4b6-f12a-437b-99f5-9b8b8b8b426c",
		report_key: "shopify_product_returns",
		report_display_name: {
			en: "Shopify Product Returns",
		},
		pageName: "ReportSection82361a6263bf3542f89c",
	},
});
