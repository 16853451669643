import { QueryClientProvider } from "@tanstack/react-query";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { scan } from "react-scan";
import { App } from "src/App";
import { SimpleLoadingScreen } from "src/components/LoadingScreen/LoadingScreen";
import { cspConfig } from "src/configs";
import { AuthContainer } from "src/containers/AuthContainer";
import { use18nextInit } from "src/locales/use-i18next-init";
import "src/main.css";
import { HelmetMetaTagWrapper } from "src/router/CommonMetaTags";
import { queryClient } from "src/services/queryClient";
import { createCspDirectives } from "src/utils/csp-directives";
import { isProd, isTest } from "./settings";

const REACT_SCAN_ENABLED = import.meta.env.VITE_REACT_SCAN_ENABLED;

scan({
	enabled: !isProd() && !isTest() && REACT_SCAN_ENABLED !== "false",
});

const root = ReactDOM.createRoot(
	document.querySelector("#root") as HTMLElement,
);

const cspDirectives = createCspDirectives(cspConfig);

function RootComponent() {
	const { ready: translationsReady } = use18nextInit();

	return (
		<HelmetProvider>
			<StrictMode>
				<HelmetMetaTagWrapper
					defaultTitle="parcelLab Portal"
					titleTemplate="parcelLab Portal - %s"
				>
					<meta
						httpEquiv="Content-Security-Policy"
						content={cspDirectives}
					/>
				</HelmetMetaTagWrapper>
				<AuthContainer>
					<QueryClientProvider client={queryClient}>
						{translationsReady ? (
							<App />
						) : (
							<SimpleLoadingScreen
								className="h-screen border-transparent bg-pl-light-slate"
								variant="fit"
							/>
						)}
					</QueryClientProvider>
				</AuthContainer>
			</StrictMode>
		</HelmetProvider>
	);
}

root.render(<RootComponent />);
