import { APP_URL_PREFIX } from "src/configs/constants";

export class AppConfig {
	static instance: AppConfig | null = null;

	#pageUrlPrefix;

	// Private constructor to prevent direct instantiation
	constructor() {
		if (AppConfig.instance) {
			throw new Error("Use getInstance() to get the singleton instance.");
		}

		this.#pageUrlPrefix = "";
	}

	get pageUrlPrefix() {
		return this.#pageUrlPrefix;
	}

	set pageUrlPrefix(value: string) {
		this.#pageUrlPrefix = value;
	}
	static setPageUrlPrefix(accountId: string | number) {
		AppConfig.getInstance().pageUrlPrefix = `/${APP_URL_PREFIX}/${accountId}`;
	}

	static getInstance() {
		if (!AppConfig.instance) {
			AppConfig.instance = new AppConfig();
		}
		return AppConfig.instance;
	}
}
