import { useTranslation } from "react-i18next";
import { cn } from "src/utils/tailwind";

export function MultiSelectControlButton({
	textKey,
	isLinkStyle,
	onClick,
	dataTestId,
	className,
}: {
	textKey: string;
	onClick: () => void;
	isLinkStyle?: boolean;
	dataTestId?: string;
	className?: string;
}) {
	const { t } = useTranslation();
	return (
		<button
			data-testid={dataTestId}
			type="button"
			className={cn(`${className || ""}`, {
				"w-full border-b border-slate-200 bg-pl-light-slate p-3 text-xs transition-all hover:bg-slate-200":
					!isLinkStyle,
				"text-xs text-slate-600 hover:text-slate-400": isLinkStyle,
			})}
			onClick={onClick}
		>
			{t(textKey)}
		</button>
	);
}
