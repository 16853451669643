import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
	createBrowserRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router";
import { getSettings, isDev } from "src/settings";

if (!isDev()) {
	Sentry.init({
		dsn: getSettings().sentry.dsn,
		environment: getSettings().sentry.environment,
		enabled: getSettings().sentry.enabled,
		integrations: [
			Sentry.reactRouterV7BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration(),
		],
		tracesSampleRate: getSettings().sentry.tracesSampleRate,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1,
		ignoreErrors: ["Non-Error promise rejection captured with value"],
		sendDefaultPii: true, // so we get more details in Sentry, we're self-hosting this, so it is fine
	});
}

export const sentryCreateBrowserRouter = !isDev()
	? Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)
	: createBrowserRouter;
