import { type VariantProps, cva } from "class-variance-authority";
import React, {
	type ComponentProps,
	type ComponentType,
	forwardRef,
	useContext,
	useEffect,
} from "react";
import ReactSelect, {
	type ActionMeta,
	type GroupBase,
	type MultiValue,
	type NoticeProps,
	type OptionProps,
	type SingleValue,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { MultiSelectFilterableMenuList } from "src/components/form/Select/MultiSelectFilterableMenuList";
import { SelectClearIndicator } from "src/components/form/Select/SelectClearIndicator";
import { SelectLoadingMessage } from "src/components/form/Select/SelectLoadingMessage";
import { SelectNoOptionsMessage } from "src/components/form/Select/SelectNoOptionsMessage";
import { SelectOptionElement } from "src/components/form/Select/SelectOptionElement";
import { MatomoContext } from "src/contexts/matomo";
import type { SelectOption } from "src/types/ui.types";
import { getOptionFromValue } from "src/utils/select-options";
import { cn } from "src/utils/tailwind";

export const selectVariants = cva(
	["text-sm react-sortable-select-input-wrap"],
	{
		variants: {
			text: {
				sm: "!text-xs",
				normal: "!text-sm",
				lg: "!text-base",
			},
			size: {
				md: "max-w-md",
				lg: "max-w-lg",
			},
			readonly: {
				true: "pointer-events-none",
			},
		},
	},
);

export const menuVariants = cva(["text-sm react-sortable-menu !mt-1"], {
	variants: {
		text: {
			sm: "!text-xs",
			normal: "!text-sm",
			lg: "!text-base",
		},
	},
});

export const optionVariants = cva(
	[
		"!p-3 text-black bg-transparent text-sm hover:!bg-pl-light-slate !text-slate-700",
	],
	{
		variants: {
			focused: {
				true: "!bg-pl-light-slate",
			},
			selected: {
				true: "!bg-transparent",
			},
		},
	},
);

export const singleValueVariants = cva(
	["text-slate-700 bg-transparent text-sm"],
	{
		variants: {
			text: {
				sm: "!text-xs",
				normal: "!text-sm",
				lg: "!text-base",
			},
		},
	},
);

export const controlVariants = cva(
	[
		"block w-full !appearance-none !rounded-md !border !border-slate-300 !placeholder-slate-500 !shadow-sm !text-sm overflow-hidden",
	],
	{
		variants: {
			focused: {
				true: "outline-none !ring-1 !ring-pl-blue !border-pl-blue",
			},
			height: {
				md: "min-h-[42px]",
			},
		},
	},
);

export const selectInputVariants = cva(["react-sortable-select-input-wrap"]);

type SelectCommonProps = {
	textVariant?: VariantProps<typeof singleValueVariants>["text"];
	sizeVariant?: VariantProps<typeof selectVariants>["size"];
	heightVariant?: VariantProps<typeof controlVariants>["height"];
	htmlValidation?: boolean;
	className?: string;
	dataTestId?: string;
	inputClassName?: string;
	isCreatable?: boolean;
	options: SelectOption[] | { label: string; options: SelectOption[] }[];
	errorMessage?: string;
	readonly?: boolean;
	isFilterable?: boolean;
	keepMenuOpen?: boolean;
	matomoValues?: {
		category: string;
		action: string;
		name: string;
	};
	isNotClearable?: boolean;
	hasLabelDetails?: boolean;
} & ComponentProps<"input">;

type MultiProps = React.ComponentProps<ReactSelect> & {
	isMulti: true;
	onSelectChange?: (
		newValue: MultiValue<SelectOption> | null,
	) => void | Promise<void>;
	defaultValue?: SelectOption["value"][] | MultiValue<SelectOption>;
} & SelectCommonProps;

type SingleProps = React.ComponentProps<ReactSelect> & {
	onSelectChange?: (
		newValue: SingleValue<SelectOption> | null,
	) => void | Promise<void>;
	defaultValue?: SelectOption["value"] | SingleValue<SelectOption>;
} & SelectCommonProps;

export type SelectProps = SingleProps | MultiProps;

export const Select = forwardRef<
	React.ElementRef<typeof ReactSelect | typeof CreatableSelect>,
	SelectProps
>(
	(
		{
			htmlValidation = true,
			className,
			textVariant = "normal",
			classNames,
			sizeVariant,
			heightVariant,
			onSelectChange,
			inputClassName,
			isCreatable,
			errorMessage,
			options = [],
			readonly,
			matomoValues,
			components = {},
			required,
			isFilterable,
			keepMenuOpen = false,
			isNotClearable = false,
			hasLabelDetails = false,
			placeholder,
			...props
		},
		ref,
	) => {
		const defaultSelected =
			props.value !== undefined ? props.value : props.defaultValue;
		const SelectComponent = isCreatable ? CreatableSelect : ReactSelect;
		const { matomoTracker } = useContext(MatomoContext);
		const [zIndex, setZIndex] = React.useState<string>("");
		const [menuIsOpen, setMenuIsOpen] = React.useState(keepMenuOpen);
		const [placeholderTemp, setPlaceholderTemp] = React.useState<
			string | undefined
		>(placeholder);

		useEffect(() => {
			setPlaceholderTemp(placeholder);
		}, [placeholder]);

		const selectedValue = (val?: SelectOption["value"]) =>
			typeof val === "string" || typeof val === "number"
				? getOptionFromValue(options, val)
				: val;
		const selectedValues = (val?: SelectOption["value"][]) =>
			Array.isArray(val)
				? val.map((item) => selectedValue(item))
				: props.value;

		const handleChange = async (
			selectedOption: (MultiValue<SelectOption> & SelectOption) | null,
		) => {
			const emptyOrDefaultValue =
				props.defaultValue !== undefined
					? props.defaultValue
					: props.isMulti
						? []
						: null;
			const newValue =
				selectedOption !== null ? selectedOption : emptyOrDefaultValue;
			await onSelectChange?.(
				newValue as MultiValue<SelectOption> & SelectOption,
			);
			if (props.isMulti && selectedOption !== null && !isFilterable) {
				setMenuIsOpen(true);
			}
			if (matomoValues) {
				matomoTracker?.trackEvent({
					...matomoValues,
					value: JSON.stringify(newValue),
				});
			}
		};
		const selected = props.isMulti
			? selectedValues(defaultSelected as SelectOption["value"][])
			: selectedValue(defaultSelected as SelectOption["value"]);

		const handleFocus = () => {
			setPlaceholderTemp("");
			setZIndex("z-20");
		};
		const handleBlur = () => {
			setPlaceholderTemp(placeholder);
			setZIndex("");
		};

		const deselectAllOptions = async () => {
			await handleChange(null);
			setMenuIsOpen(false);
		};

		const selectAllOptions = async () => {
			if (onSelectChange && props.isMulti) {
				let selection: SelectOption[] = [];
				for (const option of options) {
					if ("options" in option) {
						selection = selection.concat(option.options);
					} else {
						selection.push(option as SelectOption);
					}
				}
				await onSelectChange(
					selection as unknown as MultiValue<SelectOption> &
						SelectOption,
				);
				setMenuIsOpen(false);
			}
		};

		const menuList = props.isMulti
			? {
					MenuList: MultiSelectFilterableMenuList(
						selectAllOptions,
						deselectAllOptions,
					),
				}
			: {};

		return (
			<SelectComponent
				ref={ref}
				defaultValue={selected}
				hideSelectedOptions={false}
				menuIsOpen={menuIsOpen}
				onMenuClose={() => {
					if (!keepMenuOpen) {
						setMenuIsOpen(false);
					}
				}}
				onMenuOpen={() => setMenuIsOpen(true)}
				classNames={{
					menu: () => menuVariants(),
					menuList: () =>
						"!py-0 first:rounded-t-sm last:rounded-b-sm",
					indicatorsContainer: () => "react-indicator-container",
					container: () =>
						cn(
							selectVariants({
								size: sizeVariant,
								readonly,
							}),
							className,
							zIndex,
						),
					input: () => cn(selectInputVariants(), inputClassName),
					control: ({ isFocused }: { isFocused: boolean }) =>
						controlVariants({
							focused: isFocused,
							height: heightVariant,
						}),
					option: ({ isFocused, isSelected }) =>
						optionVariants({
							selected: isSelected,
							focused: isFocused,
						}),
					singleValue: (componentProps) =>
						cn(
							singleValueVariants({ text: textVariant }),
							componentProps.selectProps.menuIsOpen
								? "hidden"
								: "",
						),
					multiValue: () =>
						"!bg-slate-200 !text-slate-600 rounded-xs box-border !inline-flex !text-xs",
					multiValueLabel: () =>
						"react-sortable-select-value-label !text-xs !text-slate-800",
					...classNames,
				}}
				components={{
					NoOptionsMessage: (cmpProps) =>
						SelectNoOptionsMessage({
							...cmpProps,
							errorMessage,
						} as NoticeProps<SelectOption>),
					ClearIndicator: (cmpProps) =>
						!required &&
						SelectClearIndicator(cmpProps, {
							deselectAllOptions,
							defaultValue:
								props.defaultValue as SelectOption["value"],
						}),
					LoadingMessage:
						SelectLoadingMessage as ComponentType<NoticeProps>,
					Option: (cmpProps) =>
						SelectOptionElement({
							...cmpProps,
							hasLabelDetails,
						} as OptionProps<
							SelectOption,
							boolean,
							GroupBase<SelectOption>
						> & {
							hasLabelDetails?: boolean | undefined;
						}),
					...menuList,
					...components,
				}}
				{...props}
				required={htmlValidation ? required : undefined}
				isClearable={!(isNotClearable || props.isMulti)}
				options={options}
				tabSelectsValue={false}
				value={selected || ""}
				onFocus={handleFocus}
				onBlur={handleBlur}
				onChange={
					handleChange as (
						newValue: unknown,
						actionMeta: ActionMeta<unknown>,
					) => void
				}
				placeholder={placeholderTemp}
			/>
		);
	},
);
