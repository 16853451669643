import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentProps } from "react";
import { commonTextVariants } from "src/components/ui/Typography/constants";
import { cn } from "src/utils/tailwind";

export const pVariants = cva("text-base leading-7", {
	variants: {
		...commonTextVariants,
	},
});

export type LinkProps = ComponentProps<"p"> & VariantProps<typeof pVariants>;

export function P({
	className,
	children,
	color,
	size,
	muted,
	fontWeight,
	uppercase,
	capitalized,
	ellipsis,
	leading,
	...others
}: LinkProps) {
	return (
		<p
			className={cn(
				pVariants({
					color,
					size,
					muted,
					fontWeight,
					uppercase,
					capitalized,
					ellipsis,
					leading,
				}),
				className,
			)}
			{...others}
		>
			{children}
		</p>
	);
}
