import { useParams } from "react-router";
import { useOidcUser } from "src/services/oidc-exporter";
import { getDefaultUserAccountId } from "src/utils/user";

export const useCurrentAccountId = (): number => {
	const { accountId } = useParams<{ accountId: string }>();

	const id = Number.parseInt(accountId as string, 10);

	return (Number.isNaN(id) ? null : id) as number;
};

export const useDefaultAccountId = () => {
	const { oidcUser } = useOidcUser();

	return getDefaultUserAccountId(oidcUser);
};
