import { useEffect } from "react";

import { type UserSettingsOptions, useUserSettings } from "src/hooks/use-user-settings";
import { initializeLocaleConfig } from "src/utils/dayjs.ts";

export function useLanguageFromUserSettings(opts?: UserSettingsOptions) {
	const {userLocale, i18n, isLoading, isError, error} =
		useUserSettings(opts);

	useEffect(() => {
		if (!isLoading && !isError) {
			i18n.changeLanguage(userLocale)
				.then(() => {
					initializeLocaleConfig(userLocale).catch(console.error)
				})
				.catch(console.error)
		}
	}, [isLoading, isError, userLocale, i18n]);

	return { locale: i18n.language, isLoading, isError, error };
}
