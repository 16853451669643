import { models } from "powerbi-client";
import { getSettings } from "src/settings";
import type { ReportMetaDataType } from "src/types/report-meta-data.type";

const embedUrl = getSettings().powerBiEmbedReport.URL;

export const getReportConfig = (
	data: ReportMetaDataType,
	language: string,
) => ({
	type: "report",
	id: data.dashboardInfo.report_id,
	pageName: data.dashboardInfo.pageName,
	embedUrl: `${embedUrl}${data.dashboardInfo.report_id}`,
	accessToken: data.token,
	tokenType: models.TokenType.Embed,
	settings: {
		panes: {
			filters: {
				expanded: false,
				visible: false,
			},
			pageNavigation: {
				visible: false,
			},
		},
		localeSettings: {
			language,
			formatLocale: language,
		},
		background: models.BackgroundType.Default,
	},
});

export const getLanguageFilters = (language: string) => [
	{
		$schema: "http://powerbi.com/product/schema#basic",
		target: {
			table: "language",
			column: "language",
		},
		filterType: 1,
		operator: "In",
		values: [language.slice(0, 2)],
		requireSingleSelection: false,
	},
];
