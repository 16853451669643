import {
	type ExtractSubjectType,
	type MongoAbility,
	type MongoQuery,
	type SubjectRawRule,
	createMongoAbility,
} from "@casl/ability";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { memoize } from "lodash";
import { type PropsWithChildren, createContext, useMemo } from "react";
import { useAccount } from "src/hooks/use-account";
import { isProd } from "src/settings";
import {
	type Account,
	OptInsEnum,
	type UserSettings,
} from "src/types/_generated";
import { accountUsersMeRetrieveOptions } from "src/types/_generated/@tanstack/react-query.gen";
import {
	AccountFeatureFlags,
	type AccountFeatureFlagsType,
	UserFeatureFlags,
	type UserFeatureFlagsType,
} from "src/types/portal-features.type";

export enum Action {
	add = "add",
	change = "change",
	view = "view",
	delete = "delete",
	export = "export",
	publish = "publish",
	force = "force",
	manage = "manage",
	forcePublish = "force_publish",
	revealSecrets = "reveal_secrets",
	useAccountFeature = "useAccountFeature",
	useUserFeature = "useUserFeature",
}

export enum Model {
	ADMIN_LOGENTRY = "admin/logentry",
	AUTH_PERMISSION = "auth/permission",
	AUTH_GROUP = "auth/group",
	CONTENTTYPES_CONTENTTYPE = "contenttypes/contenttype",
	SESSIONS_SESSION = "sessions/session",
	OAUTH_ACCESSTOKEN = "oauth/accesstoken",
	OAUTH_APPLICATION = "oauth/application",
	OAUTH_SSOCONFIG = "oauth/ssoconfig",
	OAUTH_REFRESHTOKEN = "oauth/refreshtoken",
	OAUTH_IDTOKEN = "oauth/idtoken",
	OAUTH_GRANT = "oauth/grant",
	TAGGIT_TAG = "taggit/tag",
	ACCOUNT_ACCOUNT = "account/account",
	ACCOUNT_GROUPPROXY = "account/groupproxy",
	ACCOUNT_ROLE = "account/role",
	ACCOUNT_GROUPROLEEXTENSION = "account/grouproleextension",
	ACCOUNT_USER = "account/user",
	REVERSION_REVISION = "reversion/revision",
	REVERSION_VERSION = "reversion/version",
	AUTHTOKEN_TOKEN = "authtoken/token",
	AUTHTOKEN_TOKENPROXY = "authtoken/tokenproxy",
	SERVICE_SERVICE = "service/service",
	SERVICE_PUBLISHEDCONFIGURATION = "service/publishedconfiguration",
	SERVICE_ATTRIBUTE = "service/attribute",
	SERVICE_SERVICEINSTANCE = "service/serviceinstance",
	CONFIG_COURIER = "config/courier",
	CONFIG_CLIENT = "config/client",
	CONFIG_REFERENCEITEM = "config/referenceitem",
	PORTAL_POWERBIEMBEDTOKEN = "portal/powerbiembedtoken",
	OAUTH_SSOPROVIDER = "oauth/ssoprovider",
	RETURNS_RETURNABLEPERIOD = "returns/returnableperiod",
	RETURNS_RETURNSARTICLEFILTER = "returns/returnsarticlefilter",
	RETURNS_RETURNSORDERAPI = "returns/returnsorderapi",
	RETURNS_RETURNSPORTAL = "returns/returnsportal",
	RETURNS_RETURNSTRANSLATION = "returns/returnstranslation",
	RETURNS_RETURNSRULES = "returns/returnsrules",
	RETURNS_RETURNSPORTALCOURIERMAPPING = "returns/returnsportalcouriermapping",
	RETURNS_RETURNSCOURIERAPI = "returns/returnscourierapi",
	PROMISE_WAREHOUSE = "promise/warehouse",
	PROMISE_SPECIALDAY = "promise/specialday",
	PROMISE_DELIVERYMETHOD = "promise/deliverymethod",
	PROMISE_DELIVERYPREDICTIONSERVICE = "promise/deliverypredictionservice",
	PROMISE_COURIERSERVICELEVEL = "promise/courierservicelevel",
	TESTAPP_ATTRIBUTESET = "testapp/attributeset",
	TESTAPP_ATTRIBUTETYPE = "testapp/attributetype",
	TESTAPP_TRANSLATIONBUNDLE = "testapp/translationbundle",
	TESTAPP_TRANSLATIONDEFINITION = "testapp/translationdefinition",
	TESTAPP_TRANSLATIONSEGMENT = "testapp/translationsegment",
	TESTAPP_TRANSLATIONVALUE = "testapp/translationvalue",
	TESTAPP_ATTRIBUTEVALUE = "testapp/attributevalue",
	TESTAPP_BUNDLESEGMENT = "testapp/bundlesegment",
	SERVICE_OPERATIONLOG = "service/operationlog",
	TRACK_ORDER = "track/order",
	PORTAL_SHAREDOBJECT = "portal/sharedobject",
	PORTAL_DATAPROTECTIONREQUEST = "portal/dataprotectionrequest",
	TESTAPP_DUMMYCHILDMODEL = "testapp/dummychildmodel",
	TESTAPP_DUMMYMODEL = "testapp/dummymodel",
	OAUTH_SSOGROUPSETTINGS = "oauth/ssogroupsettings",
	CAMPAIGN_AUDIENCE = "campaign/audience",
	CAMPAIGN_CAMPAIGN = "campaign/campaign",
	CAMPAIGN_CONTENTTYPE = "campaign/contenttype",
	CAMPAIGN_CONTENTBLOCK = "campaign/contentblock",
	ADMIN_INTERFACE_THEME = "admin_interface/theme",
	CAMPAIGN_ASSET = "campaign/asset",
	JOURNEY_ATTACHMENT = "journey/attachment",
	JOURNEY_LAYOUT = "journey/layout",
	JOURNEY_MESSAGE = "journey/message",
	JOURNEY_PLACEHOLDER = "journey/placeholder",
	JOURNEY_MEDIA = "journey/media",
	JOURNEY_PLACEHOLDERVALUE = "journey/placeholdervalue",
	JOURNEY_PARTIAL = "journey/partial",
	TESTAPP_DUMMYSUBMODEL = "testapp/dummysubmodel",
	PORTAL_CUSTOMERPORTAL = "portal/customerportal",
	NOTIFICATIONS_NOTIFICATION = "notifications/notification",
	DJANGO_CELERY_RESULTS_TASKRESULT = "django_celery_results/taskresult",
	DJANGO_CELERY_RESULTS_CHORDCOUNTER = "django_celery_results/chordcounter",
	DJANGO_CELERY_RESULTS_GROUPRESULT = "django_celery_results/groupresult",
	DJANGO_CELERY_BEAT_CRONTABSCHEDULE = "django_celery_beat/crontabschedule",
	DJANGO_CELERY_BEAT_INTERVALSCHEDULE = "django_celery_beat/intervalschedule",
	DJANGO_CELERY_BEAT_PERIODICTASK = "django_celery_beat/periodictask",
	DJANGO_CELERY_BEAT_PERIODICTASKS = "django_celery_beat/periodictasks",
	DJANGO_CELERY_BEAT_SOLARSCHEDULE = "django_celery_beat/solarschedule",
	DJANGO_CELERY_BEAT_CLOCKEDSCHEDULE = "django_celery_beat/clockedschedule",
	RETURNS_RETURNSCOURIERAPITESTREQUEST = "returns/returnscourierapitestrequest",
	CONFIG_FILTERFIELD = "config/filterfield",
	RETURNS_RETURNSDOCUMENTTEMPLATE = "returns/returnsdocumenttemplate",
	JOURNEY_MESSAGETYPE = "journey/messagetype",
	TRACK_EMAIL = "track/email",
	TRACK_EVENT = "track/event",
	TRACK_SMS = "track/sms",
	TRACK_TRACKING = "track/tracking",
	TRACK_WEBHOOK = "track/webhook",
	RETURNS_THEMEASSET = "returns/themeasset",
	JOURNEY_JOURNEYFLOWVISUALIZATION = "journey/journeyflowvisualization",
	CONFIG_WHITELABELDOMAINCONFIG = "config/whitelabeldomainconfig",
	TRACK_PLACEINFO = "track/placeinfo",
	JOURNEY_JOURNEYTRIGGERACTION = "journey/journeytriggeraction",
	PROMISE_PROMISERULE = "promise/promiserule",
	JOURNEY_JOURNEYCONFIGURATION = "journey/journeyconfiguration",
	JOURNEY_JOURNEYTRIGGERCHANNEL = "journey/journeytriggerchannel",
	JOURNEY_JOURNEYTRIGGERDEFINITION = "journey/journeytriggerdefinition",
	JOURNEY_JOURNEYTRIGGEREVENT = "journey/journeytriggerevent",
	TRACK_SCHEDULEDMESSAGE = "track/scheduledmessage",
	JOURNEY_AUTOLAYOUTCONFIG = "journey/autolayoutconfig",
	OAUTH_AUTHCLIENTAPPLICATION = "oauth/authclientapplication",
	OAUTH_AUTHORIZATIONGRANT = "oauth/authorizationgrant",
	RETURNS_RETURNREASON = "returns/returnreason",
	RETURNS_COMPENSATIONMETHOD = "returns/compensationmethod",
	JOURNEY_JOURNEYSHAREDFILTER = "journey/journeysharedfilter",
	SERVICE_AUTHORIZABLESERVICEINSTANCE = "service/authorizableserviceinstance",
	JOURNEY_JOURNEYTESTTRACKING = "journey/journeytesttracking",
	PROMISE_TRENDINGDELAYCONFIGURATION = "promise/trendingdelayconfiguration",
	CONFIG_CLIENTLOCALECONFIGURATION = "config/clientlocaleconfiguration",
	CONFIG_INTEGRATION = "config/integration",
	TRACK_ORDERSTATUSPAGE = "track/orderstatuspage",
	RETURNS_RETURNREGISTRATION = "returns/returnregistration",
	ANALYTICS_USERFEEDBACK = "analytics/userfeedback",
	SERVICE_RECIPEMODEL = "service/recipemodel",
	ANALYTICS_SURVEY = "analytics/survey",
	RETURNS_RETENTIONOFFER = "returns/retentionoffer",
	PORTAL_POWERBIDATASET = "portal/powerbidataset",
	PORTAL_REPORT = "portal/report",
	PORTAL_CUSTOMREPORT = "portal/customreport",
	JOURNEY_ABTEST = "journey/abtest",
	APPS_CUSTOMAPP = "apps/customapp",
	APPS_INSTALLEDAPP = "apps/installedapp",
	SERVICE_RECIPEAPPLICATIONLOG = "service/recipeapplicationlog",
	ACCOUNT_APITOKEN = "account/apitoken",
	CAMPAIGN_CUSTOMERSEGMENTATION = "campaign/customersegmentation",
	MISC_VOUCHER = "misc/voucher",
	MISC_IMPORT = "misc/import",
	CONFIG_CUSTOMERSEGMENTCONFIG = "config/customersegmentconfig",
	APPS_APPDEPLOYMENT = "apps/appdeployment",
	TAGGIT_TAGGEDITEM = "taggit/taggeditem",
	SERVICE_RECIPEIMAGE = "service/recipeimage",
	TESTAPP_DUMMYCONFIGSCHEMAMODEL = "testapp/dummyconfigschemamodel",
	TESTAPP_DUMMYCONFIGINSTANCEMODEL = "testapp/dummyconfiginstancemodel",
	APPS_APPIMAGE = "apps/appimage",
	SERVICE_WORKFLOWCOMMENTS = "service/workflowcomments",
	ANALYTICS_SURVEYANSWER = "analytics/surveyanswer",
	ACCOUNT_ACTIVITYLOG = "account/activitylog",
	CONFIG_WHITELISTEDDOMAIN = "config/whitelisteddomain",
	MISC_CARRIERHOOK = "misc/carrierhook",
	ACCOUNT_USERACTIONLOG = "account/useractionlog",
	JOURNEY_ABTESTMESSAGE = "journey/abtestmessage",
	JOURNEY_EXPERIMENT = "journey/experiment",
	JOURNEY_EXPERIMENTVARIANT = "journey/experimentvariant",
	RETURNS_REFUNDMETHOD = "returns/refundmethod",
	JOURNEY_EXPERIMENTRATIO = "journey/experimentratio",
	TRACKING_EXTENDED = "tracking/extended",
	TRACK_DELIVERYINFO = "track/deliveryinfo",
	APPS_CONFIGUREDAPPHOOK = "apps/configuredapphook",
	TRACK_RECIPIENT = "track/recipient",
	OAUTH_PROVIDER = "oauth/provider",
	PROMISE_TRENDINGDELAYACTIVATIONCONFIG = "promise/trendingdelayactivationconfig",
	AGENTS_SIMPLEAGENT = "agents/simpleagent",
	AGENTS_SIMPLEAGENTTHREAD = "agents/simpleagentthread",
}

export type AccountFeatureFlagSubject = keyof AccountFeatureFlagsType;
export type UserFeatureFlagSubject = keyof UserFeatureFlagsType;

export type AppAbility = MongoAbility<
	| [Action.useAccountFeature, AccountFeatureFlagSubject]
	| [Action.useUserFeature, UserFeatureFlagSubject]
	| [Action, Model]
>;

export type AbilityArgument = Parameters<AppAbility["can"]>;

export type AbilityArguments = {
	can?: {
		allOf?: AbilityArgument[];
		oneOf?: AbilityArgument[];
	};
	cannot?: {
		allOf?: AbilityArgument[];
		oneOf?: AbilityArgument[];
	};
};
export type GenericAuthorizationMap<T extends string> = {
	[_K in T]: AbilityArguments;
};

type AccountSubjectRawRule = SubjectRawRule<
	Action.useAccountFeature,
	AccountFeatureFlagSubject,
	MongoQuery
>;
type UserSubjectRawRule = SubjectRawRule<
	Action.useUserFeature,
	UserFeatureFlagSubject,
	MongoQuery
>;

// typeguard function to test for a string being an Action
export function isAction(action: string): action is Action {
	return Object.values(Action).includes(action as Action);
}
export function isModel(model: string): model is Model {
	return Object.values(Model).includes(model as Model);
}

/**
 *
 * @param permissions like "journey.change_attachment"
 * @returns an array of objects with action and subject properties similar to what the django-casl plugin would give us
 */
export function mapPermissionsToAbilities(
	permissions: string[],
): SubjectRawRule<Action, ExtractSubjectType<Model>, MongoQuery>[] {
	return permissions.map((permission) => {
		const [module, operationModel] = permission.split(".");
		const pos = operationModel.lastIndexOf("_");
		const action = operationModel.slice(0, pos);
		const model = operationModel.slice(pos + 1);

		if (!isProd() && !isAction(action)) {
			const msg = `Configuration: Unknown action ${action} in permission ${permission}`;
			Sentry.captureMessage(msg, "info");
		}
		if (!isProd() && !isModel(`${module}/${model}`)) {
			const msg2 = `Configuration: Unknown model ${module}/${model} in permission ${permission}`;
			Sentry.captureMessage(msg2, "info");
		}
		return {
			action: action as Action,
			subject: `${module}/${model}` as Model,
		};
	});
}
export function mapAccountFeatureFlagsToAbilities(
	account: AccountFeatureFlagsType,
): AccountSubjectRawRule[] {
	return Object.values(AccountFeatureFlags).map((flag) => ({
		action: Action.useAccountFeature,
		subject: flag,
		// Inverted: Access granted if the feature flag is true on the account.
		inverted: !account[flag],
	}));
}
export function mapUserFeatureFlagsToAbilities(
	userSettings: UserFeatureFlagsType,
): UserSubjectRawRule[] {
	return Object.values(UserFeatureFlags).map((flag) => ({
		action: Action.useUserFeature,
		subject: flag,
		// Inverted: Access granted if the feature flag is true on the user settings.
		inverted: !userSettings[flag],
	}));
}

function getHardcodedAccountFeatureFlags(
	account: Account,
): AccountSubjectRawRule[] {
	return [
		{
			// TODO: This is currently a snowflake hardcoded check for account hexclad (1620904)
			action: Action.useAccountFeature,
			subject: AccountFeatureFlags.hasReturnsOverview,
			// Inverted: Access granted if account id is 1620904 (hexclad).
			inverted: account.id !== 1620904,
		},
		{
			action: Action.useAccountFeature,
			subject: AccountFeatureFlags.hasGptOptIn,
			// Inverted: grants access if account does have OPENAI OptIn enabled
			inverted: !account?.optIns?.includes(OptInsEnum.OPENAI),
		},
	];
}

// biome-ignore lint/style/noNonNullAssertion: <explanation>
export const AuthorizationContext = createContext<AppAbility>(undefined!);

export const checkAbilities = memoize(
	(ability: AppAbility, abilityArguments: AbilityArguments | undefined) => {
		if (!abilityArguments) {
			return true;
		}

		const isAllAble = (
			arg: AbilityArgument[] | undefined,
			actionType: "can" | "cannot",
		) => {
			if (!arg) return true;
			if (arg.length === 0) return true;

			return arg.every((args) => ability[actionType](...args));
		};

		const isSomeAble = (
			arg: AbilityArgument[] | undefined,
			actionType: "can" | "cannot",
		) => {
			if (!arg) return true;
			if (arg.length === 0) return true;

			return arg.some((args) => ability[actionType](...args));
		};

		return (
			isAllAble(abilityArguments.can?.allOf, "can") &&
			isSomeAble(abilityArguments.can?.oneOf, "can") &&
			isAllAble(abilityArguments.cannot?.allOf, "cannot") &&
			isSomeAble(abilityArguments.cannot?.oneOf, "cannot")
		);
	},
	(ability, abilityArguments) => JSON.stringify([ability, abilityArguments]),
);

export const createAbility = memoize(
	(userSettings?: UserSettings, account?: Account) => {
		const permissionAbilities = mapPermissionsToAbilities(
			userSettings?.permissions || [],
		);
		const accountFeatureFlagAbilities = account
			? [
					...mapAccountFeatureFlagsToAbilities(
						account as unknown as AccountFeatureFlagsType,
					),
					...getHardcodedAccountFeatureFlags(account),
				]
			: [];
		const userFeatureFlagAbilities = userSettings
			? [
					...mapUserFeatureFlagsToAbilities(
						userSettings as unknown as UserFeatureFlagsType,
					),
				]
			: [];
		return createMongoAbility<AppAbility>([
			...permissionAbilities,
			...accountFeatureFlagAbilities,
			...userFeatureFlagAbilities,
		]);
	},
	(userSettings?: UserSettings, account?: Account) =>
		`${userSettings?.email}-${account?.id}`,
);

export function AuthorizationProvider({ children }: PropsWithChildren) {
	const { data: account } = useAccount();
	const { data: userSettings } = useQuery({
		...accountUsersMeRetrieveOptions(),
		refetchOnWindowFocus: false,
	});

	const ability = useMemo(
		() => createAbility(userSettings, account),
		[userSettings, account],
	);

	return (
		<AuthorizationContext.Provider value={ability}>
			{children}
		</AuthorizationContext.Provider>
	);
}
