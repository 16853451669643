import * as SeparatorPrimitive from "@radix-ui/react-separator";
import { cva } from "class-variance-authority";
import {
	type ComponentPropsWithoutRef,
	type ElementRef,
	forwardRef,
} from "react";
import { cn } from "src/utils/tailwind";

const dividerVariants = cva(["shrink-0 bg-borderColor"], {
	variants: {
		orientation: {
			vertical: "h-full w-[1px]",
			horizontal: "h-[1px] w-full",
		},
	},
	defaultVariants: {
		orientation: "horizontal",
	},
});

export const Divider = forwardRef<
	ElementRef<typeof SeparatorPrimitive.Root>,
	ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
	(
		{ className, orientation = "horizontal", decorative = true, ...props },
		ref,
	) => (
		<SeparatorPrimitive.Root
			ref={ref}
			decorative={decorative}
			orientation={orientation}
			className={cn(dividerVariants({ orientation }), className)}
			{...props}
		/>
	),
);
Divider.displayName = SeparatorPrimitive.Root.displayName;
