import { type LoaderFunctionArgs, Navigate } from "react-router";
import { communicationsAuthorization } from "src/configs/authorization/communication";
import { PAGES } from "src/configs/pages-urls";
import {
	emailCommunicationsDetailsLoader,
	notificationsLoader,
} from "src/module--communications/communications.loaders";
import { CommunicationTypes } from "src/module--communications/types/communications.type";
import { authorizationLoader } from "src/router/common-loaders";
import type { RouterConfig } from "src/router/types";

export function communicationsRoutes(config: RouterConfig) {
	const { oidcUser, queryClient, idToken } = config;
	return [
		{
			index: true,
			element: <Navigate to={PAGES.communications.sub.emails} replace />,
		},
		{
			path: PAGES.communications.sub.emails,
			async lazy() {
				const { CommunicationsPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return {
					Component: CommunicationsPage,
				};
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				notificationsLoader(
					queryClient,
					idToken,
					oidcUser,
					request,
					CommunicationTypes.Email,
					accountIdParam,
				),
		},
		{
			path: `${PAGES.communications.sub.emailDetails}`,
			async lazy() {
				const { CommunicationPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return {
					Component: CommunicationPage,
				};
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				emailCommunicationsDetailsLoader(
					queryClient,
					idToken,
					oidcUser,
					request,
					accountIdParam,
				),
		},
		{
			path: PAGES.communications.sub.sms,
			async lazy() {
				const { CommunicationsPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return {
					Component: CommunicationsPage,
				};
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				notificationsLoader(
					queryClient,
					idToken,
					oidcUser,
					request,
					CommunicationTypes.Sms,
					accountIdParam,
				),
		},
		{
			path: `${PAGES.communications.sub.smsDetails}`,
			async lazy() {
				const { CommunicationPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return {
					Component: CommunicationPage,
					loader: () => ({
						communicationType: CommunicationTypes.Sms,
					}),
				};
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				notificationsLoader(
					queryClient,
					idToken,
					oidcUser,
					request,
					CommunicationTypes.Sms,
					accountIdParam,
				),
		},
		{
			path: PAGES.communications.sub.webhooks,
			async lazy() {
				const { CommunicationsPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return {
					Component: CommunicationsPage,
				};
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				notificationsLoader(
					queryClient,
					idToken,
					oidcUser,
					request,
					CommunicationTypes.Webhook,
					accountIdParam,
				),
		},
		{
			path: `${PAGES.communications.sub.webhookDetails}`,
			async lazy() {
				const { CommunicationPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return {
					Component: CommunicationPage,
				};
			},
			loader: async ({
				request,
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				notificationsLoader(
					queryClient,
					idToken,
					oidcUser,
					request,
					CommunicationTypes.Webhook,
					accountIdParam,
				),
		},
		{
			path: PAGES.communications.sub.emailsInsights,
			async lazy() {
				const { CommunicationsEmailInsightsReport } = await import(
					"src/components/Report"
				);
				return { Component: CommunicationsEmailInsightsReport };
			},
		},
		{
			path: PAGES.communications.sub.smsInsights,
			async lazy() {
				const { CommunicationsSmsInsightsReport } = await import(
					"src/components/Report"
				);
				return { Component: CommunicationsSmsInsightsReport };
			},
		},
		{
			path: PAGES.communications.sub.webhooksInsights,
			async lazy() {
				const { CommunicationsWebhooksInsightsReport } = await import(
					"src/components/Report"
				);
				return { Component: CommunicationsWebhooksInsightsReport };
			},
		},
		{
			path: PAGES.communications.sub.orderStatusPage,
			async lazy() {
				const { CommunicationsOrderStatusPageReport } = await import(
					"src/components/Report"
				);
				return { Component: CommunicationsOrderStatusPageReport };
			},
		},
		{
			path: PAGES.communications.sub.feedback,
			async lazy() {
				const { CommunicationsFeedbackReport } = await import(
					"src/components/Report"
				);
				return { Component: CommunicationsFeedbackReport };
			},
		},
		{
			path: PAGES.communications.sub.emotionAIPromotion,
			async lazy() {
				const { EmotionAiPromotionPage } = await import(
					"src/module--communications/communications.lazy"
				);
				return { Component: EmotionAiPromotionPage };
			},
		},
		{
			path: PAGES.communications.sub.emotionAI,
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					communicationsAuthorization.communications.emotionAI,
					[
						{
							redirectUrl:
								PAGES.communications.sub.emotionAIPromotion,
							abilityList:
								communicationsAuthorization.communications
									.emotionAIPromotion,
						},
					],
				),
			async lazy() {
				const { CommunicationsEmotionAiReport } = await import(
					"src/components/Report"
				);
				return {
					Component: CommunicationsEmotionAiReport,
				};
			},
		},
		{
			path: PAGES.communications.sub.benchmarkingPromotion,
			async lazy() {
				const { BenchmarkingPromotionPage } = await import(
					"src/module--trackings/trackings.lazy"
				);
				return { Component: BenchmarkingPromotionPage };
			},
		},
		{
			path: PAGES.communications.sub.benchmarking,
			async lazy() {
				const { BenchmarkingCommsReport } = await import(
					"src/components/Report"
				);
				return { Component: BenchmarkingCommsReport };
			},
			loader: async ({
				params: { accountId: accountIdParam },
			}: LoaderFunctionArgs) =>
				authorizationLoader(
					{ ...config, accountIdParam },
					communicationsAuthorization.communications.benchmarking,
					[
						{
							redirectUrl:
								PAGES.communications.sub.benchmarkingPromotion,
							abilityList:
								communicationsAuthorization.communications
									.benchmarkingPromotion,
						},
					],
				),
		},
	];
}
