import { queryOptions } from "@tanstack/react-query";
import { redirect } from "react-router";
import { PAGE_URLS } from "src/configs/pages-urls";
import { getAuthHeaders } from "src/hooks/use-auth-headers";
import { trackingsQueries } from "src/module--trackings/trackings.query-factory";
import type { LoaderConfig } from "src/router/types";
import { getLanguageCode } from "src/utils/languages";
import { getSearchParams } from "src/utils/loader";

export const trackingDetailsLoader = (
	{ queryClient, idToken, oidcUser, request }: LoaderConfig,
	accountId: string | undefined,
) => {
	const headers = getAuthHeaders(oidcUser, Number(accountId), idToken);
	const trackingId = getSearchParams(request).get("trackingId") as string;
	if (!trackingId) {
		return redirect(PAGE_URLS.trackings().trackings);
	}

	const trackingDetailQuery = queryOptions(
		trackingsQueries.detail(
			{
				trackingId,
				lang: getLanguageCode(),
				user: accountId,
			},
			headers,
		),
	);
	return queryClient.ensureQueryData(trackingDetailQuery);
};
