import { type VariantProps, cva } from "class-variance-authority";
import { type InputHTMLAttributes, forwardRef } from "react";
import type { FormikFieldProps } from "src/components/form/form.types";
import { cn } from "src/utils/tailwind";

export const textInputVariants = cva(
	[
		"block w-full appearance-none rounded-md border px-3 py-2 shadow-sm focus:border-pl-blue focus:outline-none sm:text-sm ",
		"placeholder:text-muted-foreground focus-visible:ring-ringColor flex file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 read-only:opacity-50 read-only:bg-slate-50",
	],
	{
		variants: {
			fieldState: {
				default:
					"border-borderColor placeholder-foreground ring-offset-ringColor-foreground focus:ring-ringColor",
				error: "text-error placeholder-error ring-offset-error focus:ring-error !border-error",
			},
		},
	},
);
type InputProps = FormikFieldProps<string | number> &
	InputHTMLAttributes<HTMLInputElement> &
	VariantProps<typeof textInputVariants>;

const TextInput = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			className,
			type = "text",
			meta,
			field,
			fieldState,
			defaultValue,
			...props
		},
		ref,
	) => (
		<input
			id={field.name}
			data-testid={field.name}
			type={type}
			className={cn(
				textInputVariants({
					fieldState:
						meta.touched && meta.error
							? "error"
							: (fieldState ?? "default"),
				}),
				className,
			)}
			ref={ref}
			{...field}
			{...props}
			value={field.value || defaultValue || ""}
		/>
	),
);
TextInput.displayName = "TextInput";

export { TextInput };
