import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "src/components/ui/Button";
import { Flex } from "src/components/ui/Flex";
import {
	type FeedbackVariantsProps,
	feedbackVariants,
} from "src/components/utils/feedback.variants";
import type { ErrorStateType } from "src/types/error";
import { extractStatusFromErrors } from "src/utils/extract-status-from-errors";
import { cn } from "src/utils/tailwind";

export type ErrorStateProps = {
	Icon?: typeof ExclamationTriangleIcon;
	iconClassName?: string;
	error?: ErrorStateType;
	errorKey?: string;
	resetErrorBoundary?: () => void;
} & { title?: string; description?: string } & ComponentProps<"div"> &
	FeedbackVariantsProps;

export function ErrorState({
	title,
	description,
	Icon,
	variant,
	className,
	iconClassName,
	error,
	errorKey,
	resetErrorBoundary,
	children,
	...others
}: ErrorStateProps) {
	const IconComponent = Icon || ExclamationTriangleIcon;
	const { t } = useTranslation();
	const errorStatusKey = errorKey || extractStatusFromErrors(error);
	if (
		error instanceof Error &&
		error.message.includes("dynamically imported module") &&
		window
	) {
		if (resetErrorBoundary) resetErrorBoundary();
		// we reload the APP when we get an error related to dynamically imported module
		window.location.reload();
	}
	return (
		<Flex asChild gap={4}>
			<div
				data-testid="error-state"
				className={cn(feedbackVariants({ variant }), className)}
				{...others}
			>
				<span className="block">
					<IconComponent
						className={cn("h-20 w-20 text-error", iconClassName)}
					/>
				</span>
				<span className="block text-3xl font-medium text-slate-900">
					{title || t(`errorState.${errorStatusKey}.title`)}
				</span>
				<span className="block max-w-2xl text-center text-base font-medium text-slate-700">
					{description ||
						t(`errorState.${errorStatusKey}.description`)}
				</span>
				{resetErrorBoundary && (
					<span className="block">
						<Button onClick={() => resetErrorBoundary()}>
							{t("error.retry")}
						</Button>
					</span>
				)}
				{children}
			</div>
		</Flex>
	);
}
