import { type VariantProps, cva } from "class-variance-authority";
import type { ComponentProps } from "react";
import { cn } from "src/utils/tailwind";

export const pageSectionVariants = cva("w-full p-4 xl:mx-auto", {
	variants: {
		bg: {
			white: "bg-white",
			transparent: "bg-transparent",
		},
		fullWidth: {
			false: "xl:max-w-page",
		},
	},
	defaultVariants: {
		fullWidth: false,
	},
});

export type LayoutProps = VariantProps<typeof pageSectionVariants> &
	ComponentProps<"section">;

export function PageSection({
	className,
	bg,
	children,
	fullWidth,
	...rest
}: LayoutProps) {
	return (
		<section
			className={cn(pageSectionVariants({ bg, fullWidth }), className)}
			{...rest}
		>
			{children}
		</section>
	);
}
