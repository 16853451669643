import { useMemo } from "react";
import { createPath, useLocation, useSearchParams } from "react-router";
import { useHash } from "react-use";

export const NEXT_URL_IDENTIFIER = "nextUrl";

export function useGetNextUrlParam(): string {
	const [searchParams] = useSearchParams();
	const [hash] = useHash();
	if (!searchParams.has(NEXT_URL_IDENTIFIER)) {
		return `/${hash}`;
	}

	return `${searchParams.get(NEXT_URL_IDENTIFIER)}${hash}`;
}

export function useSetNextUrlParam() {
	const location = useLocation();

	return useMemo(() => {
		const searchParams = new URLSearchParams();

		const nextUrl = createPath(location);

		searchParams.append(NEXT_URL_IDENTIFIER, nextUrl);

		return {
			searchParams,
			searchString: `${NEXT_URL_IDENTIFIER}=${nextUrl}`,
		};
	}, [location]);
}
