import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { STALE_QUERY_CLIENT_TIME } from "src/configs/constants";
import { invalidateQuery } from "src/utils/InvalidateQuery";
import { handleServerError } from "src/utils/error";

export const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: handleServerError,
	}),
	mutationCache: new MutationCache({
		onError: handleServerError,
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				predicate: invalidateQuery,
			});
		},
	}),
	defaultOptions: {
		queries: {
			staleTime: STALE_QUERY_CLIENT_TIME,
		},
	},
});
